// External imports
import React, { useRef } from 'react';
import { Box, Button, Checkbox, Input, Select } from '@chakra-ui/react';
// Internal components / functions
import { OSTooltip } from '../types/Utils';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
// COMPONENTS ===========================================================================
export const OpenSankeyDefaultModalePreferenceContent = (applicationData, trad) => {
    // Data -------------------------------------------------------------------------------
    const { t } = applicationData.new_data;
    const { new_data, data } = applicationData;
    // Component updater ------------------------------------------------------------------
    const menus = ['MEP', 'EN', 'EF', 'ED', 'LL', 'Vis'];
    const checkbox_refs = {};
    menus.forEach(menu => checkbox_refs[menu] = useRef(null));
    const update_checkboxes = (menu_to_show) => {
        menus.forEach(menu => {
            var _a, _b, _c;
            const checkbox_ref = (_b = (_a = checkbox_refs[menu]) === null || _a === void 0 ? void 0 : _a.current) !== null && _b !== void 0 ? _b : undefined;
            const checkbox_checked = (_c = checkbox_ref === null || checkbox_ref === void 0 ? void 0 : checkbox_ref.checked) !== null && _c !== void 0 ? _c : undefined;
            if ((checkbox_checked !== undefined) &&
                (checkbox_checked !== menu_to_show.includes(menu))) {
                checkbox_ref === null || checkbox_ref === void 0 ? void 0 : checkbox_ref.click();
            }
        });
    };
    // JSX Component ----------------------------------------------------------------------
    const ui = {
        'lang': React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.lang')),
            React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: trad.language, onChange: evt => {
                    trad.changeLanguage((evt.target.value));
                } },
                React.createElement("option", { key: 'francais', value: 'fr' }, "Fran\u00E7ais"),
                React.createElement("option", { key: 'english', value: 'en' }, "English"))),
        'form': [
            React.createElement("h4", null, t('Menu.pref_title_sub_menu')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', onClick: () => {
                            sessionStorage.removeItem('modepref');
                            update_checkboxes(['MEP']);
                        } }, "Mode Simple"),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', onClick: () => {
                            sessionStorage.setItem('modepref', 'expert');
                            update_checkboxes(['MEP', 'EN', 'EF', 'ED', 'LL', 'Vis']);
                        } }, "Mode Expert"))),
            React.createElement(Checkbox, { ref: checkbox_refs['MEP'], variant: 'menuconfigpanel_option_checkbox', defaultChecked: new_data.menu_configuration.isGivenAccordionShowed('MEP'), onChange: () => {
                    new_data.menu_configuration.toggleGivenAccordion('MEP');
                } }, t('Menu.MEP')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.Noeuds')),
            React.createElement(Checkbox, { ref: checkbox_refs['EN'], variant: 'menuconfigpanel_option_checkbox', defaultChecked: new_data.menu_configuration.isGivenAccordionShowed('EN'), onChange: () => {
                    new_data.menu_configuration.toggleGivenAccordion('EN');
                } }, t('Menu.EN')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.flux')),
            React.createElement(Checkbox, { ref: checkbox_refs['EF'], variant: 'menuconfigpanel_option_checkbox', defaultChecked: new_data.menu_configuration.isGivenAccordionShowed('EF'), onChange: () => {
                    new_data.menu_configuration.toggleGivenAccordion('EF');
                } }, t('Menu.EF')),
            React.createElement(Checkbox, { ref: checkbox_refs['ED'], variant: 'menuconfigpanel_option_checkbox', defaultChecked: new_data.menu_configuration.isGivenAccordionShowed('ED'), onChange: () => {
                    new_data.menu_configuration.toggleGivenAccordion('ED');
                } }, t('Menu.ED')),
        ],
        'node_label_sep': React.createElement(OSTooltip, { label: t('Menu.tooltips.node_label_sep') },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.node_label_sep')),
                React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: data.node_label_separator, onChange: evt => {
                        data.node_label_separator = evt.target.value; // TODO passer dans new_data
                    }, onBlur: () => new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw()) }))),
    };
    return ui;
};
export const ModalPreference = ({ applicationData, ui, t }) => {
    const content = React.createElement(React.Fragment, null, Object.values(ui).map((d, i) => {
        return React.createElement(React.Fragment, { key: i }, d);
    }));
    return React.createElement(MenuDraggable, { dict_hook_ref_setter_show_dialog_components: applicationData.new_data.menu_configuration.dict_setter_show_dialog, dialog_name: 'ref_setter_show_modal_preference', content: content, title: t('Menu.title_pref') });
};
