// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
import { Class_NodeDimension } from './Node';
import { default_grey_color, getBooleanFromJSON, getStringFromJSON, getStringListFromJSON, makeId } from './Utils';
// CLASS PROTO TAG ***********************************************************************
/**
 * Class that define a Tag object
 * @class Class_Tag
 */
export class Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ProtoTag.
     * @param {string} name
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_ProtoTag
     */
    constructor(name, sankey, id = undefined) {
        // Color of tag
        this._color = default_grey_color;
        // Boolean
        this._is_selected = false;
        /**
         * True if tag is currently on a deletion process
         * Avoid cross calls of delete() method
         * @private
         * @memberof Class_Tag
         */
        this._is_currently_deleted = false;
        this._id = id !== null && id !== void 0 ? id : makeId(name);
        this._name = name;
        this._ref_sankey = sankey;
    }
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Unref this from tag group
            this.group.removeTag(this);
            // Clean the rest
            this.cleanForDeletion();
            // Garbage collection will do the rest
        }
    }
    copyFrom(element) {
        this._name = element._name;
        this._color = element._color;
        this._is_selected = element._is_selected;
        this._ref_sankey = element._ref_sankey;
        // Groups are switched from related group class
    }
    setSelected() {
        // Avoid useless update
        if (this._is_selected === false) {
            // Set attributes
            this._is_selected = true;
            // Redraw all related elements
            this.update();
        }
    }
    setUnSelected() {
        // Avoid useless update
        if (this._is_selected === true) {
            // Set attributes
            this._is_selected = false;
            // Redraw all related elements
            this.update();
        }
    }
    toogleSelected() {
        // Set attributes
        this._is_selected = !this._is_selected;
        // Redraw all related elements
        this.update();
    }
    toJSON() {
        const json_object = {};
        json_object['name'] = this._name;
        json_object['selected'] = this._is_selected;
        json_object['color'] = this._color;
        return json_object;
    }
    /**
     *Set Tag value from JSON
     *
     * @param {Type_JSON} json_object
     * @memberof Class_Tag
     */
    fromJSON(json_object) {
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._is_selected = getBooleanFromJSON(json_object, 'selected', false);
        this._color = getStringFromJSON(json_object, 'color', this._color);
    }
    // GETTERS / SETTERS ==================================================================
    get id() { return this._id; }
    get name() { return this._name; }
    set name(value) { this._name = value; }
    get color() { return this._color; }
    set color(value) {
        // Avoid useless updates
        if (this._color !== value) {
            // Set attributes
            this._color = value;
            // Redraw all related elements
            this.update();
        }
    }
    // Selection
    get is_selected() { return this._is_selected; }
    set is_selected(_) { this._is_selected = _; }
}
// CLASS TAG ****************************************************************************
/**
 * Class that define a Tag object
 * @class Class_Tag
 */
export class Class_Tag extends Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Tag.
     * @param {string} name
     * @param {Class_TagGroup} group
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_DataTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._references = {};
        this._group = group;
    }
    // PUBLIC METHODS =====================================================================
    update() {
        Object.values(this._references)
            .forEach(element => {
            element.draw();
        });
    }
    hasGivenReference(_) {
        return (this._references[_.id] !== undefined);
    }
    addReference(_) {
        if (!this.hasGivenReference(_)) {
            this._references[_.id] = _;
            _.addTag(this);
        }
    }
    removeReference(_) {
        if (this.hasGivenReference(_)) {
            delete this._references[_.id];
            _.removeTag(this);
        }
    }
    /**
     * Copy attributes from external tag
     *
     * @param {Class_Tag} element
     * @memberof Class_Tag
     */
    copyFrom(tag) {
        super.copyFrom(tag);
        // Synchronize references
        let all_possible_reference = Object.assign({}, this._ref_sankey.nodes_dict);
        this._ref_sankey.links_list
            .map(link => all_possible_reference = Object.assign(Object.assign({}, all_possible_reference), Object.fromEntries(Object.entries(link.getAllValues()).map(([id, list]) => [id, list[0]]))));
        Object.keys(tag._references) // Add missing refs
            .filter(ref_id => ref_id in all_possible_reference)
            .forEach(ref_id => this.addReference(all_possible_reference[ref_id]));
        Object.keys(this._references) // Remove extra refs
            .filter(ref_id => !tag._references[ref_id])
            .forEach(ref_id => this.removeReference(this._references[ref_id]));
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Unref this tag from all references
        Object.values(this._references)
            .forEach(element => {
            element.removeTag(this);
        });
        this._references = {};
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
}
// CLASS DATATAG ************************************************************************
export class Class_DataTag extends Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_DataTag.
     * @param {string} name
     * @param {Class_TagGroup} group
     * @param {Class_Sankey} sankey
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_DataTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._references = {};
        this._group = group;
        this._references = sankey.links_dict;
        // Update all links
        Object.values(this._references)
            .forEach(ref => ref.addDataTag(this));
    }
    // PUBLIC METHODS =====================================================================
    update() {
        Object.values(this._references)
            .forEach(element => {
            element.drawWithNodes();
        });
    }
    /**
     * Copy all attributes from input tags + Set the same refs
     *
     * @param {Class_DataTag} tag
     * @memberof Class_DataTag
     */
    copyFrom(tag) {
        super.copyFrom(tag);
        // No need for reference synchro here
        // -> will be done from new links creation / removal
        // + will be done from new datatags creation / removal
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Update all links
        Object.values(this._references)
            .forEach(link => link.removeDataTag(this));
        // Unref references
        this._references = {};
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
}
// CLASS LEVELTAG ***********************************************************************
export class Class_LevelTag extends Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_LevelTag.
     * @param {string} name
     * @param {Class_LevelTagGroup} group
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_LevelTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._dimensions_as_tag_for_parent = {};
        this._dimensions_as_tag_for_children = {};
        this._group = group;
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Need to delete references
        this.dimensions_list_as_tag_for_children
            .forEach(dim => dim.removeTagFromChildrenLevelTag(this));
        this._dimensions_as_tag_for_children = {};
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => dim.delete());
        this._dimensions_as_tag_for_parent = {};
        // Let the garbage collector do the rest
    }
    /**
     * Copy all attributes from input tags + Set the same refs
     *
     * @param {Class_DataTag} tag
     * @memberof Class_DataTag
     */
    copyFrom(tag) {
        // Copy herited attributes
        super.copyFrom(tag);
        // Get all existing references ------------------------------------------------------
        // Create a dict of all existing node in this related sankey
        const all_existing_nodes = this._ref_sankey.nodes_dict;
        // Create a dict of all existing dimensions in this related sankey
        const all_existing_dim = {};
        this._ref_sankey.level_taggs_list
            .forEach(tagg => {
            tagg.tags_list
                .forEach(tag => {
                // Check children dimensions
                tag.dimensions_list_as_tag_for_children
                    .filter(dim => !(dim.id in all_existing_dim))
                    .forEach(dim => all_existing_dim[dim.id] = dim);
                // Check parent dimensions
                tag.dimensions_list_as_tag_for_parent
                    .filter(dim => !(dim.id in all_existing_dim))
                    .forEach(dim => all_existing_dim[dim.id] = dim);
            });
        });
        // Synchro dimensions where tag is for children -------------------------------------
        // Add missing but existing dimensions where this is a tag for children
        tag.dimensions_list_as_tag_for_children
            .filter(dim => {
            return ((dim.id in all_existing_dim) &&
                !(dim.id in this._dimensions_as_tag_for_children));
        })
            .forEach(dim => {
            this.addAsChildrenLevel(all_existing_dim[dim.id]);
        });
        // Add missing and non-existing dimensions where this is a tag for children
        tag.dimensions_list_as_tag_for_children
            .filter(dim => {
            // Verify if there is at least one child that exist in related sankey
            let at_least_one_match_for_children = false;
            dim.children
                .forEach(child => at_least_one_match_for_children = ((at_least_one_match_for_children) ||
                (child.id in all_existing_nodes)));
            // And verify that parent also exists in related sankey
            // And that related tag for parent is in the same group
            return (!(dim.id in all_existing_dim) &&
                (dim.parent.id in all_existing_nodes) &&
                (at_least_one_match_for_children) &&
                (dim.parent_level_tag.id in this.group.tags_dict));
        })
            .forEach(dim => {
            const parent = all_existing_nodes[dim.parent.id];
            const children = dim.children.map(_ => all_existing_nodes[_.id]);
            const parent_level_tag = this.group.tags_dict[dim.parent_level_tag.id];
            const new_dim = new Class_NodeDimension(parent, children, parent_level_tag, [this], dim.id);
            this.addAsChildrenLevel(new_dim);
        });
        // Remove existing dimension where this tag is no more
        this.dimensions_list_as_tag_for_children
            .filter(dim => {
            return (!(dim.id in tag._dimensions_as_tag_for_children));
        })
            .forEach(dim => this.removeChildrenLevel(dim));
        // Synchro dimensions where tag is for parents --------------------------------------
        // Add missing but existing dimensions where this is a tag for parent
        tag.dimensions_list_as_tag_for_parent
            .filter(dim => {
            return ((dim.id in all_existing_dim) &&
                !(dim.id in this._dimensions_as_tag_for_parent));
        })
            .forEach(dim => {
            this.addAsParentLevel(all_existing_dim[dim.id]);
        });
        // Add missing and non-existing dimensions where this is a tag for parent
        tag.dimensions_list_as_tag_for_parent
            .filter(dim => {
            // Verify if there is at least one child that exist in related sankey
            let ok_for_children_nodes = false;
            dim.children
                .forEach(child => ok_for_children_nodes = ((ok_for_children_nodes) ||
                (child.id in all_existing_nodes)));
            // And that related tag for parent is in the same group
            let ok_children_level_tags = false;
            dim.children_level_tags
                .forEach(tag => ok_children_level_tags = ((ok_children_level_tags) ||
                (tag.id in this.group.tags_dict)));
            // And verify that parent also exists in related sankey
            return (!(dim.id in all_existing_dim) &&
                (dim.parent.id in all_existing_nodes) &&
                (ok_for_children_nodes) &&
                (ok_children_level_tags));
        })
            .forEach(dim => {
            const parent = all_existing_nodes[dim.parent.id];
            const children = dim.children.map(_ => all_existing_nodes[_.id]);
            const children_level_tag = dim.children_level_tags
                .filter(tag => tag.id in this.group.tags_dict)
                .map(tag => this.group.tags_dict[tag.id]);
            const new_dim = new Class_NodeDimension(parent, children, this, children_level_tag, dim.id);
            this.addAsParentLevel(new_dim);
        });
        // Remove existing dimension where this tag is no more
        this.dimensions_list_as_tag_for_parent
            .filter(dim => {
            return (!(dim.id in tag._dimensions_as_tag_for_parent));
        })
            .forEach(dim => this.removeParentLevel(dim));
    }
    // PUBLIC METHODS =====================================================================
    setSelected() {
        // Exclude other levels tags from selection and reinit dimension to default dehavior
        this._group.tags_list
            .filter(tag => tag !== this)
            .forEach(tag => tag.setUnSelected());
        this.dimensions_list_as_tag_for_children
            .forEach(dim => dim.showFromLevelTags());
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => dim.showFromLevelTags());
        // Apply selection
        super.setSelected();
    }
    setUnSelected() {
        // Reinit dimension to default dehavior
        this.dimensions_list_as_tag_for_children
            .forEach(dim => dim.showFromLevelTags());
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => dim.showFromLevelTags());
        // Apply unselection
        super.setUnSelected();
    }
    update() {
        this.dimensions_list_as_tag_for_children
            .forEach(dim => {
            dim.children
                .forEach(child => child.draw());
        });
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => dim.parent.draw());
    }
    getOrCreateLowerDimension(parent, child, child_tags) {
        // First check if tags are from the same group
        let same_group = true;
        child_tags
            .forEach(_ => same_group = (same_group && this.group === _.group));
        if (same_group) {
            // Try to find matching dimension with :
            // - this as parent tag
            // - input child_tag as children tag
            // - parent node as parent
            let dimension_found;
            this.dimensions_list_as_tag_for_parent
                .forEach(dimension => {
                // Check if children tag list contains the same tags as in dimensions children tag list
                let ok_children_level_tags = true;
                dimension.children_level_tags.forEach(tag => ok_children_level_tags = ok_children_level_tags && child_tags.includes(tag));
                child_tags.forEach(tag => ok_children_level_tags = ok_children_level_tags && dimension.children_level_tags.includes(tag));
                // Match dimension if all these conditions are true
                // - Parent are the same
                // - Parent level tags are the same
                // - All children level tags are the same
                if ((dimension.parent_level_tag === this) &&
                    (ok_children_level_tags) &&
                    (dimension.parent === parent)) {
                    dimension_found = dimension;
                }
            });
            // If found - just add child
            if (dimension_found) {
                dimension_found.addNodeAsChild(child);
            }
            // If no dimension has been found, create a new one
            else {
                dimension_found = new Class_NodeDimension(parent, [child], this, child_tags);
            }
            // Return
            return dimension_found;
        }
        return null;
    }
    isLevelForChildren(_) {
        return (this._dimensions_as_tag_for_children[_.id] !== undefined);
    }
    isLevelForParent(_) {
        return (this._dimensions_as_tag_for_parent[_.id] !== undefined);
    }
    addAsChildrenLevel(_) {
        if (!this.isLevelForChildren(_)) {
            this._dimensions_as_tag_for_children[_.id] = _;
            _.addTagAsChildrenLevelTag(this);
        }
    }
    addAsParentLevel(_) {
        if (!this.isLevelForParent(_)) {
            this._dimensions_as_tag_for_parent[_.id] = _;
            _.parent_level_tag = this;
        }
    }
    removeChildrenLevel(_) {
        if (this.isLevelForChildren(_)) {
            delete this._dimensions_as_tag_for_children[_.id];
            _.removeTagFromChildrenLevelTag(this);
        }
    }
    removeParentLevel(_) {
        if (this.isLevelForParent(_)) {
            delete this._dimensions_as_tag_for_parent[_.id];
            _.delete();
        }
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
    get has_upper_dimensions() {
        return (this.dimensions_list_as_tag_for_children.length > 0);
    }
    get has_lower_dimensions() {
        return (this.dimensions_list_as_tag_for_parent.length > 0);
    }
    get dimensions_list_as_tag_for_parent() {
        return Object.values(this._dimensions_as_tag_for_parent);
    }
    get dimensions_list_as_tag_for_children() {
        return Object.values(this._dimensions_as_tag_for_children);
    }
}
// CLASS PROTO TAGGROUP *****************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey) {
        // List of tags
        this._tag_count = 0;
        // Type of banne
        this._banner = 'one';
        /**
         * True if tag is currently on a deletion process
         * Avoid infinite calls of delete() method
         * @private
         * @memberof Class_TagGroup
         */
        this._is_currently_deleted = false;
        this._id = id;
        this._name = name;
        this._ref_sankey = sankey;
    }
    /**
     * Define deletion behavior
     * @memberof Class_ProtoTagGroup
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Delete all tags properly
            Object.values(this._tags)
                .forEach(tag => {
                tag.delete();
            });
            this._tags = {};
            // Garbage collection will do the rest ...
        }
    }
    // PUBLIC METHODS =====================================================================
    addTag(name, id = undefined) {
        const tag = this.createTag(name, id);
        this._tags[tag.id] = tag;
        this._tag_count = this._tag_count + 1;
        return tag;
    }
    addDefaultTag() {
        const n = String(this._tag_count);
        const name = 'Etiquette ' + n;
        this.addTag(name);
    }
    removeTag(_) {
        if (this._tags[_.id] !== undefined) {
            _.delete();
            delete this._tags[_.id];
        }
    }
    selectTagsFromId(id) {
        this.tags_list
            .forEach(tag => {
            if (tag.id === id) {
                tag.setSelected();
            }
            else {
                tag.setUnSelected();
            }
        });
    }
    selectTagsFromIds(ids) {
        this.tags_list
            .forEach(tag => {
            if (ids.includes(tag.id)) {
                tag.setSelected();
            }
            else {
                tag.setUnSelected();
            }
        });
    }
    updateTagsReferences() {
        Object.values(this._tags)
            .forEach(tag => tag.update());
    }
    toJSON() {
        // Create empty structs
        const json_object = {};
        const json_object_tags = {};
        // Fill group attributes
        json_object['name'] = this._name;
        json_object['banner'] = this._banner;
        // Update tags infos
        this.tags_list
            .forEach(tag => {
            json_object_tags[tag.id] = tag.toJSON();
        });
        json_object['tags'] = json_object_tags;
        // Out
        return json_object;
    }
    /**
     * Set Tag_group value & substructure from JSON
     *
     * @param {Type_JSON} json_object
     * @memberof Class_TagGroup
     */
    fromJSON(json_object, matching_tags_id = {}) {
        // Read legacy JSON
        this.fromLegacyJSON(json_object);
        // Read group attributes
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._banner = getStringFromJSON(json_object, 'banner', this._banner);
        // Create new tags & read their attributes
        Object.entries(json_object['tags'])
            .forEach(([_, tag_json]) => {
            var _a, _b;
            // Get or Create tag
            const tag_id = (_a = matching_tags_id[_]) !== null && _a !== void 0 ? _a : _;
            const tag = (_b = this._tags[_]) !== null && _b !== void 0 ? _b : this.addTag(tag_id, tag_id); // Tag will be renamed in fromJSON method
            // Update tag with json
            tag.fromJSON(tag_json);
        });
    }
    copyFrom(element, tags_synchro = true) {
        // Common attributes
        this._name = element._name;
        this._banner = element._banner;
        this._tag_count = element._tag_count;
        // Synchronize tags
        if (tags_synchro) {
            // Delete tags not present in new layout but present in curr
            this.tags_list
                .filter(tag => !(tag.id in element.tags_dict))
                .forEach(tag => {
                this.removeTag(tag);
            });
            // Transfer tags attr present in new layout and in curr
            this.tags_list
                .filter(tag => (tag.id in element.tags_dict))
                .forEach(tag => {
                tag.copyFrom(element.tags_dict[tag.id]);
            });
            // Add tag present in element but not this
            element.tags_list
                .filter(tag => !(tag.id in this.tags_dict))
                .forEach(tag => {
                this.addTag(tag.name, tag.id).copyFrom(tag);
            });
        }
    }
    // PRIVATE METHODS ====================================================================
    fromLegacyJSON(json_object) {
        this._name = getStringFromJSON(json_object, 'group_name', this._name);
    }
    // GETTERS ============================================================================
    /**
     * Id of tag group
     * @readonly
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get id() { return this._id; }
    /**
     * Name of tag group (!= id)
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get name() { return this._name; }
    /**
     * True if tag group has tags
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_tags() { return this.tags_list.length > 0; }
    /**
     * True if tag group has tags selected
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_selected_tags() { return this.selected_tags_list.length > 0; }
    get first_selected_tags() {
        if (this.has_tags)
            if (this.has_selected_tags)
                return this.selected_tags_list[0];
            else
                return this.tags_list[0];
        else
            return undefined;
    }
    get banner() { return this._banner; }
    // SETTERS ============================================================================
    set name(value) { this._name = value; }
    set banner(value) { this._banner = value; }
}
// CLASS TAGGROUP ***********************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_TagGroup extends Class_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey, with_a_tag = true) {
        super(id, name, sankey);
        // PROTECTED ATTRIBUTES ===============================================================
        this._tags = {};
        // PRIVATE ATTRIBUTES =================================================================
        // Display attributes
        this._show_legend = false;
        // Default banner as multi
        this.banner = 'multi';
        // Create a first default tag
        if (with_a_tag)
            this.addTag('Etiquette 0');
    }
    // PUBLIC METHODS =====================================================================
    toJSON() {
        const json_object = super.toJSON();
        json_object['show_legend'] = this._show_legend;
        return json_object;
    }
    /**
     *Set Tag_group value & substructur from JSON
     *
     * @param {Type_JSON} json_object
     * @memberof Class_TagGroup
     */
    fromJSON(json_object, matching_tags_id = {}) {
        super.fromJSON(json_object, matching_tags_id);
        this._show_legend = getBooleanFromJSON(json_object, 'show_legend', this._show_legend);
    }
    /**
     * Copy tags group attributes from element to current & copy tags
     *
     * @param {Class_TagGroup} element
     * @memberof Class_TagGroup
     */
    copyFrom(element) {
        super.copyFrom(element);
        this._show_legend = element.show_legend;
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        const tag = new Class_Tag(name, this, this._ref_sankey, id);
        tag.setSelected();
        return tag;
    }
    // GETTER =============================================================================
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_Tag }}
     * @memberof Class_TagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_Tag }}
     * @memberof Class_TagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_TagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
    get show_legend() { return this._show_legend; }
    // SETTER ==============================================================================
    set show_legend(value) {
        // Avoid useless updates
        if (this._show_legend !== value) {
            this._show_legend = value;
            this.updateTagsReferences();
        }
    }
}
// CLASS DATATAGGROUP *******************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_DataTagGroup extends Class_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey, with_a_tag = true) {
        super(id, name, sankey);
        // PRIVATE ATTRIBUTES =================================================================
        // Display attributes
        this._show_legend = false;
        // PROTECTED ATTRIBUTES ===============================================================
        this._tags = {};
        // Create and select a first default tag
        if (with_a_tag) {
            const tag = this.addTag('Etiquette 0');
            tag.setSelected();
        }
    }
    // PUBLIC METHODS =====================================================================
    selectTagsFromId(id) {
        super.selectTagsFromId(id);
        this.checkSelectionCoherence();
    }
    selectTagsFromIds(ids) {
        super.selectTagsFromIds(ids);
        this.checkSelectionCoherence();
    }
    toJSON() {
        const json_object = super.toJSON();
        json_object['show_legend'] = this._show_legend;
        return json_object;
    }
    /**
     * Set Tag_group value & substructure from JSON
     * @param {Type_JSON} json_object
     * @memberof Class_TagGroup
     */
    fromJSON(json_object, matching_tags_id = {}) {
        super.fromJSON(json_object, matching_tags_id);
        this._show_legend = getBooleanFromJSON(json_object, 'show_legend', this._show_legend);
    }
    copyFrom(element) {
        super.copyFrom(element, true);
        this._show_legend = element.show_legend;
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        return new Class_DataTag(name, this, this._ref_sankey, id);
    }
    // PRIVATE METHODES ===================================================================
    /**
     * Permet d'eviter de désélectionner tous les dataTags ce qui créerait une erreur
     * @private
     * @memberof Class_DataTagGroup
     */
    checkSelectionCoherence() {
        var _a;
        if (this.selected_tags_list.length === 0) {
            (_a = this.tags_list[0]) === null || _a === void 0 ? void 0 : _a.setSelected();
        }
    }
    // GETTER =============================================================================
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_DataTag }}
     * @memberof Class_DataTagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {Class_DataTag[]}
     * @memberof Class_DataTagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_DataTagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
    get show_legend() { return this._show_legend; }
    // SETTER ==============================================================================
    set show_legend(value) {
        // Avoid useless updates
        if (this._show_legend !== value) {
            this._show_legend = value;
            this.updateTagsReferences();
        }
    }
}
// CLASS LEVEL TAGGROUP *****************************************************************
/**
 * Tag group for node level
 * TODO fonctionnement à completer // dimension dans nodes
 * @export
 * @class Class_LevelTagGroup
 * @extends {Class_TagGroup}
 */
export class Class_LevelTagGroup extends Class_ProtoTagGroup {
    constructor() {
        // PROTECTED ATTRIBUTES ===============================================================
        super(...arguments);
        this._tags = {};
        // PRIVATE ATTRIBUTES==================================================================
        this._activated = false;
        this._siblings = [];
    }
    // PUBLIC METHODS =====================================================================
    toJSON() {
        const json_object = super.toJSON();
        json_object['activated'] = this._activated;
        json_object['sibling'] = this._siblings;
        return json_object;
    }
    fromJSON(json_object, matching_tags_id = {}) {
        super.fromJSON(json_object, matching_tags_id);
        this._activated = getBooleanFromJSON(json_object, 'activated', this._activated);
        this._siblings = getStringListFromJSON(json_object, 'sibling', this._siblings);
    }
    copyFrom(element) {
        super.copyFrom(element);
        this._activated = element._activated;
        this._siblings = element._siblings;
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        const tag = new Class_LevelTag(name, this, this._ref_sankey, id);
        tag.setUnSelected();
        return tag;
    }
    // GETTERS / SETTERS ==================================================================
    get activated() { return this._activated; }
    set activated(value) {
        // Avoid useless updates
        if (this._activated !== value) {
            this._activated = value;
            this.updateTagsReferences();
        }
    }
    get siblings() { return this._siblings; }
    set siblings(value) {
        this._siblings = value;
        this.updateTagsReferences();
    }
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_DataTag }}
     * @memberof Class_DataTagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {Class_DataTag[]}
     * @memberof Class_DataTagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_DataTagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
}
