import { Class_DrawingArea } from 'open-sankey/dist/types/DrawingArea';
import { Class_SankeyPlus } from './SankeyPlus';
import { initial_window_width, initial_window_height } from 'open-sankey/dist/types/ApplicationData';
export class Class_DrawingAreaPlus extends Class_DrawingArea {
    constructor(height, width, application_data) {
        super(height, width, application_data);
        this._contextualised_free_label = undefined;
        // override _sankey:Class_SankeyPlus
        // private _sankey_plus:Class_SankeyPlus=this._sankey
        /**
         * d3 selection of svg group that contains drawing area free labels
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof Class_DrawingArea
         */
        this.d3_selection_free_label = null;
        this._sankey = (new Class_SankeyPlus(this, this.application_data.menu_configuration));
    }
    // Public Method =======================
    /**
     * Override Reset drawing area from OS
     * @memberof Class_DrawingArea
     */
    reset() {
        var _a, _b;
        super.reset();
        // Add specific groups for free_labels, link and others
        this.d3_selection_free_label = (_b = (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.insert('g', '#g_links').attr('id', 'g_labels')) !== null && _b !== void 0 ? _b : null;
        this.drawElements();
    }
    drawElements() {
        super.drawElements();
        this.sankey_plus.free_labels_list.forEach(zdt => zdt.draw());
    }
    /**
     * Override checkAndUpdateAreaSize so it take into account free labels
     *
     * @memberof Class_DrawingAreaPlus
     */
    checkAndUpdateAreaSize() {
        super.checkAndUpdateAreaSize();
        let max_free_label_pos_x = 0;
        let max_free_label_pos_y = 0;
        this.sankey_plus.visible_free_labels_list.filter(free_label => free_label.display.position.type === 'absolute').map(free_label => {
            const free_label_rightest_pos = free_label.position_x + free_label.label_width;
            const free_label_bottomest_pos = free_label.position_y + free_label.label_height;
            max_free_label_pos_x = Math.max(max_free_label_pos_x, free_label_rightest_pos);
            max_free_label_pos_y = Math.max(max_free_label_pos_y, free_label_bottomest_pos);
        });
        // If righest free_label is too close to right drawing area border then enlarege DA
        // else reduce DA until window init witdh
        // (init DA size is computed with a sankey at scale 1 )
        if ((max_free_label_pos_x > this._width - this.grid_size) || ((max_free_label_pos_x + this._grid_size <= this._width) && (this._width > initial_window_width))) {
            this.setWidth(max_free_label_pos_x + this._grid_size);
            this.drawGrid();
        }
        // If bottomiest free_label is too close to the bottom of drawing area border then enlarege DA
        // else reduce DA until window init height
        // (init DA size is computed with a sankey at scale 1 )
        if (max_free_label_pos_y > this._height - this.grid_size || ((max_free_label_pos_y + this._grid_size <= this._height) && (this._height > initial_window_height))) {
            this.setHeight(max_free_label_pos_y + this._grid_size);
            this.drawGrid();
        }
    }
    /**
     * add a free labels from a selection set
     *
     * @param {Class_FreeLabel} zdt
     * @memberof Class_DrawingAreaPlus
     */
    addFreeLabelToSelection(zdt) {
        this._selection[zdt.id] = zdt;
        zdt.setSelected();
    }
    addNewDefaultNodeToSankey() {
        return this.sankey.addNewDefaultNode();
    }
    /**
     * remove a zdt from a selection set
     * @param {Class_FreeLabel} node
     * @memberof Class_DrawingAreaPlus
     */
    removeFreeLabelFromSelection(zdt) {
        if (this._selection[zdt.id] !== undefined) {
            delete this._selection[zdt.id];
            zdt.setUnSelected();
        }
    }
    /**
     * override purgeSelection to include event for OSP DA
     *
     * @memberof Class_DrawingAreaPlus
     */
    purgeSelection() {
        super.purgeSelection();
        this.application_data_plus.menu_configuration.ref_to_menu_config_free_label_updater.current();
    }
    // ============GETTER && SETTER ==================
    get sankey_plus() { return this._sankey; }
    get sankey() { return this._sankey; }
    set sankey(_) { this._sankey = _; }
    get application_data_plus() {
        return this.application_data;
    }
    get selected_nodes_list_plus() { return this.selected_nodes_list; }
    get selected_free_labels_list() { return this.sankey_plus.free_labels_list.filter(zdt => zdt.is_selected); }
    get selected_free_labels_list_sorted() { return this.selected_free_labels_list.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)); }
    get contextualised_free_label() { return this._contextualised_free_label; }
    set contextualised_free_label(value) { this._contextualised_free_label = value; }
}
