// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { useRef } from 'react';
import { menu_config_width } from '../topmenus/SankeyMenuTop';
// CLASS MENU CONFIG ********************************************************************
/**
 * Define shortcut to update menu components
 * @export
 * @class Class_MenuConfig
 */
export class Class_MenuConfig {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_MenuConfig.
     * @memberof Class_MenuConfig
     */
    constructor() {
        // Init button ref ------------------------------------------------------------------
        /* ========================================
          Updater of component in the configuration menu
          ========================================*/
        /**
         *   List of accordions to show
         * @protected
         * @type {string[]}
         * @memberof Class_MenuConfig
         */
        this._accordions_to_show = [];
        // Update component SankeySettingsEditionElementTags
        this._ref_to_menu_config_tags_updater = {};
        /* ========================================
          Visible Nodes / Links selectors
        =========================================== */
        this._selector_only_visible_nodes = false;
        this._selector_only_visible_links = false;
        // Ref to style of currently selected node(s)
        this._ref_selected_style_node = useRef('default');
        // Ref to style of currently selected link(s)
        this._ref_selected_style_link = useRef('default');
        // Var to hide welcome menu when we relaucnh application
        this._never_see_again = useRef((localStorage.getItem('dontSeeAggainWelcome') === '1'));
        this._ref_to_btn_toogle_menu = useRef(null);
        this._ref_to_btn_accordion_config_elements = useRef(null);
        this._ref_to_btn_accordion_config_node = useRef(null);
        this._ref_to_btn_accordion_config_link = useRef(null);
        // Init menu component updater ------------------------------------------------------
        this._ref_to_menu_updater = useRef(() => null);
        this._ref_to_menu_config_updater = useRef(() => null);
        this._ref_menu_opened = useRef(false);
        this._ref_lauchToast = useRef(() => null),
            this._function_on_wait = useRef(() => null);
        // Layout
        this._ref_to_menu_config_layout_updater = useRef(() => null);
        // Nodes
        this._ref_to_menu_config_node_updater = useRef(() => null);
        this._ref_to_menu_config_node_apparence_updater = useRef(() => null);
        this._ref_to_menu_config_node_apparence_style_updater = useRef(() => null);
        this._ref_to_menu_config_node_tags_updater = useRef(() => null);
        this._ref_to_menu_config_node_io_updater = useRef(() => null);
        this._ref_to_menu_config_node_tooltips_updater = useRef(() => null);
        // Links
        this._ref_to_menu_config_link_updater = useRef(() => null);
        this._ref_to_menu_config_link_data_updater = useRef(() => null);
        this._ref_to_menu_config_link_apparence_updater = useRef(() => null);
        this._ref_to_menu_config_link_apparence_style_updater = useRef(() => null);
        this._ref_to_menu_config_link_tags_updater = useRef(() => null);
        this._ref_to_menu_config_link_tooltips_updater = useRef(() => null);
        // Tags
        this._ref_to_menu_config_tags_updater['node_taggs'] = useRef(() => null);
        this._ref_to_menu_config_tags_updater['flux_taggs'] = useRef(() => null);
        this._ref_to_menu_config_tags_updater['data_taggs'] = useRef(() => null);
        // Toolbar+
        this._ref_to_save_in_cache_indicator = useRef((_) => null);
        this._ref_to_toolbar_updater = useRef(() => null);
        this._updateToolbar = useRef(() => null);
        // Init context menu components updater ---------------------------------------------
        this._ref_to_menu_context_nodes_updater = useRef(() => null);
        this._ref_to_menu_context_links_updater = useRef(() => null);
        this._ref_to_menu_context_drawing_area_updater = useRef(() => null);
        // Init filtering components updater ------------------------------------------------
        this._ref_to_leveltag_filter_updater = useRef(() => null);
        this._ref_to_nodetag_filter_updater = useRef(() => null);
        this._ref_to_fluxtag_filter_updater = useRef(() => null);
        this._ref_to_datatag_filter_updater = useRef(() => null);
        // Init dict of setter show dialog -------------------------------------------------
        this._dict_setter_show_dialog = {
            ref_setter_show_menu_node_apparence: useRef(() => null),
            ref_setter_show_menu_node_io: useRef(() => null),
            ref_setter_show_menu_node_tooltip: useRef(() => null),
            ref_setter_show_menu_node_tags: useRef(() => null),
            ref_setter_show_menu_link_tags: useRef(() => null),
            ref_setter_show_menu_link_data: useRef(() => null),
            ref_setter_show_menu_link_appearence: useRef(() => null),
            ref_setter_show_menu_link_tooltip: useRef(() => null),
            ref_setter_show_menu_layout: useRef(() => null),
            ref_setter_show_modal_welcome: useRef(() => null),
            ref_setter_show_modale_tuto: useRef(() => null),
            ref_setter_show_modale_support: useRef(() => null),
            ref_setter_show_excel_dialog: useRef(() => null),
            ref_setter_show_save_json: useRef(() => null),
            ref_getter_show_save_json: useRef(false),
            ref_setter_show_style_node: useRef(() => null),
            ref_setter_show_style_link: useRef(() => null),
            ref_setter_show_apply_layout: useRef(() => null),
            ref_setter_show_modal_preference: useRef(() => null),
            ref_setter_show_modal_template: useRef(() => null),
            ref_setter_show_load: useRef(() => null),
            ref_lauchToast: useRef(() => null),
            ref_setter_show_resolution_save_png: useRef(() => null),
            ref_setter_png_res_h: useRef(() => null),
            ref_setter_png_res_v: useRef(() => null)
        };
    }
    // PUBLIC METHODS =====================================================================
    addToAccordionsToShow(_) {
        if (!this.isGivenAccordionShowed(_)) {
            this._accordions_to_show.push(_);
            this._ref_to_menu_config_updater.current();
        }
    }
    removeFromAccordionsToShow(_) {
        if (this.isGivenAccordionShowed(_)) {
            this._accordions_to_show = this._accordions_to_show
                .filter(to_show => to_show !== _);
            this._ref_to_menu_config_updater.current();
        }
    }
    toggleGivenAccordion(_) {
        if (this.isGivenAccordionShowed(_))
            this.removeFromAccordionsToShow(_);
        else
            this.addToAccordionsToShow(_);
    }
    isGivenAccordionShowed(_) {
        return this._accordions_to_show.includes(_);
    }
    /**
     * Open menu configuration
     * @memberof Class_MenuConfig
     */
    OpenConfigMenu() {
        // Check if we linked the ref to the button to toggle the menu
        // and if _ref_to_btn_accordion_config_elements is null it mean the menu is closed(because the accordion is not rendered if the menu is closed)
        if (this._ref_to_btn_toogle_menu &&
            this._ref_to_btn_toogle_menu.current &&
            this._ref_to_btn_accordion_config_elements.current === null) {
            this._ref_to_btn_toogle_menu.current.click();
        }
    }
    /**
     * Open menu configuration
     * @memberof Class_MenuConfig
     */
    CloseConfigMenu() {
        // Check if we linked the ref to the button to toggle the menu
        // and if _ref_to_btn_accordion_config_elements is null it mean the menu is closed(because the accordion is not rendered if the menu is closed)
        if (this._ref_to_btn_toogle_menu &&
            this._ref_to_btn_toogle_menu.current &&
            this._ref_to_btn_accordion_config_elements.current !== null) {
            this._ref_to_btn_toogle_menu.current.click();
        }
    }
    /**
     * Check if we linked the ref to the button to open elements accordion
     * and check if the accordion elements is open then click to the button
     * that _ref_to_btn_accordion_config_elements ref to
     *
     * @memberof Class_MenuConfig
     */
    OpenConfigMenuElements() {
        // Config menu must be opened first
        this.OpenConfigMenu();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Element accordion
            if (this._ref_to_btn_accordion_config_elements.current &&
                (d3.select(this._ref_to_btn_accordion_config_elements.current).attr('aria-expanded') === 'false')) {
                this._ref_to_btn_accordion_config_elements.current.click();
            }
        }, 500);
    }
    /**
     * Check if we linked the ref to the button to toggle the menu
     * and check if the accordion nodes is open then click to the button
     * _ref_to_btn_accordion_config_node ref to
     *
     * @memberof Class_MenuConfig
     */
    OpenConfigMenuElementsNodes() {
        // Element config men umust be opened first
        this.OpenConfigMenuElements();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Node element menu
            if (this._ref_to_btn_accordion_config_node.current &&
                (d3.select(this._ref_to_btn_accordion_config_node.current).attr('aria-expanded') === 'false')) {
                this._ref_to_btn_accordion_config_node.current.click();
            }
        }, 500);
    }
    /**
     * Check if we linked the ref to the button to toggle the menu
     * and check if the accordion nodes is open then click to the button
     * _ref_to_btn_accordion_config_link ref to
     *
     * @memberof Class_MenuConfig
     */
    OpenConfigMenuElementsLinks() {
        // Element config men umust be opened first
        this.OpenConfigMenuElements();
        // Leave enough time for menus to open
        setTimeout(() => {
            if (this._ref_to_btn_accordion_config_link.current &&
                d3.select(this._ref_to_btn_accordion_config_link.current).attr('aria-expanded') === 'false') {
                this._ref_to_btn_accordion_config_link.current.click();
            }
        }, 500);
    }
    /**
     *
     *
     * @memberof Class_MenuConfig
     */
    updateAllMenuComponents() {
        this.updateAllComponentsRelatedToNodes();
        this.updateAllComponentsRelatedToLinks();
        this._ref_to_toolbar_updater.current();
        Object.values(this._ref_to_menu_config_tags_updater)
            .forEach(ref => ref.current());
    }
    /**
     * Re-render all menus for node config
     * - SankeyNodeEdition
     * - OpenSankeyConfigurationNodesAttributes
     * - OpenSankeyConfigurationNodesTags
     * - SankeyMenuConfigurationNodesIO
     * - SankeyMenuConfigurationNodesTooltip
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToNodes() {
        this._ref_to_menu_config_node_updater.current();
        this.updateAllComponentsRelatedToNodesConfig();
    }
    /**
     * Re-render all submenus for node config
     * - OpenSankeyConfigurationNodesAttributes
     * - OpenSankeyConfigurationNodesTags
     * - SankeyMenuConfigurationNodesIO
     * - SankeyMenuConfigurationNodesTooltip
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToNodesConfig() {
        this._ref_to_menu_config_node_apparence_updater.current();
        this._ref_to_menu_config_node_apparence_style_updater.current();
        this._ref_to_menu_config_node_tags_updater.current();
        this._ref_to_menu_config_node_io_updater.current();
        this._ref_to_menu_config_node_tooltips_updater.current();
    }
    /**
     * Re-render all menus for link config
     * - SankeyMenuConfigurationLinks
     * - MenuConfigurationLinksData
     * - MenuConfigurationLinksAppearence
     * - MenuConfigurationLinksTags
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToLinks() {
        this._ref_to_menu_config_link_updater.current();
        this.updateAllComponentsRelatedToLinksConfig();
    }
    /**
     * Re-render all submenus for link config
     * - MenuConfigurationLinksData
     * - MenuConfigurationLinksAppearence
     * - MenuConfigurationLinksTags
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToLinksConfig() {
        this._ref_to_menu_config_link_data_updater.current();
        this._ref_to_menu_config_link_apparence_updater.current();
        this._ref_to_menu_config_link_apparence_style_updater.current();
        this._ref_to_menu_config_link_tags_updater.current();
        this._ref_to_menu_config_link_tooltips_updater.current();
    }
    updateAllComponentsRelatedToTags() {
        this._ref_to_menu_config_node_updater.current();
        this._ref_to_menu_config_node_tags_updater.current();
        this._ref_to_menu_config_link_updater.current();
        this._ref_to_menu_config_link_tags_updater.current();
        this._ref_to_menu_config_link_data_updater.current();
        this._ref_to_toolbar_updater.current();
    }
    updateAllComponentsRelatedToLevelTags() {
        this._ref_to_leveltag_filter_updater.current();
    }
    updateAllComponentsRelatedToNodeTags() {
        this._ref_to_nodetag_filter_updater.current();
        this._ref_to_leveltag_filter_updater.current();
        this._ref_to_menu_config_node_tags_updater.current();
        this._ref_to_menu_config_tags_updater['node_taggs'].current();
    }
    updateAllComponentsRelatedToFluxTags() {
        this._ref_to_fluxtag_filter_updater.current();
        this._ref_to_menu_config_link_tags_updater.current();
        this._ref_to_menu_config_tags_updater['flux_taggs'].current();
    }
    updateAllComponentsRelatedToDataTags() {
        this._ref_to_datatag_filter_updater.current();
        this._ref_to_menu_config_link_data_updater.current();
        this._ref_to_menu_config_link_tags_updater.current();
        this._ref_to_menu_config_tags_updater['data_taggs'].current();
    }
    updateAllComponentsRelatedToTagsType(type) {
        if (type === 'data_taggs')
            this.updateAllComponentsRelatedToDataTags();
        else if (type === 'flux_taggs')
            this.updateAllComponentsRelatedToFluxTags();
        else if (type === 'node_taggs')
            this.updateAllComponentsRelatedToNodeTags();
        else
            this.updateAllComponentsRelatedToLevelTags();
    }
    toggle_selector_on_visible_nodes() {
        this._selector_only_visible_nodes = !this._selector_only_visible_nodes;
        this.updateAllComponentsRelatedToNodes();
    }
    toggle_selector_on_visible_links() {
        this._selector_only_visible_links = !this._selector_only_visible_links;
        this.updateAllComponentsRelatedToLinks();
    }
    /**
     * Function to position horizontally the toolbar, it's position depend if the configuration menu is opened
     * @memberof Class_MenuConfig
     */
    positionToolBar() {
        d3.select('.sideToolBar').transition().duration(300).style('right', ((this._ref_menu_opened.current ? menu_config_width : 0)) + 'px');
    }
    // GETTERS / SETTERS ==================================================================
    // Main menu component ----------------------------------------------------------------
    get ref_to_menu_updater() {
        return this._ref_to_menu_updater;
    }
    get ref_menu_opened() {
        return this._ref_menu_opened;
    }
    get ref_lauchToast() {
        return this._ref_lauchToast;
    }
    get function_on_wait() {
        return this._function_on_wait;
    }
    // Accordion menu openers -------------------------------------------------------------
    get accordions_to_show() {
        return this._accordions_to_show;
    }
    set accordions_to_show(_) {
        this._accordions_to_show = _;
        this._ref_to_menu_config_updater.current();
    }
    get ref_to_btn_toogle_menu() {
        return this._ref_to_btn_toogle_menu;
    }
    get ref_to_btn_accordion_config_elements() {
        return this._ref_to_btn_accordion_config_elements;
    }
    get ref_to_btn_accordion_config_node() {
        return this._ref_to_btn_accordion_config_node;
    }
    get ref_to_btn_accordion_config_link() {
        return this._ref_to_btn_accordion_config_link;
    }
    get ref_to_menu_config_updater() {
        return this._ref_to_menu_config_updater;
    }
    // Layout  menus ----------------------------------------------------------------------
    get ref_to_menu_config_layout_updater() {
        return this._ref_to_menu_config_layout_updater;
    }
    get ref_to_menu_context_drawing_area_updater() {
        return this._ref_to_menu_context_drawing_area_updater;
    }
    // Nodes menus ------------------------------------------------------------------------
    get ref_to_menu_config_node_updater() {
        return this._ref_to_menu_config_node_updater;
    }
    get ref_to_menu_config_node_apparence_updater() {
        return this._ref_to_menu_config_node_apparence_updater;
    }
    get ref_to_menu_config_node_apparence_style_updater() {
        return this._ref_to_menu_config_node_apparence_style_updater;
    }
    get ref_to_menu_config_node_tags_updater() {
        return this._ref_to_menu_config_node_tags_updater;
    }
    get ref_to_menu_config_node_io_updater() {
        return this._ref_to_menu_config_node_io_updater;
    }
    get ref_to_menu_config_node_tooltips_updater() {
        return this._ref_to_menu_config_node_tooltips_updater;
    }
    // Nodes context menu
    get ref_to_menu_context_nodes_updater() {
        return this._ref_to_menu_context_nodes_updater;
    }
    // Links menus ------------------------------------------------------------------------
    get ref_to_menu_config_link_updater() {
        return this._ref_to_menu_config_link_updater;
    }
    get ref_to_menu_config_link_data_updater() {
        return this._ref_to_menu_config_link_data_updater;
    }
    get ref_to_menu_config_link_apparence_updater() {
        return this._ref_to_menu_config_link_apparence_updater;
    }
    get ref_to_menu_config_link_apparence_style_updater() {
        return this._ref_to_menu_config_link_apparence_style_updater;
    }
    get ref_to_menu_config_link_tags_updater() {
        return this._ref_to_menu_config_link_tags_updater;
    }
    get ref_to_menu_config_link_tooltips_updater() {
        return this._ref_to_menu_config_link_tooltips_updater;
    }
    // Link context menu
    get ref_to_menu_context_links_updater() {
        return this._ref_to_menu_context_links_updater;
    }
    // Tags menus -------------------------------------------------------------------------
    get ref_to_menu_config_tags_updater() {
        return this._ref_to_menu_config_tags_updater;
    }
    // Toolbar -----------------------------------------------------------------------------
    get ref_to_save_in_cache_indicator() {
        return this._ref_to_save_in_cache_indicator;
    }
    get ref_to_toolbar_updater() {
        return this._ref_to_toolbar_updater;
    }
    get updateToolbar() {
        return this._updateToolbar;
    }
    // Filtering components ---------------------------------------------------------------
    get ref_to_leveltag_filter_updater() {
        return this._ref_to_leveltag_filter_updater;
    }
    get ref_to_nodetag_filter_updater() {
        return this._ref_to_nodetag_filter_updater;
    }
    get ref_to_fluxtag_filter_updater() {
        return this._ref_to_fluxtag_filter_updater;
    }
    get ref_to_datatag_filter_updater() {
        return this._ref_to_datatag_filter_updater;
    }
    // Nodes / links selectors ------------------------------------------------------------
    get is_selector_only_for_visible_nodes() {
        return this._selector_only_visible_nodes;
    }
    get is_selector_only_for_visible_links() {
        return this._selector_only_visible_links;
    }
    // Getter dict of ref setter show dialog
    get dict_setter_show_dialog() {
        return this._dict_setter_show_dialog;
    }
    get ref_selected_style_node() {
        return this._ref_selected_style_node;
    }
    get ref_selected_style_link() {
        return this._ref_selected_style_link;
    }
    get never_see_again() {
        return this._never_see_again;
    }
}
