import { Class_Element } from 'open-sankey/dist/types/Element';
import { default_element_position } from 'open-sankey/dist/types/Utils';
import { default_selected_stroke_width } from 'open-sankey/dist/types/Node';
import { Class_Handler } from 'open-sankey/dist/types/Handler';
export class Class_FreeLabel extends Class_Element {
    // Constructor ====================================
    constructor(id, menu_config, drawing_area) {
        super(id, menu_config, 'g_labels');
        this._display = {
            drawing_area: drawing_area,
            position: structuredClone(default_element_position),
        };
        // Free labels attributs 
        this._title = 'Zone de texte ' + this.id;
        this._content = 'Text Label ...';
        this._label_width = 100;
        this._label_height = 25;
        this._color = 'white';
        this._color_border = 'black';
        this._opacity = 100;
        this._transparent_border = false;
        this._is_image = false;
        this._image_src = '';
        // Free labels drag handlers 
        this._drag_handler = {
            top: new Class_Handler('zdt_top_handle_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragTopHandler(), this.dragHandleEnd(), { class: 'zdt_top_handle' }),
            bottom: new Class_Handler('zdt_bottom_handle_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragBottomHandler(), this.dragHandleEnd(), { class: 'zdt_bottom_handle' }),
            left: new Class_Handler('zdt_left_handle_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragLeftHandler(), this.dragHandleEnd(), { class: 'zdt_left_handle' }),
            right: new Class_Handler('zdt_right_handle_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragRightHandler(), this.dragHandleEnd(), { class: 'zdt_right_handle' }),
        };
    }
    // PUBLIC METHOD ==========================
    draw() {
        var _a;
        super.draw();
        // Update class attributes
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_labels');
        this.drawShape();
        this.drawContent();
    }
    /**
     * Draw ZDT shape (a rectangle with custom size,bg color, bg opacity,border color, ...)
     *
     * @memberof Class_FreeLabel
     */
    drawShape() {
        var _a, _b, _c;
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.zdt_shape').remove();
        // Apply shape value
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('rect').classed('zdt_shape', true).attr('width', this._label_width).attr('height', this._label_height).attr('rx', 5);
        // Apply common properties
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.selectAll('.zdt_shape').attr('id', this.id).attr('fill-opacity', this._opacity / 100).attr('fill', this._color).style('stroke', this._color_border).style('stroke-width', this.is_selected ? default_selected_stroke_width : ((this._transparent_border) ? 0 : 1));
    }
    /**
     * Draw the content of the ZDT wich can be formated text or image
     *
     * @memberof Class_FreeLabel
     */
    drawContent() {
        var _a;
        // Clean svg group before (re)drawing zdt content
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.content').remove();
        if (this._is_image) {
            this.drawContentImage();
        }
        else {
            this.drawContentText();
        }
    }
    /**
     * Function triggered when element is (un)selected
     *
     * @memberof Class_FreeLabel
     */
    drawAsSelected() {
        this.drawShape();
        this.drawDragHandlers();
    }
    // PRIVATE METHODS ======================
    /**
     * Draw the content of the zdt when it is a formated text
     *
     * (Souldn't be called outside this class, to draw content use drawContent() )
     *
     * @private
     * @memberof Class_FreeLabel
     */
    drawContentText() {
        var _a;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('foreignObject').classed('content', true).attr('width', this._label_width).attr('height', this._label_height).style('width', this._label_width).style('height', this._label_height).attr('id', this.id + '_text').append('xhtml:div').attr('class', 'ql-editor').html(this._content);
    }
    /**
     * Draw the content of the zdt when it is an image
     *
     * (Souldn't be called outside this class, to draw content use drawContent() )
     *
     * @private
     * @memberof Class_FreeLabel
     */
    drawContentImage() {
        var _a;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('image').classed('content', true).attr('width', this._label_width).attr('height', this._label_height).style('width', this._label_width).style('height', this._label_height).attr('id', this.id + '_img').attr('href', this._image_src);
    }
    /**
   * Activate the control points alignement guide
   *
   * @private
   * @return {*}
   * @memberof Class_LinkElement
   */
    dragHandleStart() {
        return () => {
        };
    }
    /**
    * Deactivate the control points alignement guide
    * @private
    * @return {*}
    * @memberof Class_LinkElement
    */
    dragHandleEnd() {
        return () => {
            this.menu_config.ref_to_menu_config_free_label_updater.current();
        };
    }
    /**
     * Event when we drag the top handle
     *
     * @private
     * @return {*}
     * @memberof Class_FreeLabel
     */
    dragTopHandler() {
        return (event) => {
            this._label_width -= event.dy;
            this.setPosXY(this.position_y + event.dy);
            this.drawShape();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    /**
     * Event when we drag the bottom handle
     *
     * @private
     * @return {*}
     * @memberof Class_FreeLabel
     */
    dragBottomHandler() {
        return (event) => {
            this._label_height += event.dy;
            this.drawShape();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    /**
     * Event when we drag the left handle
     *
     * @private
     * @return {*}
     * @memberof Class_FreeLabel
     */
    dragLeftHandler() {
        return (event) => {
            this._label_width -= event.dx;
            this.setPosXY(this.position_x + event.dx);
            this.drawShape();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    /**
     * Event when we drag the right handle
     *
     * @private
     * @return {*}
     * @memberof Class_FreeLabel
     */
    dragRightHandler() {
        return (event) => {
            this._label_width += event.dx;
            this.drawShape();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    computeTopHandlerPos() {
        // Top handle pos
        this._drag_handler.top.position_x = this.position_x + this._label_width / 2;
        this._drag_handler.top.position_y = this.position_y + 0;
    }
    computeBottomHandlerPos() {
        // bottom handle pos
        this._drag_handler.bottom.position_x = this.position_x + this._label_width / 2;
        this._drag_handler.bottom.position_y = this.position_y + this._label_height;
    }
    computeLeftHandlerPos() {
        // left handle pos
        this._drag_handler.left.position_x = this.position_x + 0;
        this._drag_handler.left.position_y = this.position_y + this._label_height / 2;
    }
    computeRightHandlerPos() {
        // right handle pos
        this._drag_handler.right.position_x = this.position_x + this._label_width;
        this._drag_handler.right.position_y = this.position_y + this._label_height / 2;
    }
    /**
     * Draw all control points
     *
     * @private
     * @memberof Class_FreeLabel
     */
    drawDragHandlers() {
        this.computeTopHandlerPos();
        this.computeBottomHandlerPos();
        this.computeLeftHandlerPos();
        this.computeRightHandlerPos();
        // Draw control handler
        this._drag_handler.top.draw();
        this._drag_handler.bottom.draw();
        this._drag_handler.left.draw();
        this._drag_handler.right.draw();
    }
    // MOUSE EVENT ======================
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventSimpleLMBCLick(event) {
        super.eventSimpleLMBCLick(event);
        // Get related drawing area
        const drawing_area = this.drawing_area;
        // EDITION MODE ===========================================================
        if (drawing_area.isInEditionMode()) {
            // Purge selection list
            drawing_area.purgeSelection();
            // Close all menus
            drawing_area.application_data.menu_configuration.CloseConfigMenu();
        }
        // SELECTION MODE =========================================================
        else if (drawing_area.isInSelectionMode() && event.button === 0) {
            // SHIFT
            if (event.shiftKey) {
                // Add free label to selection
                drawing_area.addFreeLabelToSelection(this);
                // Open related menu
                this.menu_config.OpenConfigMenuElementsFreeLabels();
                // Update components related to free label edition
                this.menu_config.ref_to_menu_config_free_label_updater.current();
            }
            // CTRL
            else if (event.ctrlKey) {
                // Add free label to selection
                drawing_area.addFreeLabelToSelection(this);
                // Update components related to free label edition
                this.menu_config.ref_to_menu_config_free_label_updater.current();
            }
            // OTHERS
            else {
                // if we're here then it's a simple click (no ctrl,alt or shift key pressed) - purge
                // Purge selection list
                drawing_area.purgeSelection();
                // Add free label to selection
                drawing_area.addFreeLabelToSelection(this);
            }
        }
    }
    /**
     * Deal with double left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventDoubleLMBCLick(_event) {
        super.eventDoubleLMBCLick(_event);
    }
    /**
     * Deal with simple right Mouse Button (RMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventSimpleRMBCLick(_event) {
        super.eventSimpleRMBCLick(_event);
    }
    /**
     * Define maintained left mouse button click for free labels
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMaintainedClick(_event) {
        super.eventMaintainedClick(_event);
    }
    /**
     * Define released left mouse button click for drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventReleasedClick(_event) {
        super.eventMaintainedClick(_event);
    }
    /**
     * Define event when mouse moves over drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOver(_event) {
        super.eventMouseOver(_event);
    }
    /**
     * Define event when mouse moves out of drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOut(_event) {
        super.eventMouseOut(_event);
    }
    /**
     * Define event when mouse moves in drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseMove(_event) {
        super.eventMouseMove(_event);
    }
    /**
     * Define event when mouse drag starts
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDragStart(_event) {
        super.eventMouseDragStart(_event);
    }
    /**
     * Define event when mouse drag element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDrag(event) {
        super.eventMouseDrag(event);
        // Get related drawing area
        const drawing_area = this.drawing_area;
        const zdt_selected = drawing_area.selected_free_labels_list;
        if (zdt_selected.length == 0) {
            if (drawing_area.isInSelectionMode()) {
                this.setPosXY(this.position_x + event.dx, this.position_y + event.dy);
                this.drawDragHandlers();
                this.drawing_area.checkAndUpdateAreaSize();
            }
        }
        else if (zdt_selected.includes(this)) { // Only trigger the drag if we drag a selected free label
            // EDITION MODE ===========================================================
            if (drawing_area.isInEditionMode()) {
                // /* TODO définir  */
            }
            // SELECTION MODE =========================================================
            else {
                // Set position
                // Update free label position
                zdt_selected
                    .forEach(n => {
                    n.setPosXY(n.position_x + event.dx, n.position_y + event.dy);
                });
                this.drawDragHandlers();
            }
        }
    }
    /**
     * Define event when mouse drag ends
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDragEnd(_event) {
        if (this.drawing_area.isInSelectionMode()) {
            this.drawing_area.checkAndUpdateAreaSize();
        }
    }
    // ============GETTER && SETTER ==================
    get menu_config() { return super.menu_config; }
    get drawing_area() { return super.drawing_area; }
    get is_visible() { return super.is_visible; }
    get title() { return this._title; }
    set title(value) { this._title = value; }
    get content() { return this._content; }
    set content(value) { this._content = value; }
    get opacity() { return this._opacity; }
    set opacity(value) { this._opacity = value; }
    get color() { return this._color; }
    set color(value) { this._color = value; }
    get color_border() { return this._color_border; }
    set color_border(value) { this._color_border = value; }
    get transparent_border() { return this._transparent_border; }
    set transparent_border(value) { this._transparent_border = value; }
    get is_image() { return this._is_image; }
    set is_image(value) { this._is_image = value; }
    get image_src() { return this._image_src; }
    set image_src(value) { this._image_src = value; }
    get label_width() { return this._label_width; }
    set label_width(value) { this._label_width = value; }
    get label_height() { return this._label_height; }
    set label_height(value) { this._label_height = value; }
}
