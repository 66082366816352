import React, { useRef, useState } from 'react';
import { FaAlignLeft, FaAlignCenter, FaAlignRight, FaEyeSlash, FaEye, FaChevronDown, FaUndo } from 'react-icons/fa';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Box, Button, Checkbox, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Select, } from '@chakra-ui/react';
/*************************************************************************************************/
import { default_shape_arrow_size, default_shape_color, default_shape_ending_curve, default_shape_ending_tangeant, default_shape_is_arrow, default_shape_is_curved, default_shape_is_dashed, default_shape_is_recycling, default_shape_opacity, default_shape_orientation, default_shape_starting_curve, default_shape_starting_tangeant, default_value_label_color, default_value_label_custom_digit, default_value_label_font_family, default_value_label_font_size, default_value_label_is_visible, default_value_label_nb_digit, default_value_label_on_path, default_value_label_orthogonal_position, default_value_label_pos_auto, default_value_label_position, default_value_label_scientific_precision, default_value_label_to_precision, default_value_label_unit, default_value_label_unit_visible, isAttributeOverloaded, } from '../types/Link';
import { default_style_id } from '../types/Sankey';
import { font_families } from '../types/Utils';
/*************************************************************************************************/
import { TooltipValueSurcharge, OSTooltip, CutName } from '../types/Utils';
import { ConfigMenuNumberInput } from './SankeyMenuConfiguration';
/*************************************************************************************************/
const logo_hv = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 26 26" },
    React.createElement("g", null,
        React.createElement("path", { d: "m 17.84271,23.063448 c -1.269418,-1.282992 -2.346574,-2.454122 -2.393679,-2.602512 -0.0594,-0.187118 -0.01495,-0.364549 0.145033,-0.578998 0.227793,-0.305339 0.251457,-0.30961 1.893906,-0.341824 l 1.663232,-0.03262 v -5.044847 c 0,-3.278554 -0.04379,-5.16073 -0.125076,-5.375831 C 18.85592,8.636398 18.348461,8.114093 17.890847,7.918325 17.58911,7.78924 16.487878,7.756442 12.455374,7.756442 H 7.3983032 l -0.03263,1.662971 c -0.0324,1.65136 -0.03483,1.664613 -0.348521,1.898566 -0.272363,0.203132 -0.362903,0.216128 -0.65705,0.09431 -0.187636,-0.07771 -1.355183,-1.160016 -2.594548,-2.405127 -1.678774,-1.686558 -2.25339,-2.336228 -2.25339,-2.547722 0,-0.212412 0.600322,-0.884111 2.384458,-2.667967 2.546767,-2.546366 2.737072,-2.671811 3.260033,-2.148932 0.212788,0.212755 0.235188,0.391917 0.235188,1.881201 v 1.646051 h 5.0906128 c 5.443018,0 5.946321,0.04455 6.897173,0.610539 0.590386,0.351422 1.254964,1.004393 1.65343,1.62455 0.666046,1.036613 0.705198,1.426315 0.705198,7.019257 v 5.089812 h 1.646309 c 1.489519,0 1.668709,0.0224 1.881497,0.235151 0.519204,0.519121 0.394333,0.708915 -2.142301,3.256165 -1.834562,1.84224 -2.445485,2.387833 -2.675253,2.389168 -0.232072,0.0013 -0.80697,-0.51292 -2.605802,-2.330984 z" })));
const logo_vh = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 26 26", style: { transform: 'rotate(180deg)' } },
    React.createElement("g", null,
        React.createElement("path", { d: "m 3.1500765,17.283934 c 1.282992,-1.269418 2.454122,-2.346574 2.602512,-2.393679 0.187118,-0.0594 0.364549,-0.01495 0.578998,0.145033 0.305339,0.227793 0.30961,0.251457 0.341824,1.893906 l 0.03262,1.663232 h 5.0448465 c 3.278554,0 5.16073,-0.04379 5.375831,-0.125076 0.450418,-0.170206 0.972723,-0.677665 1.168491,-1.135279 0.129085,-0.301737 0.161883,-1.402969 0.161883,-5.435473 V 6.8395274 l -1.662971,-0.03263 c -1.65136,-0.0324 -1.664613,-0.03483 -1.898566,-0.348521 -0.203132,-0.272363 -0.216128,-0.362903 -0.09431,-0.65705 0.07771,-0.187636 1.160016,-1.355183 2.405127,-2.594548 1.686558,-1.678774 2.336228,-2.25339004 2.547722,-2.25339004 0.212412,0 0.884111,0.60032204 2.667967,2.38445804 2.546366,2.546767 2.671811,2.737072 2.148932,3.260033 -0.212755,0.212788 -0.391917,0.235188 -1.881201,0.235188 H 21.043731 V 11.92368 c 0,5.443018 -0.04455,5.946321 -0.610539,6.897173 -0.351422,0.590386 -1.004393,1.254964 -1.62455,1.65343 -1.036613,0.666046 -1.426315,0.705198 -7.019257,0.705198 H 6.6995735 v 1.646309 c 0,1.489519 -0.0224,1.668709 -0.235151,1.881497 -0.519121,0.519204 -0.708915,0.394333 -3.256165,-2.142301 -1.84224,-1.834562 -2.387833,-2.445485 -2.389168,-2.675253 -0.0013,-0.232072 0.51292,-0.80697 2.330984,-2.605802 z" })));
const logo_vv = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 27 70" },
    React.createElement("g", null,
        React.createElement("path", { d: "m 1.0769167,58.015255 c 0.217654,0.354078 2.981133,3.275215 6.141066,6.491418 5.2863293,5.380463 5.8029433,5.84764 6.4664543,5.84764 0.665502,0 1.194478,-0.484452 6.858149,-6.280882 6.083147,-6.225736 6.13703,-6.28875 6.13703,-7.176876 0,-0.637443 -0.145906,-1.069736 -0.505635,-1.498089 L 25.668348,54.796371 21.3514,54.730451 17.034455,54.664531 V 37.542387 c 0,-9.417179 -0.06092,-18.000541 -0.135383,-19.074135 l -0.135382,-1.951988 4.45232,-0.06594 4.452319,-0.06597 0.505644,-0.602096 c 0.358397,-0.426766 0.505643,-0.861029 0.505643,-1.491273 0,-0.878947 -0.07053,-0.961469 -6.133897,-7.1768736 -5.688745,-5.831388 -6.186808,-6.28770195 -6.86297,-6.28770195 -0.675922,0 -1.176483,0.45789495 -6.8661033,6.28087995 -6.08314705,6.2257346 -6.13703005,6.2887486 -6.13703005,7.1768766 0,0.637443 0.145908,1.069735 0.50563505,1.498089 l 0.505633,0.602095 4.316948,0.06592 4.3169453,0.06592 v 17.122137 c 0,9.417183 0.06092,18.000543 0.135383,19.074137 l 0.135382,1.951988 -4.4523203,0.06596 -4.452317,0.06596 -0.505646,0.602096 c -0.61444705,0.731653 -0.65563605,1.726456 -0.108342,2.616786 z" })));
const logo_hh = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 70 27" },
    React.createElement("g", null,
        React.createElement("path", { d: "m 57.188847,25.602699 c 0.354078,-0.217654 3.275215,-2.981133 6.491418,-6.141066 5.380463,-5.286329 5.84764,-5.802943 5.84764,-6.466454 0,-0.665502 -0.484452,-1.194478 -6.280882,-6.858149 C 57.021287,0.053883 56.958273,0 56.070147,0 55.432704,0 55.000411,0.145906 54.572058,0.505635 l -0.602095,0.505633 -0.06592,4.316948 -0.06592,4.316945 H 36.715979 c -9.41718,0 -18.000542,0.06092 -19.074136,0.135383 L 15.689855,9.915926 15.623915,5.463606 15.557945,1.011287 14.955849,0.505643 C 14.529083,0.147246 14.09482,0 13.464576,0 12.585629,0 12.503107,0.07053 6.287703,6.133897 0.45631402,11.822642 2.289157e-8,12.320705 2.289157e-8,12.996867 2.289157e-8,13.672789 0.45789502,14.17335 6.280881,19.86297 12.506615,25.946117 12.569629,26 13.457757,26 c 0.637443,0 1.069735,-0.145908 1.498089,-0.505635 l 0.602095,-0.505633 0.06592,-4.316948 0.06592,-4.316945 h 17.122138 c 9.417183,0 18.000543,-0.06092 19.074137,-0.135383 l 1.951988,-0.135382 0.06596,4.45232 0.06596,4.452317 0.602096,0.505646 c 0.731653,0.614447 1.726456,0.655636 2.616786,0.108342 z" })));
const svg_label_top = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,0H4.5c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5h7.247c-.143,.042-.278,.12-.391,.234l-5.087,5.191c-.574,.581-.167,1.575,.644,1.575h3.587v12.5c0,.829,.671,1.5,1.5,1.5s1.5-.671,1.5-1.5V10h3.587c.811,0,1.218-.994,.644-1.575L12.644,3.234c-.113-.114-.248-.192-.391-.234h7.247c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
const svg_label_bottom = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,21h-7.247c.143-.042,.278-.12,.391-.234l5.087-5.191c.574-.581,.167-1.575-.644-1.575h-3.587V1.5c0-.829-.672-1.5-1.5-1.5s-1.5,.671-1.5,1.5V14h-3.587c-.811,0-1.218,.994-.644,1.575l5.087,5.191c.113,.114,.248,.192,.391,.234H4.5c-.828,0-1.5,.671-1.5,1.5s.672,1.5,1.5,1.5h15c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
const svg_label_center = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M24,12c0,.553-.448,1-1,1H1c-.552,0-1-.447-1-1s.448-1,1-1H23c.552,0,1,.447,1,1Zm-13.414-3.586c.39,.39,.902,.585,1.414,.585s1.024-.195,1.414-.585l3.293-3.293c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.293,2.293V1c0-.553-.448-1-1-1s-1,.447-1,1V6l-2.293-2.293c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l3.293,3.293Zm2.828,7.172c-.779-.779-2.049-.779-2.828,0l-3.293,3.293c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l2.293-2.293v5c0,.553,.448,1,1,1s1-.447,1-1v-5l2.293,2.293c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-3.293-3.293Z" }));
/*************************************************************************************************/
export const MenuConfigurationLinksAppearence = ({ applicationData, additional_link_appearence_items, menu_for_style }) => {
    // Datas ------------------------------------------------------------------------------
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27;
    // Get traduction function
    const { t } = applicationData.new_data;
    // Get data
    const { new_data } = applicationData;
    const { ref_selected_style_link } = new_data.menu_configuration;
    // Elements on which this menu applies ------------------------------------------------
    // Selected links
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    // Elements on which menu modification applies
    let elements;
    if (menu_for_style) {
        elements = [new_data.drawing_area.sankey.link_styles_dict[ref_selected_style_link.current]];
    }
    else {
        elements = selected_links;
    }
    // Elements attributes ----------------------------------------------------------------
    /**
     * function that go throught all links of an array & check if they're all equals
     * (to the first )
     * @param {Class_LinkElement} curr
     * @return {*}
     */
    const check_indeterminate = (curr) => {
        return (selected_links[0].isEqual(curr));
    };
    const is_indeterminate = !selected_links.every(check_indeterminate);
    const shape_orientation = ((_b = (_a = elements[0]) === null || _a === void 0 ? void 0 : _a.shape_orientation) !== null && _b !== void 0 ? _b : default_shape_orientation);
    const shape_starting_curve = ((_d = (_c = elements[0]) === null || _c === void 0 ? void 0 : _c.shape_starting_curve) !== null && _d !== void 0 ? _d : default_shape_starting_curve);
    const shape_ending_curve = ((_f = (_e = elements[0]) === null || _e === void 0 ? void 0 : _e.shape_ending_curve) !== null && _f !== void 0 ? _f : default_shape_ending_curve);
    const shape_starting_tangeant = ((_h = (_g = elements[0]) === null || _g === void 0 ? void 0 : _g.shape_starting_tangeant) !== null && _h !== void 0 ? _h : default_shape_starting_tangeant);
    const shape_ending_tangeant = ((_k = (_j = elements[0]) === null || _j === void 0 ? void 0 : _j.shape_ending_tangeant) !== null && _k !== void 0 ? _k : default_shape_ending_tangeant);
    const shape_is_curved = ((_m = (_l = elements[0]) === null || _l === void 0 ? void 0 : _l.shape_is_curved) !== null && _m !== void 0 ? _m : default_shape_is_curved);
    const shape_is_recycling = ((_p = (_o = elements[0]) === null || _o === void 0 ? void 0 : _o.shape_is_recycling) !== null && _p !== void 0 ? _p : default_shape_is_recycling);
    const shape_arrow_size = ((_r = (_q = elements[0]) === null || _q === void 0 ? void 0 : _q.shape_arrow_size) !== null && _r !== void 0 ? _r : default_shape_arrow_size);
    const value_label_position = ((_t = (_s = elements[0]) === null || _s === void 0 ? void 0 : _s.value_label_position) !== null && _t !== void 0 ? _t : default_value_label_position);
    const value_label_orthogonal_position = ((_v = (_u = elements[0]) === null || _u === void 0 ? void 0 : _u.value_label_orthogonal_position) !== null && _v !== void 0 ? _v : default_value_label_orthogonal_position);
    const value_label_on_path = ((_x = (_w = elements[0]) === null || _w === void 0 ? void 0 : _w.value_label_on_path) !== null && _x !== void 0 ? _x : default_value_label_on_path);
    const value_label_pos_auto = ((_z = (_y = elements[0]) === null || _y === void 0 ? void 0 : _y.value_label_pos_auto) !== null && _z !== void 0 ? _z : default_value_label_pos_auto);
    const shape_is_arrow = ((_1 = (_0 = elements[0]) === null || _0 === void 0 ? void 0 : _0.shape_is_arrow) !== null && _1 !== void 0 ? _1 : default_shape_is_arrow);
    const shape_color = ((_3 = (_2 = elements[0]) === null || _2 === void 0 ? void 0 : _2.shape_color) !== null && _3 !== void 0 ? _3 : default_shape_color);
    const shape_opacity = ((_5 = (_4 = elements[0]) === null || _4 === void 0 ? void 0 : _4.shape_opacity) !== null && _5 !== void 0 ? _5 : default_shape_opacity);
    const shape_is_dashed = ((_7 = (_6 = elements[0]) === null || _6 === void 0 ? void 0 : _6.shape_is_dashed) !== null && _7 !== void 0 ? _7 : default_shape_is_dashed);
    const value_label_is_visible = ((_9 = (_8 = elements[0]) === null || _8 === void 0 ? void 0 : _8.value_label_is_visible) !== null && _9 !== void 0 ? _9 : default_value_label_is_visible);
    const value_label_font_size = ((_11 = (_10 = elements[0]) === null || _10 === void 0 ? void 0 : _10.value_label_font_size) !== null && _11 !== void 0 ? _11 : default_value_label_font_size);
    const value_label_color = ((_13 = (_12 = elements[0]) === null || _12 === void 0 ? void 0 : _12.value_label_color) !== null && _13 !== void 0 ? _13 : default_value_label_color);
    const value_label_to_precision = ((_15 = (_14 = elements[0]) === null || _14 === void 0 ? void 0 : _14.value_label_to_precision) !== null && _15 !== void 0 ? _15 : default_value_label_to_precision);
    const value_label_scientific_precision = ((_17 = (_16 = elements[0]) === null || _16 === void 0 ? void 0 : _16.value_label_scientific_precision) !== null && _17 !== void 0 ? _17 : default_value_label_scientific_precision);
    const value_label_font_family = ((_19 = (_18 = elements[0]) === null || _18 === void 0 ? void 0 : _18.value_label_font_family) !== null && _19 !== void 0 ? _19 : default_value_label_font_family);
    const value_label_unit_visible = ((_21 = (_20 = elements[0]) === null || _20 === void 0 ? void 0 : _20.value_label_unit_visible) !== null && _21 !== void 0 ? _21 : default_value_label_unit_visible);
    const value_label_unit = ((_23 = (_22 = elements[0]) === null || _22 === void 0 ? void 0 : _22.value_label_unit) !== null && _23 !== void 0 ? _23 : default_value_label_unit);
    const value_label_custom_digit = ((_25 = (_24 = elements[0]) === null || _24 === void 0 ? void 0 : _24.value_label_custom_digit) !== null && _25 !== void 0 ? _25 : default_value_label_custom_digit);
    const value_label_nb_digit = ((_27 = (_26 = elements[0]) === null || _26 === void 0 ? void 0 : _26.value_label_nb_digit) !== null && _27 !== void 0 ? _27 : default_value_label_nb_digit);
    //Change le style des flux sélectionnés
    const style_of_selected_links = () => {
        if (selected_links.length !== 0) {
            const style = selected_links[0].style;
            let inchangee = true;
            selected_links.map(link => {
                inchangee = (link.style.id === style.id) ? inchangee : false;
            });
            return (inchangee) ?
                CutName(style.id, 25) :
                t('Noeud.multi_style');
        }
        else {
            return default_style_id;
        }
    };
    // Components updaters ----------------------------------------------------------------
    // State variable to trigger this menu refreshing
    const [, setCount] = useState(0);
    const [, setCountStyle] = useState(0);
    // Link this menu's update function
    if (!menu_for_style) {
        new_data.menu_configuration.ref_to_menu_config_link_apparence_updater.current = () => setCount(a => a + 1);
    }
    else {
        new_data.menu_configuration.ref_to_menu_config_link_apparence_style_updater.current = () => setCountStyle(a => a + 1);
    }
    // Link to ConfigMenuNumberInput state variable
    const number_of_input = 9;
    const ref_set_number_inputs = [];
    for (let i = 0; i < number_of_input; i++)
        ref_set_number_inputs.push(useRef((_) => null));
    // Be sure that values are updated in inputs when refreshing this component
    ref_set_number_inputs[0].current(shape_arrow_size);
    ref_set_number_inputs[1].current(shape_starting_curve * 100);
    ref_set_number_inputs[2].current(shape_ending_curve * 100);
    ref_set_number_inputs[3].current(shape_starting_tangeant * 100);
    ref_set_number_inputs[4].current(shape_ending_tangeant * 100);
    ref_set_number_inputs[5].current(shape_opacity);
    ref_set_number_inputs[6].current(value_label_scientific_precision);
    ref_set_number_inputs[7].current(value_label_nb_digit);
    ref_set_number_inputs[8].current(value_label_font_size);
    /**
     * Function used to reset menu UI
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Whatever is done, set saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        if (menu_for_style) {
            new_data.menu_configuration.updateAllComponentsRelatedToLinks();
            // Update menus for link's apparence in case we use this for style
            new_data.menu_configuration.ref_to_menu_config_link_apparence_style_updater.current();
            // Redraw all visible nodes if we modifie link style
            new_data.drawing_area.sankey.visible_links_list.forEach(link => link.draw());
        }
        // And update this menu also
        new_data.menu_configuration.ref_to_menu_config_link_apparence_updater.current();
    };
    // JSX menu components ---------------------------------------------------------------
    const content_appearence = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.edition')),
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: shape_is_recycling, onChange: (evt) => {
                elements.forEach(element => { element.shape_is_recycling = evt.target.checked; });
                refreshThisAndUpdateRelatedComponents();
            } },
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.recy') }, t('Flux.apparence.recy'))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.of'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_orientation') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(Box, { layerStyle: 'options_4cols' },
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.of_hh') },
                    React.createElement(Button, { className: 'btn_menu_config', value: 'hh', variant: (shape_orientation === 'hh') ?
                            'menuconfigpanel_option_button_activated_left' :
                            'menuconfigpanel_option_button_left', onClick: () => {
                            elements.forEach(element => element.shape_orientation = 'hh');
                            refreshThisAndUpdateRelatedComponents();
                        } }, logo_hh)),
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.of_vv') },
                    React.createElement(Button, { className: 'btn_menu_config', value: 'vv', variant: (shape_orientation === 'vv') ? 'menuconfigpanel_option_button_activated_center' :
                            'menuconfigpanel_option_button_center', onClick: () => {
                            elements.forEach(element => element.shape_orientation = 'vv');
                            refreshThisAndUpdateRelatedComponents();
                        } }, logo_vv)),
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.of_vh') },
                    React.createElement(Button, { className: 'btn_menu_config', value: 'vh', variant: (shape_orientation === 'vh') ?
                            'menuconfigpanel_option_button_activated_center' :
                            'menuconfigpanel_option_button_center', onClick: () => {
                            elements.forEach(element => element.shape_orientation = 'vh');
                            refreshThisAndUpdateRelatedComponents();
                        } }, logo_vh)),
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.of_hv') },
                    React.createElement(Button, { className: 'btn_menu_config', value: 'hv', variant: (shape_orientation === 'hv') ?
                            'menuconfigpanel_option_button_activated_right' :
                            'menuconfigpanel_option_button_right', onClick: () => {
                            elements.forEach(element => element.shape_orientation = 'hv');
                            refreshThisAndUpdateRelatedComponents();
                        } }, logo_hv)))),
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: shape_is_arrow, onChange: (evt) => {
                elements.forEach(element => element.shape_is_arrow = evt.target.checked);
                refreshThisAndUpdateRelatedComponents();
            } },
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.fleche') }, t('Flux.apparence.fleche'))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.arrow_size'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_arrow_size') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.arrow_size') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[0], default_value: shape_arrow_size, menu_for_style: menu_for_style, minimum_value: 1, stepper: true, function_on_blur: (value) => {
                            elements.forEach(element => element.shape_arrow_size = value !== null && value !== void 0 ? value : undefined);
                            refreshThisAndUpdateRelatedComponents();
                        } })))),
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: shape_is_curved, onChange: (evt) => {
                elements.forEach(element => element.shape_is_curved = evt.target.checked);
                refreshThisAndUpdateRelatedComponents();
            } },
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.courbe') }, t('Flux.apparence.courbe'))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.starting_curve'),
                ((!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_starting_curve')) ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.starting_curve') },
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[1], default_value: shape_starting_curve * 100, function_on_blur: (value) => {
                        elements.forEach(element => element.shape_starting_curve = (value ? value / 100 : undefined));
                        refreshThisAndUpdateRelatedComponents();
                    }, menu_for_style: menu_for_style, minimum_value: 0, maximum_value: shape_ending_curve * 100, step: 1, stepper: true, unit_text: '%' }))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.ending_curve'),
                ((!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_ending_curve')) ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.ending_curve') },
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[2], default_value: shape_ending_curve * 100, menu_for_style: menu_for_style, minimum_value: shape_starting_curve * 100, maximum_value: 100, step: 1, stepper: true, unit_text: '%', function_on_blur: (value) => {
                        elements.forEach(element => element.shape_ending_curve = (value ? value / 100 : undefined));
                        refreshThisAndUpdateRelatedComponents();
                    } }))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.starting_tangeant'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_starting_tangeant') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.starting_tangeant') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[3], default_value: shape_starting_tangeant * 100, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: '%', function_on_blur: (value) => {
                            elements.forEach(element => element.shape_starting_tangeant = (value ? value / 100 : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        } })))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.ending_tangeant'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_ending_tangeant') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.ending_tangeant') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[4], default_value: shape_ending_tangeant * 100, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: '%', function_on_blur: (value) => {
                            elements.forEach(element => element.shape_ending_tangeant = (value ? value / 100 : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        } })))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.couleur'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_color') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: shape_color, onChange: evt => {
                    elements.forEach(element => element.shape_color = evt.target.value);
                    refreshThisAndUpdateRelatedComponents();
                } })),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Flux.apparence.opacity'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'shape_opacity') ?
                    React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                    React.createElement(React.Fragment, null)),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.shape_opacity') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[5], default_value: shape_opacity, menu_for_style: menu_for_style, minimum_value: 0, maximum_value: 1, step: 0.1, stepper: true, function_on_blur: (value) => {
                            elements.forEach(element => element.shape_opacity = value !== null && value !== void 0 ? value : undefined);
                            refreshThisAndUpdateRelatedComponents();
                        } })))),
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: shape_is_dashed, onChange: (evt) => {
                elements.forEach(element => element.shape_is_dashed = evt.target.checked);
                refreshThisAndUpdateRelatedComponents();
            } },
            React.createElement(OSTooltip, { label: t('Flux.apparence.tooltips.hach') }, t('Flux.apparence.hach') + ' '),
            (!menu_for_style) &&
                isAttributeOverloaded(selected_links, 'shape_is_dashed') ?
                TooltipValueSurcharge('link_var_', t) :
                React.createElement(React.Fragment, null)),
        additional_link_appearence_items);
    const content_label = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: value_label_is_visible ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), isIndeterminate: is_indeterminate, isChecked: value_label_is_visible, onChange: (evt) => {
                    elements.forEach(element => element.value_label_is_visible = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.label') }, t('Flux.label.vdb') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'value_label_is_visible') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null))),
        value_label_is_visible ? React.createElement(React.Fragment, null,
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.label.NbPrecision')),
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.NbPrecision') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[6], default_value: value_label_scientific_precision, menu_for_style: menu_for_style, minimum_value: 0, stepper: true, function_on_blur: (value) => {
                            elements.forEach(element => element.value_label_scientific_precision = value !== null && value !== void 0 ? value : undefined);
                            refreshThisAndUpdateRelatedComponents();
                        } }))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: value_label_to_precision, onChange: (evt) => {
                    elements.forEach(element => {
                        element.value_label_custom_digit = false;
                        element.value_label_to_precision = evt.target.checked;
                    });
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.toPrecision') }, t('Flux.label.toPrecision') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'value_label_to_precision') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null)),
            value_label_to_precision ?
                React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: value_label_custom_digit, onChange: (evt) => {
                    elements.forEach(element => {
                        element.value_label_custom_digit = evt.target.checked;
                        element.value_label_to_precision = false;
                    });
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.custom_digit') }, t('Flux.label.custom_digit') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'value_label_custom_digit') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null)),
            value_label_custom_digit ? React.createElement(React.Fragment, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.label.NbDigit')),
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.NbDigit') },
                        React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[7], default_value: value_label_nb_digit, menu_for_style: menu_for_style, minimum_value: 0, stepper: true, function_on_blur: (value) => {
                                elements.forEach(element => element.value_label_nb_digit = value !== null && value !== void 0 ? value : undefined);
                                refreshThisAndUpdateRelatedComponents();
                            } })))) : React.createElement(React.Fragment, null),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', icon: value_label_unit_visible ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), isChecked: value_label_unit_visible, onChange: (evt) => {
                    elements.forEach(element => element.value_label_unit_visible = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.l_u_v') }, t('Flux.label.l_u_v') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'value_label_unit_visible') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null)),
            value_label_unit_visible ?
                React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                            t('Flux.label.l_u'),
                            (!menu_for_style) &&
                                isAttributeOverloaded(selected_links, 'value_label_unit') ?
                                React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                                React.createElement(React.Fragment, null)),
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.l_u') },
                            React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: value_label_unit, onChange: evt => {
                                    elements.forEach(element => element.value_label_unit = evt.target.value);
                                    refreshThisAndUpdateRelatedComponents();
                                } })))) :
                React.createElement(React.Fragment, null),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.edition')),
                React.createElement(Box, { layerStyle: 'options_3cols' },
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.len') },
                        React.createElement(Button, { variant: (!is_indeterminate && (value_label_color === 'black')) ?
                                'menuconfigpanel_option_button_activated_left' :
                                'menuconfigpanel_option_button_left', onClick: () => {
                                elements.forEach(element => element.value_label_color = 'black');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            t('Flux.label.len'),
                            (!menu_for_style) &&
                                isAttributeOverloaded(selected_links, 'value_label_color') ?
                                React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                                React.createElement(React.Fragment, null))),
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.lb') },
                        React.createElement(Button, { variant: (!is_indeterminate && (value_label_color === 'white')) ?
                                'menuconfigpanel_option_button_activated_center' :
                                'menuconfigpanel_option_button_center', onClick: () => {
                                elements.forEach(element => element.value_label_color = 'white');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            t('Flux.label.lb'),
                            (!menu_for_style) &&
                                isAttributeOverloaded(selected_links, 'value_label_color') ?
                                React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                                React.createElement(React.Fragment, null))),
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.lec') },
                        React.createElement(Button, { variant: (!is_indeterminate && (value_label_color === 'color')) ?
                                'menuconfigpanel_option_button_activated_right' :
                                'menuconfigpanel_option_button_right', onClick: () => {
                                elements.forEach(element => element.value_label_color = 'color');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            t('Flux.label.lec'),
                            (!menu_for_style) &&
                                isAttributeOverloaded(selected_links, 'value_label_color') ?
                                React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                                React.createElement(React.Fragment, null)))),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, "Police"),
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: value_label_font_family, onChange: (evt) => {
                            elements.forEach(element => element.value_label_font_family = evt.target.value);
                            refreshThisAndUpdateRelatedComponents();
                        } }, font_families
                        .map((d) => {
                        return React.createElement("option", { style: { fontFamily: d }, key: 'ff-' + d, value: d }, d);
                    })),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[8], default_value: value_label_font_size, menu_for_style: menu_for_style, minimum_value: 11, stepper: true, unit_text: 'pixels', function_on_blur: (value) => {
                            elements.forEach(element => element.value_label_font_size = value !== null && value !== void 0 ? value : undefined);
                            refreshThisAndUpdateRelatedComponents();
                        } })),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, "Position"),
                React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: value_label_pos_auto, onChange: (evt) => {
                        elements.forEach(element => {
                            const orth_pos = element.value_label_orthogonal_position;
                            element.value_label_pos_auto = evt.target.checked;
                            element.value_label_position = 'start';
                            element.value_label_orthogonal_position = (orth_pos === 'dragged') ? 'middle' : orth_pos;
                        });
                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                        refreshThisAndUpdateRelatedComponents();
                    } },
                    React.createElement(OSTooltip, { label: t('Flux.tooltips.ajust_label') }, t('Flux.ajust_label'))),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Flux.label.pos'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_links, 'value_label_position') ?
                            React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(Box, { layerStyle: 'options_2cols' },
                        React.createElement(Box, { layerStyle: 'options_3cols' },
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.deb') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (value_label_position === 'start')) ?
                                        'menuconfigpanel_option_button_activated_left' :
                                        'menuconfigpanel_option_button_left', onClick: () => {
                                        elements.forEach(element => {
                                            const orth_pos = element.value_label_orthogonal_position;
                                            element.value_label_position = 'start';
                                            element.value_label_orthogonal_position = (orth_pos == 'dragged') ? 'middle' : orth_pos;
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } },
                                    React.createElement(FaAlignLeft, null))),
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.milieu_h') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (value_label_position === 'middle')) ?
                                        'menuconfigpanel_option_button_activated_center' :
                                        'menuconfigpanel_option_button_center', onClick: () => {
                                        elements.forEach(element => {
                                            const orth_pos = element.value_label_orthogonal_position;
                                            element.value_label_position = 'middle';
                                            element.value_label_orthogonal_position = (orth_pos == 'dragged') ? 'middle' : orth_pos;
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } },
                                    React.createElement(FaAlignCenter, null))),
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.fin') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (value_label_position === 'end')) ?
                                        'menuconfigpanel_option_button_activated_right' :
                                        'menuconfigpanel_option_button_right', onClick: () => {
                                        elements.forEach(element => {
                                            const orth_pos = element.value_label_orthogonal_position;
                                            element.value_label_position = 'end';
                                            element.value_label_orthogonal_position = (orth_pos == 'dragged') ? 'middle' : orth_pos;
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } },
                                    React.createElement(FaAlignRight, null)))),
                        React.createElement(Box, { layerStyle: 'options_3cols' },
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.dessous') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!value_label_pos_auto &&
                                        !is_indeterminate &&
                                        (value_label_orthogonal_position === 'below')) ?
                                        'menuconfigpanel_option_button_activated_left' :
                                        'menuconfigpanel_option_button_left', onClick: () => {
                                        elements.forEach(element => {
                                            element.value_label_pos_auto = false;
                                            const lab_pos = element.value_label_position;
                                            element.value_label_position = (lab_pos == 'dragged') ? 'middle' : lab_pos;
                                            element.value_label_orthogonal_position = 'below';
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } }, svg_label_bottom)),
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.milieu_v') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!value_label_pos_auto &&
                                        !is_indeterminate &&
                                        (value_label_orthogonal_position === 'middle')) ?
                                        'menuconfigpanel_option_button_activated_center' :
                                        'menuconfigpanel_option_button_center', onClick: () => {
                                        elements.forEach(element => {
                                            element.value_label_pos_auto = false;
                                            const lab_pos = element.value_label_position;
                                            element.value_label_position = (lab_pos == 'dragged') ? 'middle' : lab_pos;
                                            element.value_label_orthogonal_position = 'middle';
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } }, svg_label_center)),
                            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.dessus') },
                                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!value_label_pos_auto &&
                                        !is_indeterminate &&
                                        (value_label_orthogonal_position === 'above')) ?
                                        'menuconfigpanel_option_button_activated_right' :
                                        'menuconfigpanel_option_button_right', onClick: () => {
                                        elements.forEach(element => {
                                            element.value_label_pos_auto = false;
                                            const lab_pos = element.value_label_position;
                                            element.value_label_position = (lab_pos == 'dragged') ? 'middle' : lab_pos;
                                            element.value_label_orthogonal_position = 'above';
                                        });
                                        selected_links.forEach(link => link.deleteRelativeLabelPos());
                                        refreshThisAndUpdateRelatedComponents();
                                    } }, svg_label_top)))))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: value_label_on_path, onChange: (evt) => {
                    elements.forEach(element => {
                        element.value_label_on_path = evt.target.checked;
                        if (element.value_label_on_path) {
                            const lab_pos = element.value_label_position;
                            const lab_orth_pos = element.value_label_orthogonal_position;
                            element.value_label_position = (lab_pos == 'dragged') ? 'middle' : lab_pos;
                            element.value_label_orthogonal_position = (lab_orth_pos == 'dragged' ? 'middle' : lab_orth_pos);
                        }
                    });
                    selected_links.forEach(link => link.deleteRelativeLabelPos());
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.acf') }, t('Flux.label.acf') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_links, 'value_label_on_path') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null))) : React.createElement(React.Fragment, null));
    const content_style = (!menu_for_style) ? React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_stylechoice' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('Noeud.Style')),
            React.createElement(Menu, null,
                React.createElement(MenuButton, { as: Button, variant: 'menuconfigpanel_option_button', rightIcon: React.createElement(FaChevronDown, null) }, style_of_selected_links()),
                React.createElement(MenuList, null, new_data.drawing_area.sankey.link_styles_list_sorted
                    .map(style => {
                    return (React.createElement(MenuItem, { key: style.id, onClick: () => {
                            ref_selected_style_link.current = style.id;
                            selected_links.map(link => {
                                link.style = style;
                            });
                            refreshThisAndUpdateRelatedComponents();
                        } }, style.id));
                }))),
            React.createElement(OSTooltip, { label: t('Noeud.tooltips.AS') },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        selected_links.forEach(link => link.resetAttributes());
                        refreshThisAndUpdateRelatedComponents();
                    } },
                    React.createElement(FaUndo, null)))),
        React.createElement("hr", { style: {
                borderStyle: 'none',
                margin: '10px',
                color: 'grey',
                backgroundColor: 'grey',
                height: 2
            } })) : React.createElement(React.Fragment, null);
    const content_zIndex_and_direction = (!menu_for_style) ? React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Flux.FS')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.dzf')),
            React.createElement(Box, { layerStyle: 'options_4cols' },
                React.createElement(OSTooltip, { label: t('Flux.tooltips.up') },
                    React.createElement(Button, { className: 'btn_menu_config', variant: 'menuconfigpanel_option_button_left', isDisabled: selected_links.length === 0, onClick: () => {
                            selected_links.forEach(link => link.increaseDisplayOrder());
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement(FaAngleUp, null))),
                React.createElement(OSTooltip, { label: t('Flux.tooltips.upup') },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_center', isDisabled: selected_links.length === 0, className: 'btn_menu_config', onClick: () => {
                            selected_links.forEach(link => link.setTopDisplayOrder());
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement(FaAngleDoubleUp, null))),
                React.createElement(OSTooltip, { label: t('Flux.tooltips.dwn') },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_center', isDisabled: selected_links.length === 0, className: 'btn_menu_config', onClick: () => {
                            selected_links.forEach(link => link.decreaseDisplayOrder());
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement(FaAngleDown, null))),
                React.createElement(OSTooltip, { label: t('Flux.tooltips.dwndwn') },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', isDisabled: selected_links.length === 0, className: 'btn_menu_config', onClick: () => {
                            selected_links.forEach(link => link.setDownDisplayOrder());
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement(FaAngleDoubleDown, null))))),
        React.createElement("hr", { style: {
                borderStyle: 'none',
                margin: '10px',
                color: 'grey',
                backgroundColor: 'grey',
                height: 2
            } })) : React.createElement(React.Fragment, null);
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        content_style,
        content_zIndex_and_direction,
        content_appearence,
        React.createElement("hr", { style: {
                borderStyle: 'none',
                margin: '10px',
                color: 'grey',
                backgroundColor: 'grey',
                height: 2
            } }),
        content_label);
    /* Formattage de l'affichage du menu attribut de flux */
    return content;
};
