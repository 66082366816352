// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
import { Class_Element } from './Element';
import { default_element_position, } from './Utils';
// CLASS HANDLER ************************************************************************
/**
 * Class that define a handler used to manipulate a element
 * @export
 * @class Class_Handler
 * @extends {Class_Element}
 */
export class Class_Handler extends Class_Element {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Handler.
     * @param {string} id
     * @param {Class_DrawingArea} drawing_area
     * @param {Class_MenuConfig} menu_config
     * @param {(Class_LinkElement | Class_NodeElement)} ref
     * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragStart_function
     * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} drag_function
     * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragEnd_function
     * @param {{class?:string, size?: number, color?: string, filled?: boolean }} [options]
     * @param {(Class_LinkElement | Class_NodeElement)} [ref_optional]
     * @memberof Class_Handler
     */
    constructor(id, drawing_area, menu_config, ref, dragStart_function, drag_function, dragEnd_function, options, ref_optional) {
        // Init parent class attributes
        super(id, menu_config, 'g_handlers');
        // PRIVATE ATTRIBUTES =================================================================
        this._size = 5;
        this._color = 'black';
        this._filled = true;
        this._ref_element = ref;
        this._ref_element_optional = ref_optional;
        // Init other class attributes
        this._display = {
            drawing_area: drawing_area,
            position: structuredClone(default_element_position),
        };
        // Drag handling functions -> defined by parent element
        this.eventMouseDragStart = dragStart_function;
        this.eventMouseDrag = drag_function;
        this.eventMouseDragEnd = dragEnd_function;
        // Set optional variable value
        if (options) {
            if (options.size !== undefined) {
                this._size = options.size;
            }
            if (options.color !== undefined) {
                this._color = options.color;
            }
            if (options.filled !== undefined) {
                this._filled = options.filled;
            }
            if (options.class !== undefined) {
                this._custom_class = options.class;
            }
        }
    }
    // PUBLIC METHODS =====================================================================
    draw() {
        super.draw();
        this.drawElements();
    }
    drawElements() {
        var _a, _b, _c;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_handler');
        if (this._custom_class !== undefined) {
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.attr('class', this._custom_class);
        }
        // this.d3_selection?.style('display', this.getDisplayValue())
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.append('rect').attr('x', -this._size / 2).attr('y', -this._size / 2).attr('width', this._size).attr('height', this._size).attr('stroke', this._color).attr('stroke-width', 1).attr('fill', this._color).attr('fill-opacity', this._filled ? 1 : 0);
    }
    copyFrom(element) {
        this._size = element._size;
        this._color = element._color;
        this._filled = element._filled;
        this._custom_class = element._custom_class;
        this.setPosXY(element.position_x, element.position_y);
    }
    // GETTERS / SETTERS ==================================================================
    /**
       * Getter used to display or not the handler (called in draw of Class_Element)
       *
       * @readonly
       * @memberof Class_Handler
       */
    get is_visible() {
        var _a, _b;
        return (this._ref_element.is_visible && this._ref_element.is_selected && this._is_visible && ((_b = (_a = this._ref_element_optional) === null || _a === void 0 ? void 0 : _a.is_visible) !== null && _b !== void 0 ? _b : true));
    }
    get ref_element() { return this._ref_element; }
    get ref_element_optional() { return this._ref_element_optional; }
}
