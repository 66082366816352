import { Class_NodeElement } from 'open-sankey/dist/types/Node';
export class Class_NodePlusElement extends Class_NodeElement {
    constructor(id, name, drawing_area, menu_config) {
        super(id, name, drawing_area, menu_config);
        this._iconName = '';
        this._iconColor = '';
        this._iconVisible = false;
        this._iconViewBox = '';
        this._iconColorSustainable = false;
        this._has_FO = false;
        this._is_FO_raw = false;
        this._FO_content = '';
        this._is_image = false;
        this._image_src = '';
        this._hyperlink = '';
    }
    // PUBLIC METHOD =================================
    draw() {
        super.draw();
        this.drawIllustration();
    }
    drawIllustration() {
        var _a;
        (_a = this.d3_selection_plus) === null || _a === void 0 ? void 0 : _a.selectAll('.illustartion').remove();
        if (this._is_image) {
            this.drawIllustrationImage();
        }
        if (this._iconVisible) {
            this.drawIllustrationIcon();
        }
    }
    isEqualPlus(element) {
        this.isEqual(element);
    }
    // PRIVATE ===========================
    drawIllustrationImage() {
        var _a;
        (_a = this.d3_selection_plus) === null || _a === void 0 ? void 0 : _a.append('image').attr('id', n => 'image_node_' + n.id).attr('class', 'illustartion').attr('href', n => n.image_src).attr('height', n => this.getShapeHeightToUse()).attr('width', n => this.getShapeWidthToUse());
    }
    drawIllustrationIcon() {
        var _a;
        (_a = this.d3_selection_plus) === null || _a === void 0 ? void 0 : _a.append('svg').attr('id', n => 'icon_node_' + n.id).attr('class', 'icon_node').attr('viewBox', d => d.iconViewBox ? d.iconViewBox : '0 0 1000 1000').attr('height', n => n.getShapeHeightToUse()).attr('width', n => n.getShapeWidthToUse()).attr('x', 0).append('g').append('path').style('fill', this.iconColor).attr('d', n => this.drawing_area.sankey.getIconFromCatalog(n.iconName));
    }
    // ============GETTER && SETTER ==================
    get iconName() { return this._iconName; }
    set iconName(value) { this._iconName = value; }
    get d3_selection_plus() { return this.d3_selection; }
    get iconColor() { return this._iconColor; }
    set iconColor(value) { this._iconColor = value; }
    get iconVisible() { return this._iconVisible; }
    set iconVisible(value) { this._iconVisible = value; }
    get iconViewBox() { return this._iconViewBox; }
    set iconViewBox(value) { this._iconViewBox = value; }
    get iconColorSustainable() { return this._iconColorSustainable; }
    set iconColorSustainable(value) { this._iconColorSustainable = value; }
    get is_image() { return this._is_image; }
    set is_image(value) { this._is_image = value; }
    get image_src() { return this._image_src; }
    set image_src(value) { this._image_src = value; }
}
