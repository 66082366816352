import { Class_MenuConfig } from "open-sankey/dist/types/MenuConfig";
import { useRef } from "react";
export class Class_MenuConfigAFM extends Class_MenuConfig {
    constructor() {
        super();
        this._action_type = 'optim';
        this._dict_setter_show_dialog_afm = {
            ref_setter_show_split_trade: useRef(() => null),
            ref_setter_show_reconciliation: useRef(() => null)
        };
    }
    get action_type() { return this._action_type; }
    set action_type(_) { this._action_type = _; }
    get dict_setter_show_dialog_afm() { return this._dict_setter_show_dialog_afm; }
}
