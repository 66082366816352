import { Class_MenuConfigAFM } from "afmsankey/dist/src/types/MenuConfigAFM"
import { Class_MenuConfig } from "open-sankey/dist/types/MenuConfig"
import { Class_MenuConfigPlus } from "sankeyanimation/dist/src/Types/MenuConfigPlus"

export class Class_MenuConfigSuite extends Class_MenuConfig{
  private _afm_config: Class_MenuConfigAFM
  private _sankey_plus_config: Class_MenuConfigPlus

  constructor() {
    super()
    this._afm_config = new Class_MenuConfigAFM()
    this._sankey_plus_config = new Class_MenuConfigPlus()
  }

  // AFM config
  public get action_type() { return this._afm_config._action_type}
  public set action_type(_) { this._afm_config._action_type = _}

  public get dict_setter_show_dialog_afm() { return this._afm_config._dict_setter_show_dialog_afm }

  // OpenSankey+ config
  get zdt_accordion_ref() { return this._sankey_plus_config._zdt_accordion_ref; }
  get dict_setter_show_dialog_plus() { return this._sankey_plus_config._dict_setter_show_dialog_plus; }
  // public set dict_setter_show_dialog_plus(value: OSPShowMenuComponentsVarType) {this._dict_setter_show_dialog_plus = value;}
  get ref_to_menu_config_free_label_updater() { return this._sankey_plus_config._ref_to_menu_config_free_label_updater; }
  set ref_to_menu_config_free_label_updater(value) { this._sankey_plus_config._ref_to_menu_config_free_label_updater = value; }
}