// External imports
import React, { useRef, useState } from 'react';
import { Box, Select, } from '@chakra-ui/react';
// Local components or functions
import { OSTooltip } from '../types/Utils';
import { default_value_label_unit } from '../types/Link';
import { ConfigMenuNumberInput, ConfigMenuTextInput } from './SankeyMenuConfiguration';
/*************************************************************************************************/
export const MenuConfigurationLinksData = ({ applicationData, additional_data_element, }) => {
    var _a, _b, _c, _d, _e;
    // Traduction
    const { t } = applicationData.new_data;
    // Sankey datas
    const { new_data } = applicationData;
    // Selected links --------------------------------------------------------------------
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    // Data tags and values --------------------------------------------------------------
    const list_data_taggs = new_data.drawing_area.sankey.data_taggs_list;
    const value = (_a = selected_links[0]) === null || _a === void 0 ? void 0 : _a.value;
    // Components updaters ---------------------------------------------------------------
    // Refs used to trigger refreshing of number & text inputs
    const ref_set_data_value_input = useRef((_) => null);
    const ref_set_text_value_input = useRef((_) => null);
    const updateInputsValues = () => {
        var _a, _b;
        // Update input data value
        ref_set_data_value_input.current((_a = value === null || value === void 0 ? void 0 : value.data_value) !== null && _a !== void 0 ? _a : null);
        // Update input text value
        ref_set_text_value_input.current((_b = value === null || value === void 0 ? void 0 : value.text_value) !== null && _b !== void 0 ? _b : null);
    };
    // Function used to force this component to reload
    const [, setCount] = useState(0);
    // Link this menu's update function
    const refreshThisAndUpdateRelatedComponents = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        setCount(a => a + 1);
    };
    new_data.menu_configuration.ref_to_menu_config_link_data_updater.current = () => {
        updateInputsValues();
        setCount(a => a + 1);
    };
    // JSX -------------------------------------------------------------------------------
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        // Définition des valeurs selon les paramètre dataTags
        list_data_taggs.map(data_tagg => {
            if (data_tagg.has_tags) {
                // Only one dataTag / group can be selected
                let selected_data_tags = data_tagg.selected_tags_list;
                if (selected_data_tags.length === 0) {
                    data_tagg.tags_list[0].setSelected();
                    selected_data_tags = data_tagg.selected_tags_list;
                }
                else if (selected_data_tags.length > 1) {
                    const data_tags_to_unselect = selected_data_tags.splice(1);
                    data_tags_to_unselect.forEach(tag => tag.setUnSelected());
                }
                // Retrun selection box
                return (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, data_tagg.name),
                    React.createElement(Select, { name: data_tagg.id, variant: 'menuconfigpanel_option_select', value: selected_data_tags[0].id, onChange: (evt) => {
                            // Update selected attributes for tags
                            data_tagg.tags_list.forEach(tag => {
                                if (tag.id === evt.target.value)
                                    tag.setSelected();
                                else
                                    tag.setUnSelected();
                            });
                            // Update this menu
                            refreshThisAndUpdateRelatedComponents();
                        } }, data_tagg.tags_list.map(tag => {
                        return React.createElement("option", { key: tag.id, value: tag.id }, tag.name);
                    }))));
            }
        }),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.vpp') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.vpp')),
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_data_value_input, default_value: (_b = value === null || value === void 0 ? void 0 : value.data_value) !== null && _b !== void 0 ? _b : null, function_on_blur: (_) => {
                        // Update data for links
                        selected_links.forEach(link => {
                            link.data_value = (_ !== null && _ !== void 0 ? _ : null);
                        });
                        // Update this menu
                        refreshThisAndUpdateRelatedComponents();
                    }, minimum_value: 0, stepper: true, step: 1, unit_text: (((_c = selected_links[0]) === null || _c === void 0 ? void 0 : _c.value_label_unit_visible) &&
                        ((_d = selected_links[0]) === null || _d === void 0 ? void 0 : _d.value_label_unit) !== default_value_label_unit) ?
                        (_e = selected_links[0]) === null || _e === void 0 ? void 0 : _e.value_label_unit :
                        undefined }))),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.affichage') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.affichage')),
                React.createElement(ConfigMenuTextInput, { ref_to_set_value: ref_set_text_value_input, function_get_value: () => { return value === null || value === void 0 ? void 0 : value.text_value; }, function_on_blur: (_) => {
                        // Update text for links
                        selected_links.forEach(link => {
                            link.text_value = (_ !== null && _ !== void 0 ? _ : '');
                        });
                        // Update this menu
                        refreshThisAndUpdateRelatedComponents();
                    } }))),
        additional_data_element);
    // Update input values
    updateInputsValues();
    // Return JSX component
    return content;
};
/*************************************************************************************************/
/**
 * Component developped for number input of the link data config menu
 * @param {applicationData} TODO
 * @return {JSX.Elmement}
 */
export const MenuContextLinksData = ({ applicationData, }) => {
    // Application data ------------------------------------------------------------------
    var _a, _b, _c, _d, _e;
    // Sankey datas
    const { new_data } = applicationData;
    // Selected links --------------------------------------------------------------------
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    const value = (_a = selected_links[0]) === null || _a === void 0 ? void 0 : _a.value;
    // Components updaters ---------------------------------------------------------------
    // Refs used to trigger refreshing of number & text inputs
    const ref_set_data_value_input = useRef((_) => null);
    const updateInputsValues = () => {
        var _a;
        // Update input data value
        ref_set_data_value_input.current((_a = value === null || value === void 0 ? void 0 : value.data_value) !== null && _a !== void 0 ? _a : null);
    };
    // Function used to force this component to reload
    const [, setCount] = useState(0);
    const refreshThisAndUpdateRelatedComponents = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Update data menu for link
        new_data.menu_configuration.ref_to_menu_config_link_data_updater.current();
        // And update this menu also
        setCount(a => a + 1);
        updateInputsValues();
    };
    const content = React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_data_value_input, default_value: (_b = value === null || value === void 0 ? void 0 : value.data_value) !== null && _b !== void 0 ? _b : null, function_on_blur: (_) => {
            // Update data for links
            selected_links.forEach(link => {
                link.data_value = (_ !== null && _ !== void 0 ? _ : null);
            });
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        }, minimum_value: 0, stepper: true, step: 1, unit_text: (((_c = selected_links[0]) === null || _c === void 0 ? void 0 : _c.value_label_unit_visible) &&
            ((_d = selected_links[0]) === null || _d === void 0 ? void 0 : _d.value_label_unit) !== default_value_label_unit) ?
            (_e = selected_links[0]) === null || _e === void 0 ? void 0 : _e.value_label_unit :
            undefined });
    return content;
};
