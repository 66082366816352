import React from 'react';
import { itemDemo } from './MenuExampleDemo';
import { SankeyDevInputDialogSaveJSON } from './SankeyDevMenuDialog';
import { OSPInitializeApplicationData } from './import/OpenSankey+';
import { initializeProcessFunctions } from './import/OpenSankey';
export const OSDevDefaultData = () => {
    return {};
};
// export const OSDevInitializeApplicationContext : OSDevInitializeApplicationContextType = ()=>{
//   return {
//     has_sankeydev:false
//   } as DevApplicationContextVar
// }
export const OSDevInitializeApplicationData = (data, set_data, get_default_data, initial_data) => {
    const OSP_init_app_data = OSPInitializeApplicationData(data, set_data, get_default_data, initial_data);
    return OSP_init_app_data;
    // const data_plus=data as SankeyDevData
    // const dev_display_nodes=display_nodes as {[_:string]:SankeyDevNode}
    // const dev_display_links=display_links as {[_:string]:SankeyDevLink}
    // const set_data_plus=set_data as (_:SankeyDevData)=>void
    // const dev_get_defaut_data=get_default_data as () => SankeyDevData
    // const dev_var= {
    //   data :data_plus ,
    //   set_data :set_data_plus,
    //   display_nodes : dev_display_nodes,
    //   display_links : dev_display_links,
    //   master_data:OSP_init_app_data.master_data,
    //   set_master_data:OSP_init_app_data.set_master_data,
    //   view:OSP_init_app_data.view,
    //   set_view:OSP_init_app_data.set_view,
    //   get_default_data:dev_get_defaut_data
    // }  as SankeyDevApplicationDataVar
    // return {...OSP_init_app_data,...dev_var}
};
// export const OSDevInitializeElementSelected : OSDevInitializeElementSelectedType = ()=>{
//   return {
//     multi_selected_nodes : useRef([]),
//     ref_getter_beautify: useRef<boolean>(false)
//   } 
// }
// export const OSDevInitializeShowDialog : OSDevInitializeShowDialogType = ()=>{
//   return {
//     ref_setter_show_publish_dialog : useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     ref_setter_show_resolution_save_png : useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     ref_setter_show_modal_edit_link_reference_unitary : useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     showUnitarySankeyCreationDialog : useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     ref_setter_show_unitary_view_modal : useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     ref_setter_is_for_unitary_view:  useRef<Dispatch<SetStateAction<boolean>>>(()=>null)
//   }
// }
// export const OSDevcloseAllMenu = closeAllMenu
// Modify Application Draw
// export const OSDevInitializeApplicationDraw : OSDevInitializeApplicationDrawType= () => {
//   const _ = {
//   }
//   return _ 
// }
// export const OSDevInitializeComponentUpdater : OSDevInitializeComponentUpdaterType  = ()=> {
//   const _ = {
//   }
//   return _ 
// }
// export const OSDevInitializeReinitialization : initializeReinitializationType = () => ()=> {
//}
export const OSDevInitializeProcessFunctions = initializeProcessFunctions;
// Modify context menu
// export const OSDevInitializeContextMenu :  OSDevInitializeContextMenuType = ()=> {
//   return {} 
// }
// Modify Ref used to open accordion item
// export const OSDevInitializeUIElementsRef : OSDevInitializeUiElementsRefType = () => {
//   return {
//   } 
// }
// export const OSDevInitializeLinkFunctions : OSDevInitializeLinkFunctionTypes = (
//   applicationData
// ) => {
//   const devApplicationData = (applicationData.data as SankeyDevData)
//   if (devApplicationData.unitary_node && devApplicationData.unitary_node.length>0) {
//     return {
//       GetLinkValue:devGetLinkValue,
//       LinkText:SankeyDev_link_text
//     }
//   }
//   return {
//     GetLinkValue:devGetLinkValue
//   }
// }
// export const OSDevInitializeNodeFunctions : OSDevnItializeNodeFunctionsType = (applicationData,
// ) => {
//   const dev_app_data=applicationData as SankeyDevApplicationDataType
//   return {
//     reDrawNodeNotToScale:(nodes_to_update:SankeyDevNode[])=>{
//       SankeyDevDrawNodesNotToScale(dev_app_data,nodes_to_update)
//     }
//   } 
// }
export const OSDevInitializeAdditionalMenus = (additionalMenus, applicationData, processFunctions, Reinitialization) => {
    const { new_data } = applicationData;
    // if (window.SankeyToolsStatic) {
    //   return
    // }
    // const dev_app_data=applicationData as SankeyDevApplicationDataType
    // const DevApplicationState=applicationState as DevApplicationStateType
    // // Top Menus
    // additionalMenus.external_file_item.push(
    //   <SankeyDevMenuPublishItem
    //     t={applicationContext.t}
    //     dict_hook_ref_setter_show_dialog_components={dict_hook_ref_setter_show_dialog_components as SankeyDevShowMenuComponentsType} 
    //   />
    // )
    // // Links
    // additionalMenus.additional_data_element.push(
    //   <SankeyDevMenuDataPercent
    //     data={applicationData.data as SankeyDevData}
    //     set_data={applicationData.set_data as (_:SankeyDevData)=>void}
    //     multi_selected_links={applicationState.multi_selected_links}
    //   />
    // )
    // additionalMenus.advanced_appearence_content.push(
    //   <SankeyDevNodesAttributes
    //     t={applicationContext.t}
    //     data={applicationData.data as SankeyDevData}
    //     applicationState={DevApplicationState}
    //     is_activated={true}
    //     node_function={node_function as DevNodeFunctionTypes}
    //     menu_for_style={false}
    //   />
    // )
    // additionalMenus.advanced_appearence_content.push(
    //   <MenuConfigHideLoneNode
    //     t={applicationContext.t}
    //     data={applicationData.data as SankeyDevData}
    //     set_data={applicationData.set_data as (_:SankeyDevData)=>void}
    //     applicationState={DevApplicationState}
    //     menu_for_style={false}
    //   />
    // )
    // Otion in dropdown save sankey 
    // additionalMenus.externale_save_item.push(MenuEnregistrerExcelSimple(
    //   new_data.url_prefix,
    //   applicationData.data as SankeyDevData
    // ))
    // Option in save json modal 
    additionalMenus.additional_file_save_json_option.push(React.createElement(SankeyDevInputDialogSaveJSON, { t: new_data.t }));
    // additionalMenus.additional_file_item.push(<SankeyDevMenuPublishItem
    //   t={applicationContext.t}
    //   dict_hook_ref_setter_show_dialog_components={dict_hook_ref_setter_show_dialog_components as SankeyDevShowMenuComponentsType}
    // />)
    // additionalMenus.additional_context_element_other.push(context_node_view_node_unitary(applicationContext,dev_app_data,contextMenu,DevApplicationState))
    // additionalMenus.sankey_menus['unit'] = <SankeyDevBannerUnit
    //   applicationData={applicationData as SankeyDevApplicationDataType}
    //   t={new_data.t}
    //   connected={true}
    //   dict_hook_ref_setter_show_dialog_components={dict_hook_ref_setter_show_dialog_components as SankeyDevShowMenuComponentsType}
    //   //selector_of_view // TODO
    //   view_selector={<></>}
    // />,
    additionalMenus.sankey_menus['demo'] = itemDemo(applicationData, additionalMenus.example_menu, processFunctions.launch, Reinitialization);
};
export const OSDevModuleDialogs = (applicationData, additional_menus, menu_configuration_nodes_attributes) => {
    if (window.SankeyToolsStatic) {
        return [];
    }
    // const OSDev_dict_app_data=applicationData as SankeyDevApplicationDataType
    // const OSDev_hook_show_dialogs=dict_hook_ref_setter_show_dialog_components as SankeyDevShowMenuComponentsType
    // const OSDev_app_context=applicationContext as SankeyDevApplicationContextType
    const module_dialogs = [];
    // if(OSDev_app_context.has_sankeydev){
    //   // Add modale to choose nodes to create unitary sankey from an excel file
    //   module_dialogs.push(
    //     <UnitarySankeyCreationDialog
    //       applicationContext={applicationContext}
    //       applicationState={applicationState}        
    //       applicationData={applicationData as SankeyDevApplicationDataType}
    //       processFunctions={processFunctions}
    //       applicationDraw={applicationDraw}
    //       convert_data={convert_data}
    //       postProcessLoadExcel={()=>null}
    //       dict_hook_ref_setter_show_dialog_components={OSDev_hook_show_dialogs}
    //     />
    //   )
    //   module_dialogs.push(<ModalUnitarySankeySpecialLinkValue
    //     applicationData={applicationData as SankeyDevApplicationDataType}
    //     t={applicationContext.t}
    //     dict_hook_ref_setter_show_dialog_components={OSDev_hook_show_dialogs}
    //   />)
    //   const publishImpl = (file_path:string) =>{
    //     const path = window.location.href
    //     const url = path + '/sankeydev/sankey/publish'
    //     const new_data = JSON.parse(JSON.stringify((OSDev_dict_app_data.master_data && OSDev_dict_app_data.view!=='none')?OSDev_dict_app_data.master_data:OSDev_dict_app_data.data))
    //     new_data.file_name = file_path
    //     const fetchData = {
    //       method: 'POST',
    //       body: JSON.stringify(new_data, null, 2)
    //     }
    //     fetch(url, fetchData).then( response => {
    //       if (!response.ok) {
    //         alert(response)
    //       }
    //     })
    //         OSDev_hook_show_dialogs.ref_setter_show_publish_dialog.current!(false)
    //   }
    //   // Add modale configure parametrer before publishing a sankey
    //   module_dialogs.push(<PublishModal
    //     t={applicationContext.t}
    //     publishImpl={publishImpl}
    //     dict_hook_ref_setter_show_dialog_components={OSDev_hook_show_dialogs}
    //     data={OSDev_dict_app_data.data}
    //   />)
    // }
    return module_dialogs;
};
// export const OSDevDrawAll : DrawAllType = (
//   contextMenu,
//   applicationData
// )=>{
//   // hiddeLoneNodes => Function that search and hide node that have 1 input link, 1 output link and have hideLoneNode at true
//   // Example ('-->' represent a link):
//   // node0-->node1-->node2
//   // If we click on 'hide lone node' in the configuration menu  while node1 is selected then we get :
//   // node0-->node2
//   hiddeLoneNodes( {
//     data:applicationData.data,
//     display_nodes:applicationData.display_nodes,
//     display_links:applicationData.display_links,
//     set_data:applicationData.set_data
//   } as unknown as SankeyDevApplicationDataType)
//   // If we have the sankeydev token then 'restore' all node who had previously 'hideLoneNode' at true
//   // but at this render it's false
//   // Else if we don't have sasnkeydev token we restore all node event if 'hideLoneNode' is true
//   // searchAndRestoreLoneNodes(data,set_data,has_sankeydev)
// }
// export const OSDevInstallEventsOnSVG : InstallEventsOnSVGType = () => {}
