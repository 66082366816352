//import { Class_ApplicationData } from 'open-sankey/dist/types/ApplicationData'
// import { Class_DrawingAreaPlus } from './DrawingAreaPlus'
// import { Class_MenuConfigPlus } from './MenuConfigPlus'
import { Class_ApplicationDataAFM } from "afmsankey/dist/src/types/ApplicationDataAFM"
import { Class_ApplicationDataPlus } from "sankeyanimation/dist/src/Types/ApplicationDataPlus"
import LZString from 'lz-string'
import {
  app_name_opensankeyplus,
  app_name_sankeysuite
} from '../components/Register/LicenseFunctions'
import { Class_MenuConfigSuite } from "./MenuConfigSuite"

// Logo, names, licences
// export const SuiteInitializeApplicationContext: SuiteInitializeApplicationContextType = (  
//   blocker_token_OSP : boolean,
//   blocker_token_SSM : boolean
// ) => {
//   // Accounts token
//   let token_free_account = false
//   let has_sankey_suite = false
//   let has_open_sankey_plus = false
//   let has_sankeydev = false
//   if (window.SankeyToolsStatic) {
//     has_sankey_suite = true
//     has_open_sankey_plus = true
//     has_sankeydev = true
//   }

//   // const blockers=has_sankeydev?blockers_suite_sankey(
//   //   blocker_token_OSP,set_blocker_token_OSP,blocker_token_SSM,set_blocker_token_SSM):{}
//   // const token_sankey_suite = has_sankey_suite && !blocker_token_SSM

//   // If we are use the app as creation tool then some token depend of the user
//   // -------------Token for module of OpenSankey----------------------
//   // Search for saved token in localStorage added after login
//   const storage_token_plus = LZString.decompress(
//     sessionStorage.getItem(app_name_opensankeyplus) as string) as string
//   if (storage_token_plus !== null && storage_token_plus !== '' && !blocker_token_OSP) {
//     const d_t = JSON.parse(storage_token_plus)
//     has_open_sankey_plus = d_t
//   }

//   const storage_token_suite = LZString.decompress(
//     sessionStorage.getItem(app_name_sankeysuite) as string) as string
//   if (storage_token_suite !== null && storage_token_suite !== '' && !blocker_token_SSM) {
//     const d_t = JSON.parse(storage_token_suite)
//     if (d_t === true) {
//       has_open_sankey_plus = d_t
//     }
//     has_sankey_suite = d_t
//   }

//   const storage_token_dev = LZString.decompress(sessionStorage.getItem('SankeyDev') as string) as string
//   if (storage_token_dev !== null && storage_token_dev !== '') {
//     const d_t = JSON.parse(storage_token_dev)
//     has_sankeydev = d_t
//   }

//   let logo_application = logo

//   const storage_token = LZString.decompress(sessionStorage.getItem('token') as string) as string
//   if (storage_token !== null && storage_token !== '') {
//     const d_t = JSON.parse(storage_token)
//     token_free_account = d_t
//   }
//   if (has_open_sankey_plus) {
//     logo_application = logo_OSP
//   }
//   if (has_sankey_suite) {
//     logo_application = logo_OSS
//   }
//   const _ = {
//     t: useTranslation().t,
//     logo_OS,
//     logo_OSP,
//     logo_OSS,
//     logo_application,
//     logo_width: 100,
//     app_name: 'SankeySuite',
//     url_prefix: '/sankeytools/',
//     logo: logo_application,
//     logo_terriflux,
//     has_sankey_suite,
//     has_open_sankey_plus,
//     has_sankeydev,
//     unsetTokens: () => {
//       _.has_free_account = false
//       _.has_sankey_suite = false
//       _.has_open_sankey_plus = false
//       _.has_sankeydev = false
//     },
//     name_user: '',
//     has_free_account: token_free_account
//   } as suiteApplicationContextType
//   return _
// }


export class Class_ApplicationDataSuite extends Class_ApplicationDataPlus {
  //private _afm_application_data : Class_ApplicationDataAFM 

  private _logo_sankeysuite: string = ''
  private _has_sankey_suite: boolean = false // token for sankeyplus (if user is connected with an account)
  private _has_sankey_dev:boolean = false

  constructor(published_mode: boolean) {
    super(published_mode)

    // OVERRIDE Drawing_Area & MENU CONFIG TO TAKE INTO ACCOUNT ALL NEW VAR. & FUNCTIONS OF OSP
    // TODO : since we change reference of the app_data, verify we cut all link of previous DA & config with app_data
    // this.menu_configuration= new Class_MenuConfigPlus()
    // this.drawing_area=new Class_DrawingAreaPlus(this.drawing_area.getHeight(), this.drawing_area.getWidth(), this)
    
    //this._afm_application_data = new Class_ApplicationDataAFM(published_mode)
    this._menu_configuration = new Class_MenuConfigSuite()

    // let logo_sankey_plus = ''
    this._has_sankey_plus = false
    this._has_free_account = false
    this._has_sankey_dev = false
    try {
        /* eslint-disable */
        // @ts-ignore
        this._logo_opensankey = require('../css/opensankey.png');
        /* eslint-enable */
        const path = window.location.href;
        if (!path.includes('localhost')) {
          this._logo_opensankey = this._logo_opensankey.replace('static/', 'static/sankeysuite/');
        }
    }
    catch (expt) {
        console.log('opensankey.png not found');
    }
    try {
      /* eslint-disable */
      // @ts-ignore
      this.logo_sankeysuite = require('../css/OSS.png')
      /* eslint-enable */
      const path = window.location.href
      if (!path.includes('localhost')) {
        this.logo_sankeysuite = this.logo_sankeysuite.replace('static/', 'static/sankeysuite/')
      }
    } catch (expt) {
      console.log('terriflux.png not found')
    }
    this.logo = this.logo_opensankey

    try {
        /* eslint-disable */
        // @ts-ignore
        this.logo_terriflux = require('../css/terriflux.png');
        /* eslint-enable */
        const path = window.location.href;
        if (!path.includes('localhost')) {
            this.logo_terriflux = this.logo_terriflux.replace('static/', 'static/sankeysuite/');
        }
    }
    catch (expt) {
        console.log('terriflux.png not found');
    }

    const storage_token_plus = LZString.decompress(
      sessionStorage.getItem(app_name_opensankeyplus) as string) as string
    if (storage_token_plus !== null && storage_token_plus !== '' /*&& !blocker_token_OSP*/) {
      const d_t = JSON.parse(storage_token_plus)
      this.has_sankey_plus = d_t
    }
  
    const storage_token_suite = LZString.decompress(
      sessionStorage.getItem(app_name_sankeysuite) as string) as string
    if (storage_token_suite !== null && storage_token_suite !== '' /*&& !blocker_token_SSM*/) {
      const d_t = JSON.parse(storage_token_suite)
      if (d_t === true) {
        this.has_sankey_plus = d_t
      }
      this.has_sankey_suite = d_t
    }
  
    const storage_token_dev = LZString.decompress(sessionStorage.getItem('SankeyDev') as string) as string
    if (storage_token_dev !== null && storage_token_dev !== '') {
      const d_t = JSON.parse(storage_token_dev)
      this.has_sankey_dev = d_t
    }
  
    //let logo_application = logo
  
    const storage_token = LZString.decompress(sessionStorage.getItem('token') as string) as string
    if (storage_token !== null && storage_token !== '') {
      const d_t = JSON.parse(storage_token)
      this.has_free_account = d_t
    }
    if (this.has_free_account && this.has_sankey_plus) {
      this.logo = this.logo_sankey_plus
    }
    if (this.has_free_account && this.has_sankey_suite) {
      this.logo = this.logo_sankeysuite
    }
  }

  public unsetTokens() {
    this._has_free_account = false
    this._has_sankey_suite = false
    this._has_sankey_plus = false
    this._has_sankey_dev = false
  }
  // GETTER & SETTER ==================================
  public get has_sankey_dev(): boolean { return this._has_sankey_dev }
  public set has_sankey_dev(value: boolean) { this._has_sankey_dev = value }

  public get has_sankey_suite(): boolean { return this._has_sankey_suite }
  public set has_sankey_suite(value: boolean) { this._has_sankey_suite = value }

  public get logo_sankeysuite(): string { return this._logo_sankeysuite }
  public set logo_sankeysuite(value: string) { this._logo_sankeysuite = value }
  // // Override getter & setter so we can get new type
  override get menu_configuration():Class_MenuConfigSuite{return this._menu_configuration as Class_MenuConfigSuite}
  override set menu_configuration(_:Class_MenuConfigSuite){this._menu_configuration=_}
 
  // override get drawing_area() { return this._drawing_area as unknown as Class_DrawingAreaPlus }
  // override set drawing_area(_:Class_DrawingAreaPlus) {  this._drawing_area=_ }

}