// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Import
import * as d3 from 'd3';
import LZString from 'lz-string';
// Local types
import { Class_DrawingArea } from './DrawingArea';
import { Class_MenuConfig } from './MenuConfig';
import { ClickSaveDiagram } from '../dialogs/SankeyPersistence';
import { useTranslation } from 'react-i18next';
export const initial_window_width = window.innerWidth - 50; //TODO : replace 50 by width of toolbar
export const initial_window_height = window.innerHeight - 50; //TODO : replace 50 by height of top navbar & footer
/**
 * Class that contains all elements to make the application work
 *
 * @class Class_ApplicationData
 */
export class Class_ApplicationData {
    // CONSTRUCTOR ========================================================================
    /**
      * Creates an instance of Class_ApplicationData.
      * @param {boolean} published_mode
      * @memberof Class_ApplicationData
      */
    constructor(published_mode) {
        // PUBLIC ATTRIBUTES =================================================================
        // App
        this.version = '0.9';
        // PRIVATE ATTRIBUTES =================================================================
        // General attributes for the application
        this._t = useTranslation().t; //traductor
        this._logo_width = 100;
        this._app_name = 'SankeySuite';
        this._url_prefix = '/opensankey/'; // path for server call
        this._has_free_account = true; // token for opensankey (if user is connected with an account)
        // Deals with UI menu updates / each modifications
        this._menu_configuration = new Class_MenuConfig;
        // Contains all drawn objects
        this._drawing_area = new Class_DrawingArea(initial_window_height, initial_window_width, this);
        // Link keyboard listener with app key down detection
        document.onkeydown = this.keyboardEventListener(this);
        // For published mode only
        this.drawing_area.static = published_mode;
        this.fit_screen = published_mode;
        // Get logo PNG
        let logo = '';
        try {
            /* eslint-disable */
            // @ts-ignore
            logo = require('../css/opensankey.png');
            /* eslint-enable */
            const path = window.location.href;
            if (!path.includes('localhost')) {
                logo = logo.replace('static/', 'static/opensankey/');
            }
        }
        catch (expt) {
            console.log('opensankey.png not found');
        }
        let logo_terriflux = '';
        try {
            /* eslint-disable */
            // @ts-ignore
            logo_terriflux = require('../css/terriflux.png');
            /* eslint-enable */
            const path = window.location.href;
            if (!path.includes('localhost')) {
                logo_terriflux = logo_terriflux.replace('static/', 'static/opensankey/');
            }
        }
        catch (expt) {
            console.log('terriflux.png not found');
        }
        this._logo = logo;
        this._logo_opensankey = logo;
        this._logo_terriflux = logo_terriflux;
    }
    // PUBLIC METHODS =====================================================================
    new_drawing_area() {
        return new Class_DrawingArea(initial_window_height, initial_window_width, this);
    }
    reset() {
        // Reset values of attributes
        // Recreate drawing area
        this.drawing_area.delete();
        this.drawing_area = this.new_drawing_area();
        this.drawing_area.reset();
        // Update menus
        this.menu_configuration.updateAllMenuComponents();
    }
    /**
     * Reset value of drawing_area and substructur with data from JSON
     * then assign newly created drawing_area as Class_ApplicationData currentdrawing_area attribute
     *
     * @param {Type_JSON} json_object
     * @memberof Class_ApplicationData
     */
    fromJSON(json_object) {
        // Reset everything
        this.reset();
        // TODO read application data attributes
        // Update drawing area
        this.drawing_area.fromJSON(json_object);
        this.menu_configuration.updateAllMenuComponents();
    }
    toJSON() {
        // Create json struct
        const json_object = {};
        // TODO dump application data attributes
        json_object['node_label_separator'] = ''; // TODO get node label separator when implemented in class
        // Dump with drawing area & its content in json struct
        return Object.assign(Object.assign({}, json_object), this.drawing_area.toJSON());
    }
    // PRIVATE METHODS =====================================================================
    /**
     * Function to create custom application behavior when we press a key,
     *
     * even if this is a class method we have to ref the curr class in parametter because 'this' take another scope when it is called in onkeydown
     *
     * @private
     * @param {Class_ApplicationData} app_ref
     * @return {*}
     * @memberof Class_ApplicationData
     */
    keyboardEventListener(app_ref) {
        return (evt) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(evt.key) &&
                (((((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'INPUT') ?
                    d3.select(document.activeElement).attr('value') === 'menuConfigButton' :
                    true) &&
                    (!((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.className.includes('ql-editor'))))) {
                // Deplace les noeuds sélectionné avec les flèches du clavier, cependant ne ce déplace pas si jamais on utilise les flèches pour dépalcer le curseur dans un input
                // (exemples : le input de la largeur minimal d'un noeud)
                if (evt.key == 'ArrowUp') {
                    app_ref.drawing_area.selected_nodes_list.forEach(node => {
                        node.position_y -= app_ref.drawing_area.grid_size;
                    });
                }
                else if (evt.key == 'ArrowDown') {
                    app_ref.drawing_area.selected_nodes_list.forEach(node => {
                        node.position_y += app_ref.drawing_area.grid_size;
                    });
                }
                else if (evt.key == 'ArrowLeft') {
                    app_ref.drawing_area.selected_nodes_list.forEach(node => {
                        node.position_x -= app_ref.drawing_area.grid_size;
                    });
                }
                else if (evt.key == 'ArrowRight') {
                    app_ref.drawing_area.selected_nodes_list.forEach(node => {
                        node.position_x += app_ref.drawing_area.grid_size;
                    });
                }
                // Move all elements so none of them are outside the DA 
                this.drawing_area.recenterElements();
            }
            else if (evt.key == 'Escape') {
                // Set app in selection mode
                if (this.drawing_area.isInEditionMode())
                    this.drawing_area.switchMode();
                // Deselect all element
                app_ref.drawing_area.purgeSelection();
                // Close all menus
                // app_ref.menu_configuration.closeAllMenus() // TODO
            }
            // Event to delete all selected elements --------------------------------------------------------------
            else if (evt.key == 'Delete' && (!((_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.className.includes('ql-editor')))) {
                // Check if we are not in an input so we don't modify the value of it
                if (((_d = document.activeElement) === null || _d === void 0 ? void 0 : _d.tagName) !== 'INPUT' || d3.select(document.activeElement).attr('value') == 'menuConfigButton') {
                    // Delete selected elements
                    app_ref.drawing_area.selected_nodes_list.forEach(node => app_ref.drawing_area.deleteNode(node));
                    app_ref.drawing_area.selected_links_list.forEach(link => app_ref.drawing_area.deleteLink(link));
                    // Redraw remaining elements since their presence shape their appearence one another
                    app_ref.drawing_area.sankey.nodes_list.forEach(node => node.draw());
                    app_ref.drawing_area.sankey.links_list.forEach(link => link.draw());
                    // Update component
                    app_ref.menu_configuration.ref_to_menu_config_node_updater.current();
                    app_ref.menu_configuration.ref_to_menu_config_link_updater.current();
                }
            }
            else if (evt.key == 'a' && evt.ctrlKey) {
                // Event to select all elements
                // Prevent default event on ctrl + a
                evt.preventDefault();
                // Select all node & links
                app_ref.drawing_area.sankey.nodes_list.forEach(n => app_ref.drawing_area.addNodeToSelection(n));
                app_ref.drawing_area.sankey.links_list.forEach(l => app_ref.drawing_area.addLinkToSelection(l));
                // Update component
                app_ref.menu_configuration.ref_to_menu_config_node_updater.current();
                app_ref.menu_configuration.ref_to_menu_config_link_updater.current();
            }
            // Event to blur the input we are currently focused on
            // (It's in adequation with event on input that update drawing area when we blur input)
            // TODO surement à supprimer lorsque les inputs se feront avec menuConfigurationTextInput && menuConfigurationNumberInput
            else if ((evt.key == 'Enter') &&
                (((_e = document.activeElement) === null || _e === void 0 ? void 0 : _e.tagName) == 'INPUT') &&
                (['form-control', 'chakra-numberinput__field', 'chakra-input', 'name_label_input'].some(r => { var _a; return (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.className.includes(r); }))) {
                document.activeElement.blur();
            }
            // Event to save current diagram in cache
            else if ((evt.key == 's') &&
                (evt.ctrlKey) &&
                (!evt.shiftKey)) {
                // Prevent default event on ctrl + s
                evt.preventDefault();
                // Save in cache
                localStorage.setItem('data', LZString.compress(JSON.stringify(app_ref.toJSON())));
                localStorage.setItem('last_save', 'true');
                // Update logo save in cache
                app_ref.menu_configuration.ref_to_save_in_cache_indicator.current(true);
            }
            // event to download current sankey in JSON
            else if ((evt.key == 's' && evt.ctrlKey && evt.shiftKey) ||
                (evt.key == 'S' && evt.ctrlKey && evt.shiftKey)) {
                // Prevent default event on ctrl + shift + s
                evt.preventDefault();
                ClickSaveDiagram(app_ref, { mode_save: true, mode_visible_element: false });
            }
            // Fullscreen
            else if ((evt.key === 'f') &&
                (!evt.ctrlKey) &&
                (((_f = document.activeElement) === null || _f === void 0 ? void 0 : _f.tagName) !== 'INPUT')) {
                if ((!((_h = (_g = d3.select(document.activeElement)) === null || _g === void 0 ? void 0 : _g.attr('class')) === null || _h === void 0 ? void 0 : _h.includes('ql-editor')))) {
                    evt.preventDefault();
                    if (!document.fullscreenElement) {
                        document.documentElement.requestFullscreen();
                    }
                    else if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                }
            }
            // Open config menu
            else if (evt.key == 'Tab') {
                (_j = app_ref.menu_configuration.ref_to_btn_toogle_menu.current) === null || _j === void 0 ? void 0 : _j.click();
            }
        };
    }
    // GETTERS / SETTERS ==================================================================
    get drawing_area() { return this._drawing_area; }
    set drawing_area(value) { this._drawing_area = value; } // Only extended Class_ApplicationData instance can modify these parameter (for sub-module)
    get menu_configuration() { return this._menu_configuration; }
    set menu_configuration(value) { this._menu_configuration = value; } // Only extended Class_ApplicationData instance can modify these parameter (for sub-module)
    get has_free_account() { return this._has_free_account; }
    set has_free_account(value) { this._has_free_account = value; }
    get t() { return this._t; }
    get url_prefix() { return this._url_prefix; }
    set url_prefix(value) { this._url_prefix = value; }
    get logo() { return this._logo; }
    set logo(value) { this._logo = value; }
    get logo_opensankey() { return this._logo_opensankey; }
    set logo_opensankey(value) { this._logo_opensankey = value; }
    get logo_terriflux() { return this._logo_terriflux; }
    set logo_terriflux(value) { this._logo_terriflux = value; }
    get logo_width() { return this._logo_width; }
    set logo_width(value) { this._logo_width = value; }
    get app_name() { return this._app_name; }
    set app_name(value) { this._app_name = value; }
}
