// External imports
import * as d3 from 'd3';
import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaMinus, FaPalette, FaPlus, FaRandom, } from 'react-icons/fa';
import colormap from 'colormap';
import { Box, Button, Input, InputGroup, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr, } from '@chakra-ui/react';
// Local functions / components
import { default_grey_color, OSTooltip } from '../types/Utils';
import { GetRandomInt } from '../types/Legacy';
const list_palette_color = [
    d3.interpolateBlues,
    d3.interpolateBrBG,
    d3.interpolateBuGn,
    d3.interpolatePiYG,
    d3.interpolatePuOr,
    d3.interpolatePuBu,
    d3.interpolateRdBu,
    d3.interpolateRdGy,
    d3.interpolateRdYlBu,
    d3.interpolateRdYlGn,
    d3.interpolateSpectral,
    d3.interpolateTurbo,
    d3.interpolateViridis,
    d3.interpolateInferno,
    d3.interpolateMagma,
    d3.interpolatePlasma,
    d3.interpolateCividis,
    d3.interpolateWarm,
    d3.interpolateCool,
    d3.interpolateCubehelixDefault,
    d3.interpolateRainbow,
    d3.interpolateSinebow
];
const SankeySettingsEditionElementTags = ({ applicationData, elementTagNameProp, }) => {
    var _a, _b, _c;
    const { new_data } = applicationData;
    const { t } = new_data;
    // Get related tag groups & tags - Can be NodeTags, FluxTags or DataTags --------------
    const tags_group_dict = new_data.drawing_area.sankey.getTagGroupsAsDict(elementTagNameProp);
    const tags_group_list = new_data.drawing_area.sankey.getTagGroupsAsList(elementTagNameProp);
    const [tags_group_entry_id, setTagsGroupEntryId] = useState((_b = (_a = tags_group_list[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '');
    const tags_group_entry = tags_group_dict[tags_group_entry_id];
    const tags_entry = (_c = tags_group_entry === null || tags_group_entry === void 0 ? void 0 : tags_group_entry.tags_list) !== null && _c !== void 0 ? _c : [];
    // Trigger reloading of this component ------------------------------------------------
    const [, setCount] = useState(0);
    const updateThis = () => {
        var _a, _b;
        if (tags_group_dict[tags_group_entry_id])
            setCount(a => a + 1);
        else
            setTagsGroupEntryId((_b = (_a = new_data.drawing_area.sankey.getTagGroupsAsList(elementTagNameProp)[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '');
    };
    new_data.menu_configuration.ref_to_menu_config_tags_updater[elementTagNameProp].current = updateThis;
    // Chosen color palette used ----------------------------------------------------------
    // Couleur issues de : https://github.com/d3/d3-scale-chromatic
    const [color_map, setColorMap] = useState('jet');
    const color_maps = [
        'custom',
        'jet',
        'hsv',
        'hot',
        'cool',
        'spring',
        'summer',
        'autumn',
        'winter',
        'bone',
        'copper',
        'greys',
        'YIGnBu',
        'greens',
        'YIOrRd',
        'bluered',
        'RdBu',
        'picnic',
        'rainbow',
        'portland',
        'blackbody',
        'earth',
        'electric',
        'viridis',
        'inferno',
        'magma',
        'plasma',
        'warm',
        'cool',
        'rainbow-soft',
        'bathymetry',
        'cdom',
        'chlorophyll',
        'density',
        'freesurface-blue',
        'freesurface-red',
        'oxygen',
        'par',
        'phase',
        'salinity',
        'temperature',
        'turbidity',
        'velocity-blue',
        'velocity-green',
        'cubehelix'
    ];
    // Update function --------------------------------------------------------------------
    const updateThisAndToggleSavingIndicator = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Update this menu
        new_data.menu_configuration.updateAllComponentsRelatedToTagsType(elementTagNameProp);
    };
    const updateThisAndRelatedComponents = () => {
        // Update components related to tags in menu config or toolbar
        new_data.menu_configuration.updateAllComponentsRelatedToTags();
        // Update the rest
        updateThisAndToggleSavingIndicator();
    };
    // Buttons handlers -------------------------------------------------------------------
    /**
     * Button handler for tag adding in current tag group
     */
    const handleAddTagButton = () => {
        // Create default tag in current tag group
        tags_group_entry.addDefaultTag();
        // Full update
        updateThisAndRelatedComponents();
    };
    /**
     * Button handler for taggroup adding
     */
    const handleAddTagGrpButton = () => {
        // Create new default tag group
        const tag_group = new_data.drawing_area.sankey.createTagGroup(elementTagNameProp);
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Update components related to tags in menu config or toolbar
        new_data.menu_configuration.updateAllComponentsRelatedToTags();
        // Update this menu
        setTagsGroupEntryId(tag_group.id);
    };
    /**
     * Button handler for tag deletion
     * @param {Class_ProtoTag} tag
     */
    const handleDelTag = (tag) => {
        // Delete given tag
        tag.delete();
        // Update menus
        updateThisAndRelatedComponents();
    };
    /**
     * Button handler for tag group deletion
     *
     * @param {Class_TagGroup} tagg
     */
    const handleDelGroupTag = (tagg) => {
        // Delete given tag group
        new_data.drawing_area.sankey.removeTagGroup(elementTagNameProp, tagg);
        // Update menus
        updateThisAndRelatedComponents();
    };
    /**
     * Button handler for tag group banner modification
     * @param {Class_ProtoTagGroup} tag_group
     * @param {tag_banner_type} new_banner_type
     */
    const handleBanner = (tag_group, new_banner_type) => {
        // UPdate banner for given tag group
        tag_group.banner = new_banner_type;
        // Update menus
        updateThisAndRelatedComponents();
    };
    // Tags tables ------------------------------------------------------------------------
    let variant_table_edit_tag = 'table_edit_tag_node';
    if (elementTagNameProp == 'flux_taggs')
        variant_table_edit_tag = 'table_edit_tag_link';
    if (elementTagNameProp == 'data_taggs')
        variant_table_edit_tag = 'table_edit_tag_data';
    const tagSetting = (React.createElement(React.Fragment, null,
        React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
        t('Tags.GE'),
        ":",
        React.createElement(Box, { display: 'grid', gridTemplateColumns: '2fr 1fr 1fr 1fr' },
            React.createElement(Select, { variant: 'menuconfigpanel_option_select', onChange: (evt) => {
                    setTagsGroupEntryId(evt.target.value);
                }, value: tags_group_entry_id }, tags_group_list.map((tags_group) => React.createElement("option", { key: tags_group.id, value: tags_group.id }, tags_group.name))),
            React.createElement(OSTooltip, { label: t('Tags.tooltips.pal') },
                React.createElement(Button, { variant: 'toolbar_button_3', height: '100%', onClick: () => {
                        var _a, _b;
                        const color_selected = list_palette_color[GetRandomInt(list_palette_color.length)];
                        const nb_of_colors = tags_entry.length;
                        for (const i in d3.range(nb_of_colors)) {
                            tags_entry[i].color =
                                (_b = (_a = d3.color(color_selected(+i / nb_of_colors))) === null || _a === void 0 ? void 0 : _a.formatHex()) !== null && _b !== void 0 ? _b : default_grey_color;
                        }
                        // Update only this menu
                        updateThisAndToggleSavingIndicator();
                    } },
                    React.createElement(FaPalette, null))),
            React.createElement(OSTooltip, { label: t('Tags.tooltips.pal_shuffle') },
                React.createElement(Button, { variant: 'toolbar_button_4', height: '100%', onClick: () => {
                        // Color swaping between tags
                        const colors = tags_entry.map(tag => tag.color);
                        let nb_of_colors = colors.length;
                        if (nb_of_colors > 2) {
                            // Algo for 3+ colors
                            for (const i in d3.range(nb_of_colors)) {
                                nb_of_colors = colors.length;
                                const color_to_select_id = GetRandomInt(nb_of_colors);
                                const color_to_select = colors.splice(color_to_select_id, 1);
                                if (color_to_select != undefined && color_to_select != null) {
                                    tags_entry[i].color = color_to_select[0];
                                }
                                else {
                                    tags_entry[i].color = default_grey_color;
                                }
                            }
                        }
                        else if (nb_of_colors > 1) {
                            // Algo for 2 colors
                            // Do nothing for 1 color
                            tags_entry[0].color = colors[1];
                            tags_entry[1].color = colors[0];
                        }
                        // Update only this menu
                        updateThisAndToggleSavingIndicator();
                    } },
                    React.createElement(FaRandom, null))),
            React.createElement(OSTooltip, { label: t('Tags.tooltips.pal_std') },
                React.createElement(Select, { variant: 'menuconfigpanel_option_select', onChange: (evt) => {
                        // If custom color map, do nothing
                        if (evt.target.value === 'custom') {
                            return;
                        }
                        // Get random colors from color palette
                        const nb_tags = tags_entry.length;
                        const colors = colormap({
                            colormap: evt.target.value,
                            nshades: nb_tags,
                            format: 'hex',
                            alpha: 1
                        });
                        // Apply colors to tags
                        tags_entry.forEach((tag, i) => tag.color = colors[i]);
                        // Update displayed menu
                        setColorMap(evt.target.value);
                        updateThisAndToggleSavingIndicator();
                    }, value: color_map }, color_maps.map((cur_colormap, i) => React.createElement("option", { key: i, value: cur_colormap }, cur_colormap))))),
        React.createElement(TableContainer, null,
            React.createElement(Table, { variant: variant_table_edit_tag },
                React.createElement(Thead, null,
                    React.createElement(Tr, null,
                        React.createElement(Th, null,
                            React.createElement(OSTooltip, { label: t('Tags.tooltips.add') },
                                React.createElement(Button, { variant: 'menuconfigpanel_add_button', value: '+', onClick: handleAddTagButton },
                                    React.createElement(FaPlus, null)))),
                        React.createElement(Th, null, t('Tags.Nom')),
                        elementTagNameProp !== 'data_taggs' ?
                            React.createElement(Th, null, t('Tags.Visible')) : React.createElement(React.Fragment, null),
                        React.createElement(Th, null, t('Tags.Couleur')))),
                React.createElement(Tbody, null, tags_entry.length > 0 ?
                    tags_entry.map(tag => {
                        return (React.createElement(Tr, { key: tag.id },
                            React.createElement(Td, null,
                                React.createElement(OSTooltip, { label: t('Tags.tooltips.rm') },
                                    React.createElement(Button, { variant: 'menuconfigpanel_del_button_in_table', value: '-', onClick: () => { handleDelTag(tag); } },
                                        React.createElement(FaMinus, null)))),
                            React.createElement(Td, null,
                                React.createElement(OSTooltip, { label: t('Tags.tooltips.nom') },
                                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input_table' },
                                        React.createElement(Input, { variant: 'menuconfigpanel_option_input_table', id: tag.id, type: "text", value: tag.name, onChange: (evt) => {
                                                // Change tag name
                                                tag.name = evt.target.value;
                                                // Update all related menus
                                                updateThisAndRelatedComponents();
                                            } })))),
                            elementTagNameProp !== 'data_taggs' ?
                                React.createElement(Td, null,
                                    React.createElement(OSTooltip, { label: t('Tags.tooltips.visible') },
                                        React.createElement(Button, { variant: 'menuconfigpanel_option_button_in_table', name: 'element_visible' + tag.id, id: tag.id, onClick: () => {
                                                // Inverse selection
                                                tag.toogleSelected();
                                                // Update only this menu
                                                updateThisAndToggleSavingIndicator();
                                            } }, tag.is_selected ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null)))) :
                                React.createElement(React.Fragment, null),
                            React.createElement(Td, null,
                                React.createElement(OSTooltip, { label: t('Tags.tooltips.couleur') },
                                    React.createElement(Input, { padding: '0.25rem', width: 'revert', height: 'revert', type: 'color', value: tag.color, onChange: evt => {
                                            // Update tag color
                                            tag.color = evt.target.value;
                                            // Update only this menu
                                            updateThisAndToggleSavingIndicator();
                                        } })))));
                    }) :
                    React.createElement(React.Fragment, null))))));
    // Tag group menu ---------------------------------------------------------------------
    return (React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(TableContainer, null,
            React.createElement(Table, { variant: 'table_edit_grp_tag_node_link' },
                React.createElement(Thead, null,
                    React.createElement(Tr, null,
                        React.createElement(Th, null,
                            React.createElement(OSTooltip, { label: t('Tags.tooltips.add_grp') },
                                React.createElement(Button, { variant: 'menuconfigpanel_add_button', onClick: handleAddTagGrpButton },
                                    React.createElement(FaPlus, null)))),
                        React.createElement(Th, null, t('Tags.Nom')),
                        React.createElement(Th, null, t('Tags.Bannière')))),
                React.createElement(Tbody, null, tags_group_list.map(tag_group => {
                    return (React.createElement(Tr, { key: tag_group.id },
                        React.createElement(Td, null,
                            React.createElement(OSTooltip, { label: t('Tags.tooltips.rm_grp') },
                                React.createElement(Button, { size: 'sm', variant: 'menuconfigpanel_del_button_in_table', onClick: () => handleDelGroupTag(tag_group) },
                                    React.createElement(FaMinus, null)))),
                        React.createElement(Td, null,
                            React.createElement(OSTooltip, { label: t('Tags.tooltips.nom_grp') },
                                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input_table' },
                                    React.createElement(Input, { variant: 'menuconfigpanel_option_input_table', id: tag_group.id, type: "text", value: tag_group.name, onChange: (evt) => {
                                            // Change tag group name
                                            const new_name = evt.target.value;
                                            tag_group.name = new_name;
                                            // Update all related menus
                                            updateThisAndRelatedComponents();
                                        } })))),
                        React.createElement(Td, null,
                            React.createElement(OSTooltip, { label: t('Tags.tooltips.banner') },
                                React.createElement(Select, { variant: 'menuconfigpanel_option_select_table', onChange: (evt) => handleBanner(tag_group, evt.target.value), value: tag_group.banner },
                                    (elementTagNameProp != 'data_taggs') ?
                                        React.createElement("option", { key: 'none' + tag_group.id, id: 'NoneBaner', value: 'none' }, t('Menu.Aucun')) :
                                        React.createElement(React.Fragment, null),
                                    React.createElement("option", { key: 'one' + tag_group.id, id: 'OneBaner', value: 'one' }, t('Tags.Unique')),
                                    React.createElement("option", { key: 'multi' + tag_group.id, id: 'MultipleBaner', value: 'multi' }, t('Tags.Multiple')))))));
                })))),
        tags_group_list.length > 0 ? tagSetting : React.createElement(React.Fragment, null)));
};
export default null;
export { SankeySettingsEditionElementTags };
