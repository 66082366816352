import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, ButtonGroup, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { MenuContextLinksData } from '../configmenus/SankeyMenuConfigurationLinksData';
/*************************************************************************************************/
const icon_open_modal = React.createElement(FontAwesomeIcon, { style: { float: 'right' }, icon: faUpRightFromSquare });
const sep = React.createElement("hr", { style: { borderStyle: 'none', margin: '0px', color: 'grey', backgroundColor: 'grey', height: 2 } });
const checked = (b) => React.createElement("span", { style: { float: 'right' } }, b ? '✓' : '');
// MENU COMPONENT ***********************************************************************
export const ContextMenuLink = ({ applicationData, }) => {
    // Datas ------------------------------------------------------------------------------
    const { new_data } = applicationData;
    const { t } = new_data;
    const { ref_setter_show_menu_link_appearence, ref_setter_show_menu_link_data, ref_setter_show_menu_link_tooltip, ref_setter_show_menu_link_tags } = new_data.menu_configuration.dict_setter_show_dialog;
    // Link on which this menu applies ----------------------------------------------------
    const contextualised_link = new_data.drawing_area.link_contextualised;
    let style_c_l = '0px 0px auto auto';
    let is_top = true;
    let pos_x = new_data.drawing_area.pointer_pos[0];
    let pos_y = new_data.drawing_area.pointer_pos[1];
    const context_link_label_visible = (contextualised_link !== undefined) ? contextualised_link.value_label_is_visible : false;
    // The limit value of the mouse position that engages the shift of the context menu
    // is arbitrary and taken by hand because it is not possible to know the dimensions of the menu before it is render
    if (contextualised_link) {
        if (new_data.drawing_area.pointer_pos[0] + 240 > window.innerWidth) {
            pos_x = new_data.drawing_area.pointer_pos[0] - 245;
        }
        if (new_data.drawing_area.pointer_pos[1] + 360 > window.innerHeight) {
            pos_y = new_data.drawing_area.pointer_pos[1] - 340;
            is_top = false;
        }
        style_c_l = pos_y + 'px auto auto ' + pos_x + 'px';
    }
    const selected_links = new_data.drawing_area.visible_and_selected_links_list;
    const has_flux_tags = Object.values(new_data.drawing_area.sankey.flux_taggs_dict).length > 0;
    // Menu updaters ----------------------------------------------------------------------
    // Boolean used to force this component to reload
    const [, setCount] = useState(0);
    // Link this menu's update function
    new_data.menu_configuration.ref_to_menu_context_links_updater.current = () => setCount(a => a + 1);
    // Functions used to reset menu UI ----------------------------------------------------
    const refreshThisAndToggleSaving = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Refresh this menu
        setCount(a => a + 1);
    };
    // JSX Components ---------------------------------------------------------------------
    // Menu to change some pararmeter concerning the appearence of the node
    const dropdown_c_l_tag = ((contextualised_link !== undefined) &&
        (has_flux_tags)) ?
        React.createElement(Menu, { placement: 'end' },
            React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Menu.Transformation.tagFlux_assign')),
            React.createElement(MenuList, null, new_data.drawing_area.sankey.flux_taggs_list
                .filter(tagg => tagg.has_tags)
                .map(tagg => {
                return React.createElement(Menu, { placement: 'end' },
                    React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, tagg.name),
                    React.createElement(MenuList, null, tagg.tags_list
                        .map(tag => {
                        const has_tag = contextualised_link.hasGivenTag(tag);
                        return React.createElement(MenuItem, { onClick: () => {
                                // Assign tag to selected links
                                if (has_tag) {
                                    selected_links.forEach(l => l.addTag(tag));
                                }
                                else {
                                    selected_links.forEach(l => l.removeTag(tag));
                                }
                                refreshThisAndToggleSaving();
                            } },
                            t.name,
                            checked(has_tag));
                    })));
            }))) :
        React.createElement(React.Fragment, null);
    const button_open_link_appearence = (contextualised_link !== undefined) ?
        React.createElement(Button, { onClick: () => {
                ref_setter_show_menu_link_appearence.current(true);
                new_data.drawing_area.link_contextualised = undefined;
            }, variant: 'contextmenu_button' },
            t('Flux.apparence.apparence'),
            icon_open_modal) :
        React.createElement(React.Fragment, null);
    // Menu to change some pararmeter concerning the style of the node
    const dropdown_c_l_style_select = (contextualised_link !== undefined) ?
        React.createElement(Menu, { placement: 'end' },
            React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.SelectStyle')),
            React.createElement(MenuList, null, new_data.drawing_area.sankey.link_styles_list
                .map(sl => {
                var _a;
                return React.createElement(MenuItem, { onClick: () => {
                        contextualised_link.style = sl;
                        refreshThisAndToggleSaving();
                    } },
                    sl.name,
                    checked(((_a = contextualised_link === null || contextualised_link === void 0 ? void 0 : contextualised_link.style) !== null && _a !== void 0 ? _a : '') == sl));
            }))) :
        React.createElement(React.Fragment, null);
    // Selector of style (we can also reset local link attribute)
    const dropdown_c_l_style = (contextualised_link !== undefined) ?
        React.createElement(Menu, { placement: 'end' },
            React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.editStyle')),
            React.createElement(MenuList, null,
                React.createElement(MenuItem, { as: Button, variant: 'contextmenu_button', onClick: () => {
                        selected_links.forEach(l => l.resetAttributes());
                        refreshThisAndToggleSaving();
                    } }, t('Noeud.AS')),
                dropdown_c_l_style_select)) :
        React.createElement(React.Fragment, null);
    // Set stacking order of links
    const dropdown_c_l_layout = (contextualised_link !== undefined) ?
        React.createElement(Menu, { placement: 'end' },
            React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Flux.layout')),
            React.createElement(MenuList, null,
                React.createElement(MenuItem, { onClick: () => selected_links.forEach(l => l.setTopDisplayOrder()) }, t('Flux.layoutTop')),
                React.createElement(MenuItem, { onClick: () => selected_links.forEach(l => l.increaseDisplayOrder()) }, t('Flux.layoutUp')),
                React.createElement(MenuItem, { onClick: () => selected_links.forEach(l => l.decreaseDisplayOrder()) }, t('Flux.layoutDown')),
                React.createElement(MenuItem, { onClick: () => selected_links.forEach(l => l.setDownDisplayOrder()) }, t('Flux.layoutBottom')))) : React.createElement(React.Fragment, null);
    const button_open_link_data = (contextualised_link !== undefined) ?
        React.createElement(Button, { onClick: () => {
                ref_setter_show_menu_link_data.current(true);
                new_data.drawing_area.link_contextualised = undefined;
            }, variant: 'contextmenu_button' },
            t('Flux.data.données'),
            icon_open_modal) :
        React.createElement(React.Fragment, null);
    const button_mask_link_label = (contextualised_link !== undefined) ?
        React.createElement(Button, { onClick: () => {
                selected_links
                    .forEach(link => {
                    link.value_label_is_visible = !context_link_label_visible;
                });
                refreshThisAndToggleSaving();
            }, variant: 'light' }, context_link_label_visible ?
            t('Flux.apparence.hide_link_lab') :
            t('Flux.apparence.display_link_lab')) :
        React.createElement(React.Fragment, null);
    const button_open_link_tooltip = (contextualised_link !== undefined) ?
        React.createElement(Button, { onClick: () => {
                ref_setter_show_menu_link_tooltip.current(true);
                new_data.drawing_area.link_contextualised = undefined;
            }, variant: 'contextmenu_button' },
            t('Flux.IS'),
            icon_open_modal) :
        React.createElement(React.Fragment, null);
    const btn_l_show_tags_menu = React.createElement(Button, { onClick: () => {
            ref_setter_show_menu_link_tags.current(true);
            new_data.drawing_area.link_contextualised = undefined;
        }, variant: 'contextmenu_button' },
        t('Menu.Etiquettes'),
        icon_open_modal);
    // Inverse source & target of the link
    const btn_edit_value = (contextualised_link !== undefined) ?
        React.createElement(Menu, { placement: 'end' },
            React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Flux.data.edit_value')),
            React.createElement(MenuList, null,
                React.createElement(MenuContextLinksData, { applicationData: applicationData }))) : React.createElement(React.Fragment, null);
    const btn_inverse_io = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            selected_links.forEach(l => l.inverse());
            refreshThisAndToggleSaving();
        } }, t('Flux.if'));
    // Box that serve as context menu
    return (contextualised_link !== undefined) ?
        React.createElement(Box, { layerStyle: 'context_menu', id: "context_link_pop_over", className: 'context_popover ' + (is_top ? '' : 'at_bot'), style: { maxWidth: '100%', position: 'absolute', inset: style_c_l } },
            React.createElement(ButtonGroup, { orientation: 'vertical', isAttached: true },
                btn_inverse_io,
                sep,
                dropdown_c_l_style,
                sep,
                dropdown_c_l_layout,
                button_mask_link_label,
                btn_edit_value,
                has_flux_tags && sep,
                dropdown_c_l_tag,
                sep,
                button_open_link_data,
                button_open_link_appearence,
                btn_l_show_tags_menu,
                button_open_link_tooltip)) : React.createElement(React.Fragment, null);
};
