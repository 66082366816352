import React, { useState } from 'react';
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from 'react-icons/fa';
import { Box, Button, Checkbox, Select, TabPanel, Table, Tbody, Th, Thead, Tr, } from '@chakra-ui/react';
/*************************************************************************************************/
import { OSTooltip } from '../types/Utils';
/*************************************************************************************************/
/**
 * Define IO selection menu for nodes
  *
  * @param {*} {
  *   applicationContext,
  *   applicationData,
  *   menu_for_modal
  * }
  * @return {*}
  */
export const SankeyMenuConfigurationNodesIO = ({ applicationData, menu_for_modal }) => {
    // Data -------------------------------------------------------------------------------
    var _a, _b, _c, _d, _e, _f;
    const { new_data } = applicationData;
    const { t } = new_data;
    // Nodes to modify --------------------------------------------------------------------
    let selected_nodes;
    if (!new_data.menu_configuration.is_selector_only_for_visible_nodes) {
        // All availables nodes
        selected_nodes = new_data.drawing_area.selected_nodes_list_sorted;
    }
    else {
        // Only visible nodes
        selected_nodes = new_data.drawing_area.visible_and_selected_nodes_list_sorted;
    }
    const unique_node_selected = (selected_nodes.length === 1) ? selected_nodes[0] : undefined;
    // Related Links ----------------------------------------------------------------------
    let has_at_least_one_input_link = false;
    let has_at_least_one_output_link = false;
    selected_nodes.forEach(node => {
        has_at_least_one_input_link = (has_at_least_one_input_link || node.hasOutputLinks());
        has_at_least_one_output_link = (has_at_least_one_output_link || node.hasOutputLinks());
    });
    const has_input_links = (_a = unique_node_selected === null || unique_node_selected === void 0 ? void 0 : unique_node_selected.hasInputLinks()) !== null && _a !== void 0 ? _a : false;
    const has_output_links = (_b = unique_node_selected === null || unique_node_selected === void 0 ? void 0 : unique_node_selected.hasOutputLinks()) !== null && _b !== void 0 ? _b : false;
    // Set direction state ----------------------------------------------------------------
    const output_direction = 'o';
    const input_direction = 'i';
    const [direction_selected, setSelectedDirection] = useState(undefined);
    if (!direction_selected) {
        if (has_input_links)
            setSelectedDirection(input_direction);
        else if (has_output_links)
            setSelectedDirection(output_direction);
    }
    else {
        if (!has_input_links && (direction_selected === input_direction))
            setSelectedDirection(undefined);
        else if (!has_output_links && (direction_selected === output_direction))
            setSelectedDirection(undefined);
    }
    // Set selected side ------------------------------------------------------------------
    const [side_selected, setSelectedSide] = useState(undefined);
    if (direction_selected && !side_selected) {
        if (direction_selected === input_direction)
            setSelectedSide((_d = (_c = unique_node_selected === null || unique_node_selected === void 0 ? void 0 : unique_node_selected.input_links_list[0]) === null || _c === void 0 ? void 0 : _c.target_side) !== null && _d !== void 0 ? _d : undefined);
        else
            setSelectedSide((_f = (_e = unique_node_selected === null || unique_node_selected === void 0 ? void 0 : unique_node_selected.output_links_list[0]) === null || _e === void 0 ? void 0 : _e.source_side) !== null && _f !== void 0 ? _f : undefined);
    }
    else if (!direction_selected && side_selected) {
        setSelectedSide(undefined); // reset selected side
    }
    // Set list of links to reorganize ----------------------------------------------------
    const links_to_reorganize = {
        'right': [], 'left': [], 'top': [], 'bottom': []
    };
    if (unique_node_selected && direction_selected && side_selected) {
        Object.keys(links_to_reorganize)
            .forEach((_) => {
            const side = _;
            if (direction_selected === output_direction) {
                links_to_reorganize[side] = unique_node_selected.getOutputLinksForGivenSide(side);
            }
            else {
                links_to_reorganize[side] = unique_node_selected.getInputLinksForGivenSide(side);
            }
        });
    }
    // Boolean to color or not link table ------------------------------------------------
    const [tab_colored, setTabColored] = useState(false);
    // Components updaters ---------------------------------------------------------------
    // Function used to force this component to reload
    const [, setCount] = useState(0);
    // Link this menu's update function
    new_data.menu_configuration.ref_to_menu_config_node_io_updater.current = () => setCount(a => a + 1);
    /**
     * Local refresh function
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        setCount(a => a + 1);
    };
    // JSX Components ---------------------------------------------------------------------
    const content_reorg = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Noeud.Reorg_title')),
        React.createElement(OSTooltip, { label: t('Noeud.tooltips.Reorg') },
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: !has_at_least_one_input_link && !has_at_least_one_output_link, onClick: () => {
                    selected_nodes.forEach(node => node.reorganizeIOLinks());
                    refreshThisAndUpdateRelatedComponents();
                } }, t('Noeud.Reorg'))));
    // Content to reorganize the i/o of only 1 node at the time
    const content_for_one_node = (unique_node_selected &&
        direction_selected &&
        side_selected) ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(OSTooltip, { label: t('Noeud.PF.tooltips.io') },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.PF.FES'))),
                React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: direction_selected, onChange: (evt) => {
                        setSelectedDirection(evt.target.value);
                    } },
                    has_input_links ?
                        React.createElement("option", { value: input_direction }, t('Noeud.PF.ent')) :
                        React.createElement(React.Fragment, null),
                    has_output_links ?
                        React.createElement("option", { value: output_direction }, t('Noeud.PF.sort')) :
                        React.createElement(React.Fragment, null))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(OSTooltip, { label: t('Noeud.PF.tooltips.side') },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.PF.FRN'))),
                React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: side_selected, onChange: (evt) => {
                        setSelectedSide(evt.target.value);
                    } },
                    (links_to_reorganize['left'].length > 0) ?
                        React.createElement("option", { value: 'left' }, t('Noeud.PF.gauche')) :
                        React.createElement(React.Fragment, null),
                    (links_to_reorganize['right'].length > 0) ?
                        React.createElement("option", { value: 'right' }, t('Noeud.PF.droite')) :
                        React.createElement(React.Fragment, null),
                    (links_to_reorganize['top'].length > 0) ?
                        React.createElement("option", { value: 'top' }, t('Noeud.PF.ades')) :
                        React.createElement(React.Fragment, null),
                    (links_to_reorganize['bottom'].length > 0) ?
                        React.createElement("option", { value: 'bottom' }, t('Noeud.PF.edes')) :
                        React.createElement(React.Fragment, null))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: tab_colored, onChange: (evt) => {
                    setTabColored(evt.target.checked);
                } },
                React.createElement(OSTooltip, { label: t('Noeud.PF.tooltips.lti') }, t('Noeud.PF.lti'))),
            React.createElement(React.Fragment, null,
                React.createElement(Table, { variant: 'striped' },
                    React.createElement(Thead, null,
                        React.createElement(Tr, null,
                            React.createElement(Th, null, t('Menu.flux')),
                            React.createElement(Th, null, t('Tags.Position')))),
                    React.createElement(Tbody, null, links_to_reorganize[side_selected]
                        .map((link, link_idx) => {
                        const color = link.getPathColorToUse();
                        const bc = { 'backgroundColor': (color && tab_colored) ? color : 'inherit' };
                        const first_link = (link_idx === 0);
                        const last_link = link_idx === (links_to_reorganize[side_selected].length - 1);
                        return (React.createElement(Tr, { key: link.id },
                            React.createElement("td", { style: bc }, link.name),
                            React.createElement("td", { style: { 'width': '10%' } },
                                React.createElement(Box, { layerStyle: "options_2cols" },
                                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: first_link, minWidth: '0', onClick: () => {
                                            if (!first_link) {
                                                unique_node_selected.moveLinkToPositionInOrderBefore(link, links_to_reorganize[side_selected][link_idx - 1]);
                                            }
                                            refreshThisAndUpdateRelatedComponents();
                                        } },
                                        React.createElement(FaArrowAltCircleUp, null)),
                                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: last_link, minWidth: '0', onClick: () => {
                                            if (!last_link) {
                                                unique_node_selected.moveLinkToPositionInOrderAfter(link, links_to_reorganize[side_selected][link_idx + 1]);
                                            }
                                            refreshThisAndUpdateRelatedComponents();
                                        } },
                                        React.createElement(FaArrowAltCircleDown, null))))));
                    }))))) :
        React.createElement(React.Fragment, null);
    const content_always_present = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Noeud.Slct')),
        React.createElement(Box, { as: 'span', layerStyle: 'options_2cols' },
            React.createElement(OSTooltip, { label: t('Noeud.tooltips.SlctOutLink') },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', isDisabled: !has_at_least_one_output_link, onClick: () => {
                        new_data.drawing_area.purgeSelectionOfLinks();
                        selected_nodes
                            .forEach(node => {
                            node.output_links_list
                                .forEach(link => new_data.drawing_area.addLinkToSelection(link));
                        });
                    } }, t('Noeud.SlctOutLink'))),
            React.createElement(OSTooltip, { label: t('Noeud.tooltips.SlctInLink') },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', isDisabled: !has_at_least_one_input_link, onClick: () => {
                        new_data.drawing_area.purgeSelectionOfLinks();
                        selected_nodes
                            .forEach(node => {
                            node.input_links_list
                                .forEach(link => new_data.drawing_area.addLinkToSelection(link));
                        });
                    } }, t('Noeud.SlctInLink')))));
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        content_reorg,
        content_for_one_node,
        content_always_present);
    return menu_for_modal ?
        content :
        React.createElement(TabPanel, null, content);
};
