// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
import { Class_Element } from './Element';
import { default_element_position } from './Utils';
// CLASS ZONE SELECTION *****************************************************************
/**
 * Class that helps to create a selection zone for elements on the drawing area
 * @export
 * @class Class_ZoneSelection
 * @extends {Class_Element}
 */
export class Class_ZoneSelection extends Class_Element {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ZoneSelection.
     * @param {Class_DrawingArea} drawing_area
     * @param {Class_MenuConfig} menu_config
     * @memberof Class_ZoneSelection
     */
    constructor(drawing_area, menu_config) {
        // Init parent class attributes
        super('selection_zone', menu_config, 'g_select_zone');
        // PRIVATE ATTRIBUTES =================================================================
        this._width = 0;
        this._height = 0;
        this._starting_x_point = 0;
        this._starting_y_point = 0;
        this._is_visible = false; // Invisible by default
        // Init other class attributes
        this._display = {
            drawing_area: drawing_area,
            position: structuredClone(default_element_position),
        };
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Draw the element if visible
     * @memberof Class_ZoneSelection
     */
    draw() {
        var _a;
        // Heritance
        super.draw();
        // Draw shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('rect').attr('class', 'zone_selection').attr('width', this._width).attr('height', this._height).attr('fill', 'none').attr('stroke', 'black').attr('stroke-width', '2px').attr('stroke-dasharray', '5,5');
    }
    /**
     * Set the width & height of the selection zone
     *
     * @param {number} width
     * @param {number} height
     * @memberof Class_ZoneSelection
     */
    setSize() {
        var _a;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.zone_selection').attr('width', this._width).attr('height', this._height);
    }
    /**
     * Function to select elements present in the selection zone
     * (nodes has to be fully inside the zone to be selected)
     * @memberof Class_ZoneSelection
     */
    selectElementsInside() {
        this.drawing_area.sankey.visible_nodes_list
            .filter(n => {
            // Check if node is horizontally in selection zone
            const is_node_horizontally_in_zone = ((n.position_x >= this.position_x) &&
                (n.position_x <= (this.position_x + this._width)) &&
                ((n.position_x + n.getShapeWidthToUse()) <= (this.position_x + this._width)));
            // Check if node is vertically in selection zone
            const is_node_vertically_in_zone = ((n.position_y >= this.position_y) &&
                (n.position_y <= (this.position_y + this._height)) &&
                ((n.position_y + n.getShapeHeightToUse()) <= (this.position_y + this._height)));
            // Must be verticalt & horizontaly in selection zone
            return (is_node_horizontally_in_zone && is_node_vertically_in_zone);
        })
            .forEach(n => {
            this.drawing_area.addNodeToSelection(n);
        });
    }
    /**
     * Reset selection zone by invisibilizing it and resetting it position & size
     * @memberof Class_ZoneSelection
     */
    reset() {
        this.setPosXY(0, 0);
        this._width = 0;
        this._height = 0;
        this.starting_x_point = 0;
        this.starting_y_point = 0;
        this._is_visible = false;
        this.draw();
    }
    // GETTERS / SETTERS ==================================================================
    get width() { return this._width; }
    set width(value) { this._width = value; }
    get height() { return this._height; }
    set height(value) { this._height = value; }
    get starting_x_point() { return this._starting_x_point; }
    set starting_x_point(value) { this._starting_x_point = value; }
    get starting_y_point() { return this._starting_y_point; }
    set starting_y_point(value) { this._starting_y_point = value; }
}
