// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
// Local types
import { Class_ProtoElement } from './Element';
import { default_style_id } from './Sankey';
import { Class_Handler } from './Handler';
import { default_element_color, getBooleanFromJSON, getJSONFromJSON, getJSONOrUndefinedFromJSON, getNumberFromJSON, getNumberOrNullFromJSON, getNumberOrUndefinedFromJSON, getStringFromJSON, getStringOrNullFromJSON, getStringOrUndefinedFromJSON, makeId, } from './Utils';
// SPECIFIC CONSTANTS *******************************************************************
export const default_shape_arrow_size = 10;
export const default_shape_color = default_element_color;
export const default_shape_curvature = 0.5;
export const default_shape_is_arrow = true;
export const default_shape_is_curved = true;
export const default_shape_is_dashed = false;
export const default_shape_is_recycling = false;
export const default_shape_opacity = 0.85;
export const default_shape_orientation = 'hh';
export const default_shape_starting_curve = 0.05;
export const default_shape_ending_curve = 0.95;
export const default_shape_starting_tangeant = 0.25;
export const default_shape_ending_tangeant = 0.25;
export const default_shape_middle_recyling = 100;
export const default_shape_vert_shift = 0; // TODO supprimer ce truc -> sert à rien
export const default_value_label_color = 'black';
export const default_value_label_custom_digit = false;
export const default_value_label_font_family = 'Arialserif';
export const default_value_label_font_size = 20;
export const default_value_label_is_visible = true;
export const default_value_label_nb_digit = 0;
export const default_value_label_on_path = true;
export const default_value_label_pos_auto = false;
export const default_value_label_position = 'middle';
export const default_value_label_orthogonal_position = 'middle';
export const default_value_label_scientific_precision = 5;
export const default_value_label_to_precision = false;
export const default_value_label_unit = '';
export const default_value_label_unit_visible = false;
const side_order = {
    'right': 0,
    'bottom': 1,
    'left': 2,
    'top': 3
};
// SPECIFIC FUNCTIONS ********************************************************************
export function defaultLinkId(source, target) {
    // TODO ajouter makeID pour crer id unique
    return source.name + ' --> ' + target.name;
}
/**
 * Allows to sort links alphabethically per id
 * @export
 * @param {(Class_LinkElement | Class_LinkStyle)} a
 * @param {(Class_LinkElement | Class_LinkStyle)} b
 * @return {*}
 */
export function sortLinksElementsByIds(a, b) {
    if (a.id > b.id)
        return 1;
    else if (a.id < b.id)
        return -1;
    else
        return 0;
}
/**
 * Allow to sort links by their z-ordre on the drawing area
 * @export
 * @param {Class_LinkElement} a
 * @param {Class_LinkElement} b
 * @return {*}
 */
export function sortLinksElementsByDisplayingOrders(a, b) {
    if (a.displaying_order > b.displaying_order)
        return 1;
    else if (a.displaying_order < b.displaying_order)
        return -1;
    else
        return 0;
}
/**
 * Allows to sort links of a given node by comparing their source / target relatives positions
 * @export
 * @param {Class_LinkElement} link_a
 * @param {Class_LinkElement} link_b
 * @param {Class_NodeElement} node
 * @return {*}
 */
export function sortLinksElementsByRelativeNodesPositions(link_a, link_b, node) {
    // Check relation between reference node and the two links
    const is_node_source_for_link_a = (link_a.source === node);
    const is_node_target_for_link_a = (link_a.target === node);
    const is_node_source_for_link_b = (link_b.source === node);
    const is_node_target_for_link_b = (link_b.target === node);
    // Failsafe
    if ((!is_node_source_for_link_a && !is_node_target_for_link_a) ||
        (!is_node_source_for_link_b && !is_node_target_for_link_b))
        return 0; // Dont move - somethings is wrong
    // Get nodes that we need to compare
    let node_a;
    let node_b;
    let side_a;
    let side_b;
    if (is_node_source_for_link_a) {
        node_a = link_a.target;
        side_a = link_a.source_side;
    }
    else {
        node_a = link_a.source;
        side_a = link_a.target_side;
    }
    if (is_node_source_for_link_b) {
        node_b = link_b.target;
        side_b = link_b.source_side;
    }
    else {
        node_b = link_b.source;
        side_b = link_b.target_side;
    }
    // Side check : Node position comparaison if links are on the same side ?
    if (side_a === side_b) {
        // For "horizontal" sides
        if (side_a === 'right' || side_a === 'left') {
            if (node_a.position_y > node_b.position_y)
                return 1;
            else if (node_a.position_y < node_b.position_y)
                return -1;
            else
                return 0;
        }
        // For "vertical" sides
        else {
            if (node_a.position_x > node_b.position_x)
                return 1;
            else if (node_a.position_x < node_b.position_x)
                return -1;
            else
                return 0;
        }
    }
    // Otherwise, use side "priority"
    else {
        if (side_order[side_a] < side_order[side_b])
            return -1;
        else
            return 1;
    }
}
/**
 * Check if given attribute is overloaded in at least one link
 * @export
 * @param {Class_LinkElement[]} links
 * @param {keyof Class_LinkAttribute} attr
 * @return {*}
 */
export function isAttributeOverloaded(links, attr) {
    let overloaded = false;
    links.forEach(link => overloaded = (overloaded || link.isAttributeOverloaded(attr)));
    return overloaded;
}
// CLASS LINK ELEMENT ********************************************************************
/**
 * Class that define how to display a link element and how to interact with it
 *
 * @class Class_LinkElement
 */
export class Class_LinkElement extends Class_ProtoElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_LinkElement.
     * @param {string} id
     * @param {Class_DrawingArea} drawing_area
     * @memberof Class_LinkElement
     */
    constructor(id, source, target, drawing_area, menu_config) {
        // Init parent class attributes
        super(id, menu_config, 'g_links');
        /**
         * Value of tooltip text associated to link
         * @private
         * @type {string}
         * @memberof Class_LinkElement
         */
        this._tooltip_text = '';
        // Boolean var only used when enlarging thickness when mouse hovering link
        this._artifical_enlargement = false;
        // Display
        this._display = {
            drawing_area: drawing_area,
            displaying_order: drawing_area.addElement(),
            position_starting: {
                type: 'absolute',
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            position_ending: {
                type: 'absolute',
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            style: drawing_area.sankey.default_link_style,
            attributes: new Class_LinkAttribute()
        };
        // Link with style
        this._display.style.addReference(this);
        // Add control points
        this._control_points = {
            starting_curve_point: new Class_Handler('cp_start_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.startCurvePointDragEvent(), this.dragHandleEnd(), { class: 'cp_start' }),
            ending_curve_point: new Class_Handler('cp_end_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.endCurvePointDragEvent(), this.dragHandleEnd(), { class: 'cp_end' }),
            starting_bezier_point: new Class_Handler('bz_start_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.startTangeantDragEvent(), this.dragHandleEnd(), { class: 'bz_start' }),
            ending_bezier_point: new Class_Handler('bz_end_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.endTangeantDragEvent(), this.dragHandleEnd(), { class: 'bz_end' }),
            middle_recycling_point: new Class_Handler('recy_middle_' + id, drawing_area, menu_config, this, this.dragHandleStart(), this.middleRecyclingDragEvent(), this.dragHandleEnd(), { class: 'recy_middle' }),
            is_dragged: false
        };
        // Values
        this._values = new Class_LinkValue(this);
        drawing_area.sankey.data_taggs_list
            .forEach(data_tagg => {
            this._values = this._values.expand(data_tagg);
        });
        // Source
        this._source = source;
        this._target = target; // Target
        this._source.addOutputLink(this);
        this._target.addInputLink(this); // Target
        // Instanciate display on svg
        this.computeControlPoints();
        this.draw();
    }
    // CLEANING ===========================================================================
    /**
     * Define deletion behavior
     * @memberof Class_LinkElement
     */
    cleanForDeletion() {
        // Unref self from source node
        this._source.deleteOutputLink(this);
        // Unref self from target node
        this._target.deleteInputLink(this);
        // Delete control points
        this._control_points.starting_curve_point.delete();
        this._control_points.ending_curve_point.delete();
        this._control_points.starting_bezier_point.delete();
        this._control_points.ending_bezier_point.delete();
        this._control_points.middle_recycling_point.delete();
        // Unref self from styles
        this.style.removeReference(this);
        // Delete related values
        this._values.delete();
        // TODO remove handler
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Set up element on d3 svg area
     * @private
     * @memberof Class_LinkElement
     */
    draw() {
        var _a;
        // Heritance
        super.draw();
        // Update class attributes
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_links');
        // Setup order
        this.drawing_area.orderElements();
        // Draw elements
        this.drawElements();
    }
    drawWithNodes() {
        if (this.source && this.target) {
            this.source.draw();
            this.target.draw();
        }
    }
    drawAsSelected() {
        this.drawControlPoint();
    }
    drawElements() {
        this.drawPath();
        this.drawLabel();
    }
    /**
     * Reset all attributes as defined by style
     * @memberof Class_LinkElement
     */
    resetAttributes() {
        this._display.attributes = new Class_LinkAttribute();
        // Need to redraw from nodes
        this.drawWithNodes();
    }
    /**
     * Reverse source with target
     * @memberof Class_LinkElement
     */
    inverse() {
        const tmp_target = this._target;
        const tmp_source = this._source;
        this._source = tmp_target;
        this._target = tmp_source;
        this.drawElements();
    }
    setPosXYStartingPoint(x, y) {
        this._display.position_starting.x = x;
        this._display.position_starting.y = y;
        this.draw();
    }
    setPosXYEndingPoint(x, y) {
        this._display.position_ending.x = x;
        this._display.position_ending.y = y;
        this.draw();
    }
    increaseDisplayOrder() {
        this._display.displaying_order = this._display.displaying_order + 3;
        this.draw();
    }
    decreaseDisplayOrder() {
        this._display.displaying_order = this._display.displaying_order - 3;
        this.draw();
    }
    setTopDisplayOrder() {
        this._display.displaying_order = this._display.drawing_area.addElement();
        this.draw();
    }
    setDownDisplayOrder() {
        this._display.displaying_order = -1;
        this.draw();
    }
    deleteRelativeLabelPos() {
        delete this._display.position_x_label;
        delete this._display.position_y_label;
        this.drawLabel();
    }
    /**
     * Check if given tag is referenced by link's data
     * @param {Class_Tag} tag
     * @return {*}
     * @memberof Class_LinkElement
     */
    hasGivenTag(tag) {
        const value = this.value;
        if (value)
            return value.hasGivenTag(tag);
        return false;
    }
    /**
     * Add and cross-reference a Tag with a link
     * @param {Class_Tag} tag
     * @memberof Class_LinkElement
     */
    addTag(tag) {
        const value = this.value;
        if (value)
            value.addTag(tag);
    }
    /**
     * Remove given tag and cross-reference from link
     * @param {Class_Tag} tag
     * @memberof Class_LinkElement
     */
    removeTag(tag) {
        const value = this.value;
        if (value)
            value.removeTag(tag);
    }
    addDataTagGroup(tagg) {
        this._values = this._values.expand(tagg);
    }
    removeDataTagGroup(tagg) {
        if (this._values instanceof Class_LinkValueTree)
            this._values = this._values.prune(tagg);
    }
    addDataTag(tag) {
        if (this._values instanceof Class_LinkValueTree)
            this._values.extend(tag);
    }
    removeDataTag(tag) {
        if (this._values instanceof Class_LinkValueTree)
            this._values.reduce(tag);
    }
    useDefaultStyle() {
        this.style = this.main_sankey.default_link_style;
        this.drawElements();
    }
    isAttributeOverloaded(attr) {
        return this._display.attributes[attr] !== undefined;
    }
    isEqual(_) {
        if (this.shape_orientation !== _.shape_orientation) {
            return false;
        }
        if (this.shape_starting_curve !== _.shape_starting_curve) {
            return false;
        }
        if (this.shape_ending_curve !== _.shape_ending_curve) {
            return false;
        }
        if (this.shape_vert_shift !== _.shape_vert_shift) {
            return false;
        }
        if (this.shape_curvature !== _.shape_curvature) {
            return false;
        }
        if (this.shape_is_curved !== _.shape_is_curved) {
            return false;
        }
        if (this.shape_is_recycling !== _.shape_is_recycling) {
            return false;
        }
        if (this.shape_arrow_size !== _.shape_arrow_size) {
            return false;
        }
        if (this.value_label_position !== _.value_label_position) {
            return false;
        }
        if (this.value_label_orthogonal_position !== _.value_label_orthogonal_position) {
            return false;
        }
        if (this.value_label_on_path !== _.value_label_on_path) {
            return false;
        }
        if (this.value_label_pos_auto !== _.value_label_pos_auto) {
            return false;
        }
        if (this.shape_is_arrow !== _.shape_is_arrow) {
            return false;
        }
        if (this.shape_color !== _.shape_color) {
            return false;
        }
        if (this.shape_opacity !== _.shape_opacity) {
            return false;
        }
        if (this.shape_is_dashed !== _.shape_is_dashed) {
            return false;
        }
        if (this.value_label_is_visible !== _.value_label_is_visible) {
            return false;
        }
        if (this.value_label_font_size !== _.value_label_font_size) {
            return false;
        }
        if (this.value_label_color !== _.value_label_color) {
            return false;
        }
        if (this.value_label_to_precision !== _.value_label_to_precision) {
            return false;
        }
        if (this.value_label_scientific_precision !== _.value_label_scientific_precision) {
            return false;
        }
        if (this.value_label_font_family !== _.value_label_font_family) {
            return false;
        }
        if (this.value_label_unit_visible !== _.value_label_unit_visible) {
            return false;
        }
        if (this.value_label_unit !== _.value_label_unit) {
            return false;
        }
        if (this.value_label_custom_digit !== _.value_label_custom_digit) {
            return false;
        }
        if (this.value_label_nb_digit !== _.value_label_nb_digit) {
            return false;
        }
        return true;
    }
    toJSON(with_values = true) {
        // Root attributes
        const json_object = super.toJSON();
        // Related nodes
        json_object['idSource'] = this._source.id;
        json_object['idTarget'] = this._target.id;
        // Fill style & local attributes
        json_object['style'] = this.style.id;
        json_object['local'] = this._display.attributes.toJSON();
        // Fill dragged postion attribute
        if (this._display.position_offset_label !== undefined)
            json_object['position_offset_label'] = this._display.position_offset_label;
        if (this._display.position_x_label !== undefined)
            json_object['position_x_label'] = this._display.position_x_label;
        if (this._display.position_y_label !== undefined)
            json_object['position_y_label'] = this._display.position_y_label;
        // Values
        if (with_values)
            json_object['value'] = this._values.toJSON();
        // Out
        return json_object;
    }
    fromJSON(json_object, matching_nodes_id = {}, matching_taggs_id = {}, matching_tags_id = {}) {
        var _a, _b;
        // Root attributes
        super.fromJSON(json_object);
        // Related nodes
        let source_node_id = getStringOrUndefinedFromJSON(json_object, 'idSource');
        if (source_node_id) {
            source_node_id = (_a = matching_nodes_id[source_node_id]) !== null && _a !== void 0 ? _a : source_node_id;
            if (this.main_sankey.nodes_dict[source_node_id]) {
                this.source = this.main_sankey.nodes_dict[source_node_id];
                this.shape_is_recycling = false;
            }
        }
        let target_node_id = getStringOrUndefinedFromJSON(json_object, 'idTarget');
        if (target_node_id) {
            target_node_id = (_b = matching_nodes_id[target_node_id]) !== null && _b !== void 0 ? _b : target_node_id;
            if (this.main_sankey.nodes_dict[target_node_id]) {
                this.target = this.main_sankey.nodes_dict[target_node_id];
                this.shape_is_recycling = false;
            }
        }
        // Get style & local attributes
        const style_id = getStringFromJSON(json_object, 'style', default_style_id);
        this._display.style = this.main_sankey.link_styles_dict[style_id];
        const json_local_object = getJSONOrUndefinedFromJSON(json_object, 'local');
        if (json_local_object) {
            this._display.attributes.fromJSON(json_local_object);
        }
        // Get dragged label pos if defined
        this._display.position_offset_label = getNumberOrUndefinedFromJSON(json_object, 'position_offset_label');
        this._display.position_x_label = getNumberOrUndefinedFromJSON(json_object, 'position_x_label');
        this._display.position_y_label = getNumberOrUndefinedFromJSON(json_object, 'position_y_label');
        // Get value
        this._values.fromJSON(getJSONFromJSON(json_object, 'value', {}), matching_taggs_id, matching_tags_id);
    }
    getPathColorToUse() {
        // Default color
        let shape_color = this.shape_color;
        // Do we apply color of flux tags ?
        const flux_taggs_activated = this.flux_taggs_list
            .filter(tagg => tagg.show_legend);
        if (flux_taggs_activated.length > 0) {
            const tagg_for_colormap = flux_taggs_activated[0];
            const tags_for_colormap = this.flux_tags_list
                .filter(tag => (tag.group === tagg_for_colormap))
                .filter(tag => tag.is_selected);
            if (tags_for_colormap.length > 0)
                shape_color = tags_for_colormap[0].color;
        }
        else {
            // Do we apply colors of data tags ?
            this.main_sankey.selected_data_tags_list
                .filter(tag => tag.group.show_legend)
                .forEach(tag => shape_color = tag.color);
        }
        return shape_color;
    }
    /**
     * Copy attributes from element & create/copy ref to current sankey (ref to link_taggs & style & values)
     *
     * @param {Class_LinkElement} element
     * @memberof Class_LinkElement
     */
    copyFrom(element) {
        // this._display.position = structuredClone(element._display.position)
        this._display.position_x_label = element._display.position_x_label;
        this._display.position_y_label = element._display.position_y_label;
        this._tooltip_text = element._tooltip_text;
        // Copy local attributes
        this._display.attributes.copyFrom(element._display.attributes);
        // Copy control points attributes from new layout
        this._control_points.starting_curve_point.copyFrom(element._control_points.starting_curve_point);
        this._control_points.ending_curve_point.copyFrom(element._control_points.ending_curve_point);
        this._control_points.starting_bezier_point.copyFrom(element._control_points.starting_bezier_point);
        this._control_points.ending_bezier_point.copyFrom(element._control_points.ending_bezier_point);
        this._control_points.middle_recycling_point.copyFrom(element._control_points.middle_recycling_point);
        // Set link style to element style if they have the same id & existing in current data (style should have been updated with new layout when we do this function)
        if (this.drawing_area.sankey.link_styles_list.map(ls => ls.id).includes(element._display.style.id)) {
            const new_style_id = this.drawing_area.sankey.link_styles_list
                .map(ls => ls.id)
                .filter(ls => ls.includes(element._display.style.id))[0];
            this._display.style = this.drawing_area.sankey.link_styles_dict[new_style_id];
            this._display.style.addReference(this);
        }
        // Set dragged position from element if defined
        if (element._display.position_offset_label !== undefined)
            this._display.position_offset_label = element._display.position_offset_label;
        if (element._display.position_x_label !== undefined)
            this._display.position_x_label = element._display.position_x_label;
        if (element._display.position_y_label !== undefined)
            this._display.position_y_label = element._display.position_y_label;
    }
    /**
     * Return maximum value possible for this link
     *
     * @return {*}
     * @memberof Class_LinkElement
     */
    getMaxValue() {
        return this._values.getMaxValue();
    }
    getAllValues() {
        return this._values.getAllValues();
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Link
     */
    eventSimpleLMBCLick(event) {
        // Apply parent behavior first
        super.eventSimpleLMBCLick(event);
        // Get related drawing area
        const drawing_area = this.drawing_area;
        // EDITION MODE ===========================================================
        if (drawing_area.isInEditionMode()) {
            // Purge selection list
            drawing_area.purgeSelection();
            // Close all menus
            drawing_area.application_data.menu_configuration.CloseConfigMenu();
        }
        // SELECTION MODE =========================================================
        else if (drawing_area.isInSelectionMode()) {
            // SHIFT
            if (event.shiftKey) {
                // Add link to selection
                drawing_area.addLinkToSelection(this);
                // Open related menu
                this.menu_config.OpenConfigMenuElementsLinks();
                // Update components related to link edition
                this.menu_config.updateAllComponentsRelatedToLinks();
            }
            // CTRL
            else if (event.ctrlKey) {
                // Add link to selection
                drawing_area.addLinkToSelection(this);
                // Update components related to link edition
                this.menu_config.updateAllComponentsRelatedToLinks();
            }
            // OTHERS
            else {
                // If we're here then it's a simple click (no ctrl,alt or shift key pressed) - purge
                // Purge selection list
                drawing_area.purgeSelection();
                // Add link to selection
                drawing_area.addLinkToSelection(this);
            }
        }
    }
    eventSimpleRMBCLick(event) {
        // Apply parent behavior first
        super.eventSimpleRMBCLick(event);
        // SELECTION MODE =========================================================
        if (this.drawing_area.isInSelectionMode()) {
            event.preventDefault();
            this.drawing_area.pointer_pos = [event.pageX, event.pageY];
            if (!this.drawing_area.selected_links_list.includes(this)) {
                this.drawing_area.addLinkToSelection(this);
            }
            this.menu_config.updateAllComponentsRelatedToLinks();
            this.drawing_area.link_contextualised = this;
            this.menu_config.ref_to_menu_context_links_updater.current();
        }
    }
    /**
     * Define event when mouse moves over element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOver(event) {
        var _a;
        // Apply parent behavior first
        super.eventMouseOver(event);
        // ALT
        if (event.altKey) {
            // // Purge selection list
            // this.drawing_area.purgeSelection()
            // Show tooltip
            this.drawTooltip();
        }
        else if (this.thickness < 15) {
            this._artifical_enlargement = true;
            // Artificially enlarge link thickness if too thin
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_path').attr('stroke-width', 15);
        }
    }
    /**
     * Define event when mouse move out of element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOut(event) {
        var _a;
        super.eventMouseOut(event);
        // Clear tooltip
        d3.selectAll('.sankey-tooltip').remove();
        // reset link thickness
        if (this._artifical_enlargement) {
            this._artifical_enlargement = false;
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_path').attr('stroke-width', this.thickness);
        }
    }
    // PRIVATE METHODS ====================================================================
    /**
     * Draw link shape on d3 svg
     * @private
     * @memberof Class_LinkElement
     */
    drawPath() {
        var _a, _b, _c;
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_path').remove();
        // Failsafe
        if (this._source && this._target) {
            // Add new path shape
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('path').classed('link', true).classed('link_path', true).attr('d', () => this.getBezierPath());
            // Apply properties
            (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.selectAll('.link_path').attr('id', this.id).attr('fill', 'none').attr('stroke', () => this.getPathColorToUse()).attr('stroke-opacity', this.shape_opacity).attr('stroke-width', this.thickness).attr('stroke-dasharray', (this.shape_is_dashed || this.data_value == null) ? '5,3' : '');
        }
    }
    drawLabel() {
        var _a, _b, _c;
        // Clean previous label
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_label').remove();
        // Add value label
        if (this.value_label_is_visible && ((_b = this.data_value) !== null && _b !== void 0 ? _b : 0) >= this.drawing_area.filter_label) {
            // Failsafe
            if (this._source && this._target) {
                // Compute label to display
                const label_to_display = this.getLabelToDisplay();
                // If label is undefined or null, do nothing
                if (label_to_display) {
                    // Create text object
                    const d3_text_selection = (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.append('text').classed('link', true).classed('link_label', true).classed('link_label_text', true).attr('id', 'label_text_' + this.id);
                    d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.style('font-weight', 'bold').style('font-style', 'normal').style('font-size', String(this.value_label_font_size) + 'px').style('font-family', this.value_label_font_family).attr('fill', (this.value_label_color === 'color') ?
                        this.shape_color :
                        this.value_label_color);
                    // Compute text position
                    if (this.value_label_on_path) {
                        // Create text on path
                        const d3_textpath_selection = d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.append('textPath').classed('link', true).classed('link_label', true).classed('link_label_textpath', true).attr('id', 'label_textpath_' + this.id).attr('href', '#' + this.id);
                        // Add text directly on textpath object
                        d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.text(label_to_display).attr('spacing', 'exact').attr('method', 'align');
                        // Add styling text attributes directly on text object
                        // Relative position from starting point of path
                        this.updateTextPathOffset();
                        if (!this.drawing_area.static) {
                            d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragTextPathStart(ev))
                                .on('drag', ev => this.dragTextPathMove(ev))
                                .on('end', ev => this.dragTextPathEnd(ev)));
                        }
                    }
                    else {
                        this.updateTextXYPosition();
                        d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.text(label_to_display).attr('spacing', 'exact').attr('method', 'align');
                        if (!this.drawing_area.static) {
                            d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragTextStart(ev))
                                .on('drag', ev => this.dragTextMove(ev))
                                .on('end', ev => this.dragTextEnd(ev)));
                        }
                    }
                }
            }
        }
    }
    //================= Functions for link label if it is a TextPath  =================
    /**
     * Function used to set link label offset on DA & other attribute linkd to it
     *
     * @private
     * @memberof Class_LinkElement
     */
    updateTextPathOffset() {
        var _a, _b, _c, _d;
        const [label_position, label_anchor, label_ortho_position, label_dominant_baseline] = this.getTextPathOffset();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_label_textpath').attr('text-anchor', label_anchor);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_label_textpath').attr('startOffset', label_position + '%');
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_label_textpath').attr('dy', label_ortho_position);
        (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.select('.link_label_textpath').attr('dominant-baseline', label_dominant_baseline);
    }
    /**
     * Function used to return link label offset on DA & other attribute linkd to it
     *
     * @private
     * @return {*}  {[number, string, number, string]}
     * @memberof Class_LinkElement
     */
    getTextPathOffset() {
        // Initialize value as if it link attributes were :
        // - value_label_position : 'start'
        // - value_label_orthogonal_position : 'above'
        // Offset positions
        let label_anchor = 'start';
        let label_position = 1;
        // Ortogonal position from path
        let label_ortho_position = -this.thickness / 2;
        let label_dominant_baseline = 'text-after-edge';
        if (this._display.position_offset_label !== undefined) {
            const offset = this._display.position_offset_label;
            // offset attributes when dragged
            label_anchor = offset > 50 ? 'end' : 'start';
            label_position = offset;
            // orthogonal attributes when dragged
            label_ortho_position = 0;
            label_dominant_baseline = 'middle';
        }
        else {
            // offset attributes
            if (this.value_label_position === 'middle') {
                label_anchor = 'middle';
                label_position = 50;
            }
            else if (this.value_label_position === 'end') {
                label_anchor = 'end';
                label_position = 99;
            }
            // orthogonal attributes
            if (this.value_label_orthogonal_position === 'middle') {
                label_ortho_position = 0;
                label_dominant_baseline = 'middle';
            }
            else if (this.value_label_orthogonal_position === 'below') {
                label_ortho_position = this.thickness / 2 + this.value_label_font_size;
                label_dominant_baseline = 'text-top';
            }
        }
        return [label_position, label_anchor, label_ortho_position, label_dominant_baseline];
    }
    /**
     * Function triggered when we start dragging node name label when it follow the link path, it initialise relative position if undefined
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,Class_LinkElement,Class_LinkElement>} event
     * @memberof Class_LinkElement
     */
    dragTextPathStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        if (this._display.position_offset_label === undefined) {
            const [label_offset,] = this.getTextPathOffset();
            this._display.position_offset_label = label_offset;
            this.value_label_position = 'dragged';
        }
    }
    /**
     * Function triggered when we move the node name label when it follow the link path, it update relative node position & redraw the name slabel
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,Class_LinkElement,Class_LinkElement>} event
     * @memberof Class_LinkElement
     */
    dragTextPathMove(event) {
        this._display.position_offset_label = ((this._display.position_offset_label !== undefined) ? this._display.position_offset_label : 0) + event.dx;
        if (this._display.position_offset_label < 0)
            this._display.position_offset_label = 0;
        else if (this._display.position_offset_label > 100)
            this._display.position_offset_label = 100;
        this.updateTextPathOffset();
    }
    dragTextPathEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    //================= Functions for link label if it is a simple text  =================
    /**
     * Set the position of the label of the link when it doesn't follow the path
     *
     * @private
     * @memberof Class_LinkElement
     */
    updateTextXYPosition() {
        var _a, _b, _c;
        const [label_pos, label_ortho_pos, label_anchor] = this.getTextXYPos();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_label_text').attr('y', label_ortho_pos);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_label_text').attr('x', label_pos);
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_label_text').attr('text-anchor', label_anchor);
    }
    /**
     * Return position value of the label when it doesn't follow the link path,
     * return [pos_x,pos_y,text-anchor]
     *
     * @private
     * @return {*}  {[number, number, string]}
     * @memberof Class_LinkElement
     */
    getTextXYPos() {
        // Initialize value as if it link attributes were :
        // - value_label_position : 'start'
        // - value_label_orthogonal_position : 'above'
        let label_ortho_pos = this.position_y_start;
        let label_pos = this.position_x_start;
        let label_anchor = 'start';
        // The process of the y position of the label depend of the x position :
        // - if the label is at the start of the link path then we take position_y_start as the reference
        // - if the label is at the middle of the link path then we take the center point as the reference
        // - if the label is at the middle of the link path then we take the position_y_end as the reference
        if (this._display.position_x_label !== undefined) { //dragged
            label_pos = this._display.position_x_label;
        }
        else {
            if (this.value_label_position === 'middle') {
                label_anchor = 'middle';
                label_pos = (this._control_points.starting_bezier_point.position_x + this._control_points.ending_bezier_point.position_x) / 2;
                label_ortho_pos = (this._control_points.starting_bezier_point.position_y + this._control_points.ending_bezier_point.position_y) / 2;
            }
            else if (this.value_label_position === 'end') {
                label_anchor = 'end';
                label_pos = this.position_x_end;
                label_ortho_pos = this.position_y_end;
            }
        }
        if (this._display.position_y_label !== undefined) { //dragged
            label_ortho_pos = this._display.position_y_label;
        }
        else {
            // Then we apply a relative vertical shift depending of the value_label_orthogonal_position
            if (this.value_label_orthogonal_position === 'above') {
                label_ortho_pos -= (this.value_label_font_size / 2);
            }
            else if (this.value_label_orthogonal_position === 'middle') {
                label_ortho_pos += (this.value_label_font_size / 3);
            }
            else if (this.value_label_orthogonal_position === 'below') {
                label_ortho_pos += this.value_label_font_size;
            }
        }
        return [label_pos, label_ortho_pos, label_anchor];
    }
    /**
     * Function triggered when we start dragging node name label, it initialise relative position if undefined
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextElement,Class_LinkElement,Class_LinkElement>} event
     * @memberof Class_LinkElement
     */
    dragTextStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        const [label_pos, label_ortho_pos,] = this.getTextXYPos();
        if (this._display.position_x_label === undefined) {
            this._display.position_x_label = label_pos;
            this.value_label_position = 'dragged';
        }
        if (this._display.position_y_label === undefined) {
            this._display.position_y_label = label_ortho_pos;
            this.value_label_orthogonal_position = 'dragged';
        }
    }
    /**
     * Function triggered when we move the node name label, it update relative node position & redraw the name slabel
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextElement,Class_LinkElement,Class_LinkElement>} event
     * @memberof Class_LinkElement
     */
    dragTextMove(event) {
        this._display.position_x_label = ((this._display.position_x_label !== undefined) ? this._display.position_x_label : 0) + event.dx;
        this._display.position_y_label = ((this._display.position_y_label !== undefined) ? this._display.position_y_label : 0) + event.dy;
        this.updateTextXYPosition();
    }
    dragTextEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    /**
     * Display the tooltip on drawing area
     *
     * @private
     * @memberof Class_LinkElement
     */
    drawTooltip() {
        // Clean previous label
        d3.selectAll('.sankey-tooltip').remove();
        d3.select('body')
            .append('div')
            .attr('class', 'sankey-tooltip')
            .style('opacity', 1)
            .style('top', (this.source.position_y + this.target.position_y) / 2 + 'px')
            .style('left', (this.source.position_x + this.target.position_x) / 2 + 'px')
            .html(this.tooltip_html);
    }
    drawControlPoint() {
        var _a, _b;
        // Draw control handler
        this._control_points.starting_curve_point.draw();
        this._control_points.ending_curve_point.draw();
        this._control_points.starting_bezier_point.draw();
        this._control_points.ending_bezier_point.draw();
        // Recyling handler
        if (this.shape_is_recycling)
            this._control_points.middle_recycling_point.setVisible();
        else
            this._control_points.middle_recycling_point.setInvisible();
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_control_path').remove();
        if (this._control_points.is_dragged) {
            // Get control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x5 = this._control_points.ending_curve_point.position_x;
            const y5 = this._control_points.ending_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            const x4 = this._control_points.ending_bezier_point.position_x;
            const y4 = this._control_points.ending_bezier_point.position_y;
            // Compute path
            let path;
            // Normal mode
            if (!this.shape_is_recycling) {
                path = 'M ' + x1 + ',' + y1
                    + ' L ' + x2 + ',' + y2
                    + ' L ' + x4 + ',' + y4
                    + ' L ' + x5 + ',' + y5;
            }
            else {
                const xmid = this._control_points.middle_recycling_point.position_x;
                const ymid = this._control_points.middle_recycling_point.position_y;
                if (this.is_horizontal)
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + x2 + ',' + ymid
                        + ' L ' + x4 + ',' + ymid
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
                else if (this.is_vertical)
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + xmid + ',' + y2
                        + ' L ' + xmid + ',' + y4
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
                else
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + xmid + ',' + ymid
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
            }
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('path').classed('link', true).classed('link_control_path', true).attr('d', path).attr('fill', 'none').attr('stroke', 'red').attr('stroke-opacity', 0.75).attr('stroke-width', 1);
        }
    }
    /**
     * Return a svg path for link path drawing
     * @private
     * @return {*}
     * @memberof Class_LinkElement
     */
    getBezierPath() {
        // Update control points
        this.computeControlPoints();
        // Normal mode
        if (!this.shape_is_recycling) {
            // Get starting and ending position per type of shape
            const x0 = this.position_x_start; // Shorter to write
            const y0 = this.position_y_start; // ...
            const x6 = this.position_x_end;
            const y6 = this.position_y_end;
            // Get control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            const x4 = this._control_points.ending_bezier_point.position_x;
            const y4 = this._control_points.ending_bezier_point.position_y;
            const x5 = this._control_points.ending_curve_point.position_x;
            const y5 = this._control_points.ending_curve_point.position_y;
            // Center point
            const x3 = (x2 + x4) / 2;
            const y3 = (y2 + y4) / 2;
            // Return paths
            if (!this.shape_is_curved) {
                return 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' L ' + x5 + ',' + y5
                    + ' L ' + x6 + ',' + y6;
            }
            else {
                return 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' Q ' + x2 + ',' + y2 + ' ' + x3 + ',' + y3
                    + ' Q ' + x4 + ',' + y4 + ' ' + x5 + ',' + y5
                    + ' L ' + x6 + ',' + y6;
            }
        }
        // Recycling mode
        else {
            // Get starting and ending position per type of shape
            const x0 = this.position_x_start; // Shorter to write
            const y0 = this.position_y_start; // ...
            const xf = this.position_x_end;
            const yf = this.position_y_end;
            // Get middle point coordinates
            const x_mid = this._control_points.middle_recycling_point.position_x;
            const y_mid = this._control_points.middle_recycling_point.position_y;
            // Get starting control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            // First curve
            let x3, y3;
            let x4, y4;
            let x5, y5;
            if (this.is_horizontal) {
                x4 = x2;
                y4 = y_mid;
                x3 = x4;
                y3 = (y4 + y2) / 2;
                x5 = x1;
                y5 = y4;
            }
            else if (this.is_vertical) {
                x4 = x_mid;
                y4 = y2;
                x3 = (x4 + x2) / 2;
                y3 = y4;
                x5 = x4;
                y5 = y1;
            }
            else {
                x4 = x_mid;
                y4 = y_mid;
                x3 = (x4 + x2) / 2;
                y3 = (y4 + y2) / 2;
            }
            // Get ending control points coordinates
            const x9 = this._control_points.ending_bezier_point.position_x;
            const y9 = this._control_points.ending_bezier_point.position_y;
            const x10 = this._control_points.ending_curve_point.position_x;
            const y10 = this._control_points.ending_curve_point.position_y;
            // End curve
            let x6, y6;
            let x7, y7;
            let x8, y8;
            if (this.is_horizontal) {
                x7 = x9;
                y7 = y_mid;
                x8 = x9;
                y8 = (y7 + y9) / 2;
                x6 = x10;
                y6 = y7;
            }
            else if (this.is_vertical) {
                x7 = x_mid;
                y7 = y9;
                x8 = (x7 + x9) / 2;
                y8 = y7;
                x6 = x7;
                y6 = y10;
            }
            else {
                x7 = x_mid;
                y7 = y_mid;
                x8 = (x7 + x9) / 2;
                y8 = (y7 + y9) / 2;
            }
            // Return paths
            if (!this.shape_is_curved) {
                let path = 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' L ' + x2 + ',' + y2
                    + ' L ' + x3 + ',' + y3;
                if (this.is_vertical || this.is_horizontal)
                    path = path
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5
                        + ' L ' + x5 + ',' + y5
                        + ' L ' + x6 + ',' + y6;
                path = path
                    + ' L ' + x7 + ',' + y7
                    + ' L ' + x8 + ',' + y8
                    + ' L ' + x9 + ',' + y9
                    + ' L ' + x10 + ',' + y10
                    + ' L ' + xf + ',' + yf;
                return path;
            }
            else {
                let path = 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' Q ' + x2 + ',' + y2 + ' ' + x3 + ',' + y3;
                if (this.is_vertical || this.is_horizontal)
                    path = path
                        + ' Q ' + x4 + ',' + y4 + ' ' + x5 + ',' + y5
                        + ' L ' + x6 + ',' + y6;
                path = path
                    + ' Q ' + x7 + ',' + y7 + ' ' + x8 + ',' + y8
                    + ' Q ' + x9 + ',' + y9 + ' ' + x10 + ',' + y10
                    + ' L ' + xf + ',' + yf;
                return path;
            }
        }
    }
    getLabelToDisplay() {
        // Get raw value // data tags selected
        const value = this.value;
        let text_value = value === null || value === void 0 ? void 0 : value.text_value;
        let data_value = value === null || value === void 0 ? void 0 : value.data_value;
        // If present, text value is prioritaire
        if (text_value) {
            return text_value;
        }
        // Value can be null if not specified by user
        if (data_value) {
            text_value = data_value.toString();
            // Do we need to keep only N significant numbers ?
            if (this.value_label_to_precision && this.value_label_scientific_precision > 0) {
                // 12345.67 avec nb_sign = 4 devient 12340
                text_value = data_value.toPrecision(this.value_label_scientific_precision);
                data_value = parseFloat(text_value);
            }
            //
            // if (this.value_label_to_precision) {
            //   // 12345.67 avec nb_sign = 4 devient 1,234*e+04
            //   text_value = data_value.toPrecision()
            // }
            else if (this.value_label_custom_digit) {
                text_value = data_value.toFixed(this.value_label_nb_digit);
            }
            // Add unit suffix
            if (text_value && this.value_label_unit_visible)
                text_value = text_value + this.value_label_unit;
        }
        // Output
        return text_value;
    }
    // =========== Method about control points ==============
    /**
     * Function used to update starting curve point position value
     *
     * @private
     * @memberof Class_LinkElement
     */
    computeStartingCurvePoint() {
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const x6 = this.position_x_end;
        const y6 = this.position_y_end;
        const starting_shift = this.lenght * this.shape_starting_curve;
        const horizontal_direction = Math.sign(x6 - x0); // +1 / -1
        const vertical_direction = Math.sign(y6 - y0); // +1 / -1
        let x1, y1;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x1 = x0 + horizontal_direction * starting_shift;
                y1 = y0;
            }
            else {
                x1 = x0;
                y1 = y0 + vertical_direction * starting_shift;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x1 = x0 - horizontal_direction * starting_shift;
                y1 = y0;
            }
            else {
                x1 = x0;
                y1 = y0 - vertical_direction * starting_shift;
            }
        }
        this._control_points.starting_curve_point.setPosXY(x1, y1);
    }
    /**
    * Function used to update ending curve point position value
    *
    * @private
    * @memberof Class_LinkElement
    */
    computeEndingCurvePoint() {
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const x6 = this.position_x_end;
        const y6 = this.position_y_end;
        // Shifts
        const ending_shift = this.lenght * (1 - this.shape_ending_curve);
        const horizontal_direction = Math.sign(x6 - x0); // +1 / -1
        const vertical_direction = Math.sign(y6 - y0); // +1 / -1
        let x5, y5;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x5 = x6 - horizontal_direction * ending_shift;
                y5 = y6;
            }
            else {
                x5 = x6;
                y5 = y6 - vertical_direction * ending_shift;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x5 = x6 + horizontal_direction * ending_shift;
                y5 = y6;
            }
            else {
                x5 = x6;
                y5 = y6 + vertical_direction * ending_shift;
            }
        }
        this._control_points.ending_curve_point.setPosXY(x5, y5);
    }
    /**
    * Function used to update starting tangeant point position value
    *
    * @private
    * @memberof Class_LinkElement
    */
    computeStartingBezierPoint() {
        const x1 = this._control_points.starting_curve_point.position_x;
        const y1 = this._control_points.starting_curve_point.position_y;
        const x5 = this._control_points.ending_curve_point.position_x;
        const y5 = this._control_points.ending_curve_point.position_y;
        let x2, y2;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x2 = x1 + (x5 - x1) * this.shape_starting_tangeant;
                y2 = y1;
            }
            else {
                x2 = x1;
                y2 = y1 + (y5 - y1) * this.shape_starting_tangeant;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x2 = x1 - (x5 - x1) * this.shape_starting_tangeant;
                y2 = y1;
            }
            else {
                x2 = x1;
                y2 = y1 - (y5 - y1) * this.shape_starting_tangeant;
            }
        }
        this._control_points.starting_bezier_point.setPosXY(x2, y2);
    }
    /**
    * Function used to update ending tangeant point position value
    *
    * @private
    * @memberof Class_LinkElement
    */
    computeEndingBezierPoint() {
        const x1 = this._control_points.starting_curve_point.position_x;
        const y1 = this._control_points.starting_curve_point.position_y;
        const x5 = this._control_points.ending_curve_point.position_x;
        const y5 = this._control_points.ending_curve_point.position_y;
        let x4, y4;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x4 = x5 + (x1 - x5) * this.shape_ending_tangeant;
                y4 = y5;
            }
            else {
                x4 = x5;
                y4 = y5 + (y1 - y5) * this.shape_ending_tangeant;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x4 = x5 - (x1 - x5) * this.shape_ending_tangeant;
                y4 = y5;
            }
            else {
                x4 = x5;
                y4 = y5 - (y1 - y5) * this.shape_ending_tangeant;
            }
        }
        // Update point
        this._control_points.ending_bezier_point.setPosXY(x4, y4);
    }
    computeMiddleRecyclingPoint() {
        // Get starting & ending position
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const xf = this.position_x_end;
        const yf = this.position_y_end;
        // Compute ref points
        const x_ref = (x0 + xf) / 2;
        const y_ref = (y0 + yf) / 2;
        // Compute point
        let x_mid, y_mid;
        if (this.is_horizontal) {
            x_mid = x_ref;
            y_mid = y_ref + this.shape_middle_recycling;
        }
        else if (this.is_vertical) {
            x_mid = x_ref + this.shape_middle_recycling;
            y_mid = y_ref;
        }
        else {
            const vx = (xf - x0);
            const vy = (yf - y0);
            const vx_ortho = -vy;
            const vy_ortho = vx;
            const d = Math.sqrt(vx * vx + vy * vy);
            const scale_norm = this.shape_middle_recycling / Math.sqrt(2);
            x_mid = x_ref + scale_norm * (vx_ortho / d);
            y_mid = y_ref + scale_norm * (vy_ortho / d);
        }
        // Update point
        this._control_points.middle_recycling_point.setPosXY(x_mid, y_mid);
    }
    computeControlPoints() {
        this.computeStartingCurvePoint();
        this.computeEndingCurvePoint();
        this.computeStartingBezierPoint();
        this.computeEndingBezierPoint();
        if (this.shape_is_recycling)
            this.computeMiddleRecyclingPoint();
    }
    /**
     * Activate the control points alignement guide
     *
     * @private
     * @return {*}
     * @memberof Class_LinkElement
     */
    dragHandleStart() {
        return () => {
            this._control_points.is_dragged = true;
        };
    }
    /**
     * Deactivate the control points alignement guide
     * @private
     * @return {*}
     * @memberof Class_LinkElement
     */
    dragHandleEnd() {
        return () => {
            this._control_points.is_dragged = false;
            this.drawControlPoint();
            this.menu_config.ref_to_menu_config_link_apparence_updater.current();
        };
    }
    /**
     * Function called when we drag the starting curve point, it update variable shape_starting_curve
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof Class_LinkElement
     */
    startCurvePointDragEvent() {
        return (event) => {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.starting_curve_point.position_x + event.dx;
                const x0 = this.position_x_start;
                const x6 = this.position_x_end;
                // Compute starting curve point coef based on new handle pos
                const dx6x0 = Math.abs(x6 - x0);
                if (dx6x0 > 0) // Avoid NaN
                    this.shape_starting_curve = Math.abs(handle_new_pos_x - x0) / dx6x0;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.starting_curve_point.position_y + event.dy;
                const y0 = this.position_y_start;
                const y6 = this.position_y_end;
                // Compute starting curve point coef based on new handle pos
                const dy6y0 = Math.abs(y6 - y0);
                if (dy6y0 > 0) // Avoid NaN
                    this.shape_starting_curve = Math.abs(handle_new_pos_y - y0) / dy6y0;
            }
        };
    }
    /**
     * Function called when we drag the ending curve point, it update variable shape_ending_curve
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof Class_LinkElement
     */
    endCurvePointDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_vertical_horizontal) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.ending_curve_point.position_x + event.dx;
                const x0 = this.position_x_start;
                const x6 = this.position_x_end;
                // Compute ending curve point coef based on new handle pos
                const dx6x0 = Math.abs(x6 - x0);
                if (dx6x0 > 0) // Avoid NaN
                    this.shape_ending_curve = Math.abs(handle_new_pos_x - x0) / dx6x0;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.ending_curve_point.position_y + event.dy;
                const y0 = this.position_y_start;
                const y6 = this.position_y_end;
                // Compute ending curve point coef based on new handle pos
                const dy6y0 = Math.abs(y6 - y0);
                if (dy6y0 > 0) // Avoid NaN
                    this.shape_ending_curve = Math.abs(handle_new_pos_y - y0) / dy6y0;
            }
            this._control_points.is_dragged = false;
        };
    }
    /**
     * Function called when we drag the starting tangeant point, it update variable shape_starting_tangeant
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof Class_LinkElement
     */
    startTangeantDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_horizontal_vertical) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.starting_bezier_point.position_x + event.dx;
                const x1 = this._control_points.starting_curve_point.position_x;
                const x5 = this._control_points.ending_curve_point.position_x;
                // Compute starting tangeant point coef based on new handle pos
                const dx1x5 = Math.abs(x5 - x1);
                if (dx1x5 > 0) // Avoid NaN
                    this.shape_starting_tangeant = Math.abs(handle_new_pos_x - x1) / dx1x5;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.starting_bezier_point.position_y + event.dy;
                const y1 = this._control_points.starting_curve_point.position_y;
                const y5 = this._control_points.ending_curve_point.position_y;
                // Compute starting tangeant point coef based on new handle pos
                const dy1y5 = Math.abs(y5 - y1);
                if (dy1y5 > 0) // Avoid NaN
                    this.shape_starting_tangeant = Math.abs(handle_new_pos_y - y1) / dy1y5;
            }
            this._control_points.is_dragged = false;
        };
    }
    /**
    * Function called when we drag the ending tangeant point, it update variable shape_ending_tangeant
    *
    * @private
    * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
    * @memberof Class_LinkElement
    */
    endTangeantDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_vertical_horizontal) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.ending_bezier_point.position_x + event.dx;
                const x1 = this._control_points.starting_curve_point.position_x;
                const x5 = this._control_points.ending_curve_point.position_x;
                // Compute starting tangeant point coef based on new handle pos
                const dx1x5 = Math.abs(x5 - x1);
                if (dx1x5 > 0) // Avoid NaN
                    this.shape_ending_tangeant = Math.abs(handle_new_pos_x - x5) / dx1x5;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.ending_bezier_point.position_y + event.dy;
                const y1 = this._control_points.starting_curve_point.position_y;
                const y5 = this._control_points.ending_curve_point.position_y;
                // Compute starting tangeant point coef based on new handle pos
                const dy1y5 = Math.abs(y5 - y1);
                if (dy1y5 > 0) // Avoid NaN
                    this.shape_ending_tangeant = Math.abs(handle_new_pos_y - y5) / dy1y5;
            }
            this._control_points.is_dragged = false;
        };
    }
    middleRecyclingDragEvent() {
        return (event) => {
            // Only in recylcing
            if (this.shape_is_recycling) {
                if (this.is_horizontal) {
                    const handle_new_pos_y = this._control_points.middle_recycling_point.position_y + event.dy;
                    const y0 = this.position_y_start;
                    const yf = this.position_y_end;
                    this.shape_middle_recycling = handle_new_pos_y - (y0 + yf) / 2;
                }
                else if (this.is_vertical) {
                    const handle_new_pos_x = this._control_points.middle_recycling_point.position_x + event.dx;
                    const x0 = this.position_x_start;
                    const xf = this.position_x_end;
                    this.shape_middle_recycling = handle_new_pos_x - (x0 + xf) / 2;
                }
                else {
                    // Starting & Ending positions
                    const x0 = this.position_x_start;
                    const xf = this.position_x_end;
                    const y0 = this.position_y_start;
                    const yf = this.position_y_end;
                    // Vector start->end
                    const vx = (xf - x0);
                    const vy = (yf - y0);
                    // Middle recyling is at given distance
                    const sign = Math.sign(vx * event.dy - vy * event.dx); // Produit vectoriel
                    const d = Math.sqrt(event.dx * event.dx + event.dy * event.dy);
                    this.shape_middle_recycling = this.shape_middle_recycling + sign * d;
                }
            }
        };
    }
    // GETTERS / SETTERS ==================================================================
    /**
     * Get name of link
     * @readonly
     * @memberof Class_LinkElement
     */
    get name() {
        return defaultLinkId(this._source, this._target);
    }
    get is_visible() {
        return (this.are_source_and_target_displayed &&
            this.are_related_tags_selected &&
            this.is_value_above_threshold &&
            this._is_visible);
    }
    /**
     * displaying order on drawing area
     * @memberof Class_LinkElement
     */
    get displaying_order() {
        return this._display.displaying_order;
    }
    set displaying_order(_) {
        this._display.displaying_order = _;
    }
    /**
     * Get source node
     * @memberof Class_LinkElement
     */
    get source() {
        return this._source;
    }
    /**
     * set source node
     * @memberof Class_LinkElement
     */
    set source(_) {
        if (this.source !== _) {
            const old_source = this._source;
            this._source = _;
            // Clean old source
            old_source.swapOutputLink(this, _);
            // If we set a source from himself then make the link a recycing one
            if (this.target === this.source) {
                this.shape_is_recycling = true;
            }
        }
    }
    /**
     * Get starting node side for link
     * @readonly
     * @type {Type_Side}
     * @memberof Class_LinkElement
     */
    get source_side() {
        // Failsafe : because of constructor
        if (this.source === undefined || this.target === undefined) {
            return 'right';
        }
        // Normal behavior
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                if (this.source.position_x <= this.target.position_x)
                    return 'right';
                else
                    return 'left';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'bottom';
                else
                    return 'top';
            }
        }
        // Recylcing mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                if (this.source.position_x <= this.target.position_x)
                    return 'left';
                else
                    return 'right';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'top';
                else
                    return 'bottom';
            }
        }
    }
    /**
     * get destination node
     * @memberof Class_LinkElement
     */
    get target() {
        return this._target;
    }
    /**
     * Set destination node
     * @memberof Class_LinkElement
     */
    set target(_) {
        if (this.target !== _) {
            const old_target = this._target;
            this._target = _;
            // Clean old source
            old_target.swapInputLink(this, _);
            // If we set a target to himself then make the link a recycing one
            if (this.target === this.source) {
                this.shape_is_recycling = true;
            }
        }
    }
    /**
     * Get starting node side for link
     * @readonly
     * @type {Type_Side}
     * @memberof Class_LinkElement
     */
    get target_side() {
        // Failsafe : because of constructor
        if (this.source === undefined || this.target === undefined) {
            return 'left';
        }
        // Normal behavior
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                if (this.source.position_x <= this.target.position_x)
                    return 'left';
                else
                    return 'right';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'top';
                else
                    return 'bottom';
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                if (this.source.position_x <= this.target.position_x)
                    return 'right';
                else
                    return 'left';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'bottom';
                else
                    return 'top';
            }
        }
    }
    /**
     * Get value object.
     * Either search correct current value with data_taggs,
     * or return directly the value when there is no data_taggs
     * @readonly
     * @memberof Class_LinkElement
     */
    get value() {
        if (this._values instanceof Class_LinkValue)
            return this._values;
        else
            return this._values.getValueForDataTags(this.main_sankey.selected_data_tags_list);
    }
    /**
     * Either search correct current value with data_taggs,
     *  or return directly the value when there is no data_taggs
     * @memberof Class_LinkElement
     */
    get data_value() {
        const value = this.value;
        // Cast as number
        if (value !== null)
            return value.data_value;
        else
            return null;
    }
    /**
     * Either set correct current value with data_taggs,
     *  or set directly the value when there is no data_taggs
     * @memberof Class_LinkElement
     */
    set data_value(_) {
        const value = this.value;
        // Cast as number
        if (value !== null) {
            value.data_value = _;
            // Need to update and redraw from source and target also
            this.source.updateOutputValue();
            this.target.updateInputValue();
            if (this.source.position_type == 'parametric') {
                // if the positioning mode of source is parametric we need to reposition all nodes below 
                const same_source_u = this.main_sankey.visible_nodes_list.filter(n => n.position_u == this.source.position_u && n.position_v > this.source.position_v);
                same_source_u.forEach(n => n.draw());
            }
            if (this.target.position_type == 'parametric') {
                // if the positioning mode of target is parametric we need to reposition all nodes below 
                const same_target_u = this.main_sankey.visible_nodes_list.filter(n => n.position_u == this.target.position_u && n.position_v > this.target.position_v);
                same_target_u.forEach(n => n.draw());
            }
        }
    }
    /**
     * Either search correct current value with data_taggs,
     *  or return directly the value when there is no data_taggs
     * @return string
     * @memberof Class_LinkElement
     */
    get text_value() {
        const value = this.value;
        // Cast as string
        if (value !== null && value.text_value !== null)
            return value.text_value;
        else
            return '';
    }
    /**
     * Either set correct current value with data_taggs,
     *  or set directly the value when there is no data_taggs
     * @memberof Class_LinkElement
     */
    set text_value(_) {
        const value = this.value;
        // Cast as number
        if (value !== null) {
            value.text_value = _;
            this.drawLabel();
        }
    }
    get data_label() {
        // Init
        let data_value = this.data_value;
        let text_value = '-';
        // Create data label
        if (data_value) {
            // Do we need to keep only N significant numbers ?
            if (this.value_label_scientific_precision > 0) {
                // 12345.67 avec nb_sign = 4 devient 12340
                text_value = data_value.toPrecision(this.value_label_scientific_precision);
                data_value = parseFloat(text_value);
            }
            // Convert
            if (this.value_label_to_precision) {
                // 12345.67 avec nb_sign = 4 devient 1,234*e+04
                text_value = data_value.toPrecision();
            }
            else if (this.value_label_custom_digit) {
                text_value = data_value.toFixed(this.value_label_nb_digit);
            }
            else {
                text_value = String(data_value);
            }
            // Add unit suffix
            if (text_value && this.value_label_unit_visible)
                text_value = text_value + this.value_label_unit;
        }
        return text_value;
    }
    /**
     * Dict as [id: tag] of tags related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_tags_dict() {
        const value = this.value;
        if (value)
            return this.value.flux_tags_dict;
        return {};
    }
    /**
     * Array of tags related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_tags_list() {
        const value = this.value;
        if (value)
            return this.value.flux_tags_list;
        return [];
    }
    /**
     * Dict as [id: tag group] of tag groups related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_taggs_dict() {
        const value = this.value;
        if (value)
            return this.value.flux_taggs_dict;
        return {};
    }
    /**
     * Array of tag groups related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_taggs_list() {
        return Object.values(this.flux_taggs_dict);
    }
    /**
     * Set tooltip text
     * @memberof Class_LinkElement
     */
    get tooltip_text() { return this._tooltip_text; }
    /**
     * Get tooltip text
     * @memberof Class_LinkElement
     */
    set tooltip_text(_) {
        this._tooltip_text = _;
        // TODO redraw ?
    }
    /**
     * Get style key of node
     * @return {string}
     * @memberof Class_Node
     */
    get style() {
        return this._display.style;
    }
    /**
    * Set style key of node
    * @memberof Class_Node
    */
    set style(_) {
        this._display.style.removeReference(this);
        this._display.style = _;
        _.addReference(this);
        this.drawElements();
    }
    /**
     * Get thickness of stroke shape
     * @readonly
     * @memberof Class_LinkElement
     */
    get thickness() {
        // Get link value for current dataTaggs selected
        const data_value = this.data_value;
        // Scale this value for the drawing area
        const linkValueInPx = this.drawing_area.scaleValueToPx((data_value !== null) ? data_value : 1);
        // If link processed size is inferior to min. limit return min. limit
        if (this.drawing_area.minimum_flux && linkValueInPx < this.drawing_area.minimum_flux) {
            return this.drawing_area.minimum_flux;
        }
        // If link processed size is superior to max. limit return max. limit
        if (this.drawing_area.maximum_flux && linkValueInPx > this.drawing_area.maximum_flux) {
            return this.drawing_area.maximum_flux;
        }
        return linkValueInPx;
    }
    get position_x_start() {
        return this._display.position_starting.x;
    }
    get position_y_start() {
        return this._display.position_starting.y;
    }
    get position_x_end() {
        // If we draw an arrow for the link then we need to create a space between the node and the end of the link path (this space correspond to the size of the arrow)
        let shifting_end_point_x = 0;
        if (this.shape_is_arrow) {
            const is_horizontal_at_target = this.is_horizontal || this.is_vertical_horizontal;
            const is_revert = (is_horizontal_at_target && this.target_side == 'right') || (!is_horizontal_at_target && this.target_side == 'bottom');
            const sign_shifting_end_point = (is_revert) ? -1 : 1;
            shifting_end_point_x = (this.is_horizontal || this.is_vertical_horizontal) ? this.shape_arrow_size * sign_shifting_end_point : 0;
        }
        return this._display.position_ending.x - shifting_end_point_x;
    }
    get position_y_end() {
        // If we draw an arrow for the link then we need to create a space between the node and the end of the link path (this space correspond to the size of the arrow)
        let shifting_end_point_y = 0;
        if (this.shape_is_arrow) {
            const is_horizontal_at_target = this.is_horizontal || this.is_vertical_horizontal;
            const is_revert = (is_horizontal_at_target && this.target_side == 'right') || (!is_horizontal_at_target && this.target_side == 'bottom');
            const sign_shifting_end_point = (is_revert) ? -1 : 1;
            shifting_end_point_y = (this.is_vertical || this.is_horizontal_vertical) ? this.shape_arrow_size * sign_shifting_end_point : 0;
        }
        return this._display.position_ending.y - shifting_end_point_y;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_orientation() {
        if (this._display.attributes.shape_orientation !== undefined) {
            return this._display.attributes.shape_orientation;
        }
        else if (this._display.style.shape_orientation !== undefined) {
            return this._display.style.shape_orientation;
        }
        return default_shape_orientation;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_orientation(_) {
        this._display.attributes.shape_orientation = _;
        // Need to redraw from nodes
        this.drawWithNodes();
    }
    // Orientation
    get is_horizontal() { return this.shape_orientation === 'hh'; }
    get is_vertical() { return this.shape_orientation === 'vv'; }
    get is_horizontal_vertical() { return this.shape_orientation === 'hv'; }
    get is_vertical_horizontal() { return this.shape_orientation === 'vh'; }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_starting_curve() {
        if (this._display.attributes.shape_starting_curve !== undefined) {
            return this._display.attributes.shape_starting_curve;
        }
        else if (this._display.style.shape_starting_curve !== undefined) {
            return this._display.style.shape_starting_curve;
        }
        return default_shape_starting_curve;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_starting_curve(_) {
        if (_ >= 0 && _ < this.shape_ending_curve) {
            this._display.attributes.shape_starting_curve = _;
            this.drawPath();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_ending_curve() {
        if (this._display.attributes.shape_ending_curve !== undefined) {
            return this._display.attributes.shape_ending_curve;
        }
        else if (this._display.style.shape_ending_curve !== undefined) {
            return this._display.style.shape_ending_curve;
        }
        return default_shape_ending_curve;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_ending_curve(_) {
        if (_ <= 1 && _ > this.shape_starting_curve) {
            this._display.attributes.shape_ending_curve = _;
            this.drawPath();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_starting_tangeant() {
        if (this._display.attributes.shape_starting_tangeant !== undefined) {
            return this._display.attributes.shape_starting_tangeant;
        }
        else if (this._display.style.shape_starting_tangeant !== undefined) {
            return this._display.style.shape_starting_tangeant;
        }
        return default_shape_starting_tangeant;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_starting_tangeant(_) {
        if (_ > 0) {
            this._display.attributes.shape_starting_tangeant = _;
            this.drawPath();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_ending_tangeant() {
        if (this._display.attributes.shape_ending_tangeant !== undefined) {
            return this._display.attributes.shape_ending_tangeant;
        }
        else if (this._display.style.shape_ending_tangeant !== undefined) {
            return this._display.style.shape_ending_tangeant;
        }
        return default_shape_ending_tangeant;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_ending_tangeant(_) {
        if (_ > 0) {
            this._display.attributes.shape_ending_tangeant = _;
            this.drawPath();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_middle_recycling() {
        if (this._display.attributes.shape_middle_recycling !== undefined) {
            return this._display.attributes.shape_middle_recycling;
        }
        else if (this._display.style.shape_middle_recycling !== undefined) {
            return this._display.style.shape_middle_recycling;
        }
        return default_shape_middle_recyling;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_middle_recycling(_) {
        this._display.attributes.shape_middle_recycling = _;
        this.drawPath();
        this.drawControlPoint();
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_vert_shift() {
        if (this._display.attributes.shape_vert_shift !== undefined) {
            return this._display.attributes.shape_vert_shift;
        }
        else if (this._display.style.shape_vert_shift !== undefined) {
            return this._display.style.shape_vert_shift;
        }
        return default_shape_vert_shift;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_vert_shift(_) { this._display.attributes.shape_vert_shift = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_curvature() {
        if (this._display.attributes.shape_curvature !== undefined) {
            return this._display.attributes.shape_curvature;
        }
        else if (this._display.style.shape_curvature !== undefined) {
            return this._display.style.shape_curvature;
        }
        return default_shape_curvature;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_curvature(_) { this._display.attributes.shape_curvature = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_is_curved() {
        if (this._display.attributes.shape_is_curved !== undefined) {
            return this._display.attributes.shape_is_curved;
        }
        else if (this._display.style.shape_is_curved !== undefined) {
            return this._display.style.shape_is_curved;
        }
        return default_shape_is_curved;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_is_curved(_) { this._display.attributes.shape_is_curved = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_is_recycling() {
        if (this._display.attributes.shape_is_recycling !== undefined) {
            return this._display.attributes.shape_is_recycling;
        }
        else if (this._display.style.shape_is_recycling !== undefined) {
            return this._display.style.shape_is_recycling;
        }
        return default_shape_is_recycling;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_is_recycling(_) {
        this._display.attributes.shape_is_recycling = _;
        // Need to redraw from nodes
        this.drawWithNodes();
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_arrow_size() {
        if (this._display.attributes.shape_arrow_size !== undefined) {
            return this._display.attributes.shape_arrow_size;
        }
        else if (this._display.style.shape_arrow_size !== undefined) {
            return this._display.style.shape_arrow_size;
        }
        return default_shape_arrow_size;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_arrow_size(_) { this._display.attributes.shape_arrow_size = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_position() {
        if (this._display.attributes.value_label_position !== undefined) {
            return this._display.attributes.value_label_position;
        }
        else if (this._display.style.value_label_position !== undefined) {
            return this._display.style.value_label_position;
        }
        return default_value_label_position;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_position(_) {
        if (_ !== 'dragged')
            delete this._display.position_offset_label;
        this._display.attributes.value_label_position = _;
        this.drawLabel();
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_orthogonal_position() {
        if (this._display.attributes.value_label_orthogonal_position !== undefined) {
            return this._display.attributes.value_label_orthogonal_position;
        }
        else if (this._display.style.value_label_orthogonal_position !== undefined) {
            return this._display.style.value_label_orthogonal_position;
        }
        return default_value_label_orthogonal_position;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_orthogonal_position(_) {
        this._display.attributes.value_label_orthogonal_position = _;
        this.drawLabel();
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_on_path() {
        if (this._display.attributes.value_label_on_path !== undefined) {
            return this._display.attributes.value_label_on_path;
        }
        else if (this._display.style.value_label_on_path !== undefined) {
            return this._display.style.value_label_on_path;
        }
        return default_value_label_on_path;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_on_path(_) { this._display.attributes.value_label_on_path = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_pos_auto() {
        if (this._display.attributes.value_label_pos_auto !== undefined) {
            return this._display.attributes.value_label_pos_auto;
        }
        else if (this._display.style.value_label_pos_auto !== undefined) {
            return this._display.style.value_label_pos_auto;
        }
        return default_value_label_pos_auto;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_pos_auto(_) { this._display.attributes.value_label_pos_auto = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_is_arrow() {
        if (this._display.attributes.shape_is_arrow !== undefined) {
            return this._display.attributes.shape_is_arrow;
        }
        else if (this._display.style.shape_is_arrow !== undefined) {
            return this._display.style.shape_is_arrow;
        }
        return default_shape_is_arrow;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_is_arrow(_) { this._display.attributes.shape_is_arrow = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_color() {
        if (this._display.attributes.shape_color !== undefined) {
            return this._display.attributes.shape_color;
        }
        else if (this._display.style.shape_color !== undefined) {
            return this._display.style.shape_color;
        }
        return default_shape_color;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_color(_) { this._display.attributes.shape_color = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_opacity() {
        if (this._display.attributes.shape_opacity !== undefined) {
            return this._display.attributes.shape_opacity;
        }
        else if (this._display.style.shape_opacity !== undefined) {
            return this._display.style.shape_opacity;
        }
        return default_shape_opacity;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_opacity(_) { this._display.attributes.shape_opacity = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get shape_is_dashed() {
        if (this._display.attributes.shape_is_dashed !== undefined) {
            return this._display.attributes.shape_is_dashed;
        }
        else if (this._display.style.shape_is_dashed !== undefined) {
            return this._display.style.shape_is_dashed;
        }
        return default_shape_is_dashed;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set shape_is_dashed(_) { this._display.attributes.shape_is_dashed = _; this.drawPath(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_is_visible() {
        if (this._display.attributes.value_label_is_visible !== undefined) {
            return this._display.attributes.value_label_is_visible;
        }
        else if (this._display.style.value_label_is_visible !== undefined) {
            return this._display.style.value_label_is_visible;
        }
        return default_value_label_is_visible;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_is_visible(_) { this._display.attributes.value_label_is_visible = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_font_size() {
        if (this._display.attributes.value_label_font_size !== undefined) {
            return this._display.attributes.value_label_font_size;
        }
        else if (this._display.style.value_label_font_size !== undefined) {
            return this._display.style.value_label_font_size;
        }
        return default_value_label_font_size;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_font_size(_) { this._display.attributes.value_label_font_size = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_color() {
        if (this._display.attributes.value_label_color !== undefined) {
            return this._display.attributes.value_label_color;
        }
        else if (this._display.style.value_label_color !== undefined) {
            return this._display.style.value_label_color;
        }
        return default_value_label_color;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_color(_) { this._display.attributes.value_label_color = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_to_precision() {
        if (this._display.attributes.value_label_to_precision !== undefined) {
            return this._display.attributes.value_label_to_precision;
        }
        else if (this._display.style.value_label_to_precision !== undefined) {
            return this._display.style.value_label_to_precision;
        }
        return default_value_label_to_precision;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_to_precision(_) { this._display.attributes.value_label_to_precision = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_scientific_precision() {
        if (this._display.attributes.value_label_scientific_precision !== undefined) {
            return this._display.attributes.value_label_scientific_precision;
        }
        else if (this._display.style.value_label_scientific_precision !== undefined) {
            return this._display.style.value_label_scientific_precision;
        }
        return default_value_label_scientific_precision;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_scientific_precision(_) { this._display.attributes.value_label_scientific_precision = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_font_family() {
        if (this._display.attributes.value_label_font_family !== undefined) {
            return this._display.attributes.value_label_font_family;
        }
        else if (this._display.style.value_label_font_family !== undefined) {
            return this._display.style.value_label_font_family;
        }
        return default_value_label_font_family;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_font_family(_) { this._display.attributes.value_label_font_family = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_unit_visible() {
        if (this._display.attributes.value_label_unit_visible !== undefined) {
            return this._display.attributes.value_label_unit_visible;
        }
        else if (this._display.style.value_label_unit_visible !== undefined) {
            return this._display.style.value_label_unit_visible;
        }
        return default_value_label_unit_visible;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_unit_visible(_) { this._display.attributes.value_label_unit_visible = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_unit() {
        if (this._display.attributes.value_label_unit !== undefined) {
            return this._display.attributes.value_label_unit;
        }
        else if (this._display.style.value_label_unit !== undefined) {
            return this._display.style.value_label_unit;
        }
        return default_value_label_unit;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_unit(_) { this._display.attributes.value_label_unit = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_custom_digit() {
        if (this._display.attributes.value_label_custom_digit !== undefined) {
            return this._display.attributes.value_label_custom_digit;
        }
        else if (this._display.style.value_label_custom_digit !== undefined) {
            return this._display.style.value_label_custom_digit;
        }
        return default_value_label_custom_digit;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_custom_digit(_) { this._display.attributes.value_label_custom_digit = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    get value_label_nb_digit() {
        if (this._display.attributes.value_label_nb_digit !== undefined) {
            return this._display.attributes.value_label_nb_digit;
        }
        else if (this._display.style.value_label_nb_digit !== undefined) {
            return this._display.style.value_label_nb_digit;
        }
        return default_value_label_nb_digit;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_nb_digit(_) { this._display.attributes.value_label_nb_digit = _; this.drawLabel(); }
    // PRIVATE GETTER / SETTER =============================================================
    /**
     * Compute lenght of link
     * @memberof Class_LinkElement
     */
    get lenght() {
        if (this.is_vertical) {
            return Math.abs(this.position_y_start - this.position_y_end);
        }
        else if (this.is_horizontal) {
            return Math.abs(this.position_x_start - this.position_x_end);
        }
        else {
            return (Math.abs(this.position_x_start - this.position_x_end) +
                Math.abs(this.position_y_start - this.position_y_end));
        }
    }
    /**
     * If link has tags :
     * - check if any of them is selected at false
     * else if the link doesn't have tag it isn't filtered by them
     * @readonly
     * @private
     * @memberof Class_LinkElement
     */
    get are_related_tags_selected() {
        return this.flux_tags_list.filter(t => !t.is_selected).length === 0;
    }
    /**
   * If drawing area has filter_link_value above 0:
   * - check if the link value is superior to the filter
   *   if not don't display the link
   * @readonly
   * @private
   * @memberof Class_LinkElement
   */
    get is_value_above_threshold() {
        if (this.drawing_area.filter_link_value == 0) {
            return true;
        }
        else {
            return Number(this.data_value) >= this.drawing_area.filter_link_value;
        }
    }
    /**
     * Check if node source and node target are displayed,
     * if one of them is not then we don't display the link
     *
     * @private
     * @return {*}
     * @memberof Class_LinkElement
     */
    get are_source_and_target_displayed() {
        var _a, _b, _c, _d;
        return (((_b = (_a = this._source) === null || _a === void 0 ? void 0 : _a.is_visible) !== null && _b !== void 0 ? _b : false) &&
            ((_d = (_c = this._target) === null || _c === void 0 ? void 0 : _c.is_visible) !== null && _d !== void 0 ? _d : false));
    }
    get tooltip_html() {
        // Title
        let tooltip_html = '<p class="title" style="margin-bottom: 5px;">' +
            this.source.name.split('\\n').join(' ') +
            ' → ' +
            this.target.name.split('\\n').join(' ') +
            '</p>';
        // Subtitle
        if (this.tooltip_text) {
            tooltip_html += '<p class="subtitle" style="	margin-bottom: 5px;">' +
                this.tooltip_text.split('\n').join('</br>') +
                '</p>';
        }
        // Create table
        tooltip_html += '<div style="padding-left :5px;padding-right :5px">';
        tooltip_html += '<table class="table" style="margin-bottom: 5px;">';
        tooltip_html += '  <tbody>';
        // Show data
        tooltip_html += '    <tr>';
        tooltip_html += '      <th>' + 'Valeurs' + '</th>'; // TODO traduction
        tooltip_html += '      <td>' + this.data_label + '</td>';
        tooltip_html += '    </tr>';
        // Show flux tags
        const flux_tags = this.flux_tags_list; // avoid hidden recomputing
        this.flux_taggs_list
            .forEach(tagg => {
            const flux_tags_names = flux_tags
                .filter(tag => tag.group === tagg)
                .map(tag => tag.name);
            tooltip_html += '    <tr>';
            tooltip_html += '      <th> ' + tagg.name + ' </th>';
            tooltip_html += '      <td>' + flux_tags_names.join() + '</td>';
            tooltip_html += '    </tr>';
        });
        tooltip_html += '  </tbody>';
        tooltip_html += '</table>';
        tooltip_html += '</div>';
        return tooltip_html;
    }
}
// CLASS LINK ATTRIBUTES ****************************************************************
/**
 * Define all attributes that can be applyied to a link
 *
 * @export
 * @class Class_LinkAttribute
 */
export class Class_LinkAttribute {
    // CONSTRUCTOR ========================================================================
    constructor() { }
    // PUBLIC METHODES ====================================================================
    toJSON() {
        const json_object = {};
        // Geometry link
        if (this._shape_orientation !== undefined)
            json_object['orientation'] = this._shape_orientation;
        if (this._shape_starting_curve !== undefined)
            json_object['left_horiz_shift'] = this._shape_starting_curve;
        if (this._shape_ending_curve !== undefined)
            json_object['right_horiz_shift'] = this._shape_ending_curve;
        if (this._shape_vert_shift !== undefined)
            json_object['vert_shift'] = this._shape_vert_shift;
        if (this._shape_curvature !== undefined)
            json_object['curvature'] = this._shape_curvature;
        if (this._shape_is_curved !== undefined)
            json_object['curved'] = this._shape_is_curved;
        if (this._shape_is_recycling !== undefined)
            json_object['recycling'] = this._shape_is_recycling;
        if (this._shape_arrow_size !== undefined)
            json_object['arrow_size'] = this._shape_arrow_size;
        // Geometry link labels
        if (this._value_label_position !== undefined)
            json_object['label_position'] = this._value_label_position;
        if (this._value_label_orthogonal_position !== undefined)
            json_object['orthogonal_label_position'] = this._value_label_orthogonal_position;
        if (this._value_label_on_path !== undefined)
            json_object['label_on_path'] = this._value_label_on_path;
        if (this._value_label_pos_auto !== undefined)
            json_object['label_pos_auto'] = this._value_label_pos_auto;
        //Attributes link
        if (this._shape_is_arrow !== undefined)
            json_object['arrow'] = this._shape_is_arrow;
        if (this._shape_color !== undefined)
            json_object['color'] = this._shape_color;
        if (this._shape_opacity !== undefined)
            json_object['opacity'] = this._shape_opacity;
        if (this._shape_is_dashed !== undefined)
            json_object['dashed'] = this._shape_is_dashed;
        //Attributes link labels
        if (this._value_label_is_visible !== undefined)
            json_object['label_visible'] = this._value_label_is_visible;
        if (this._value_label_font_size !== undefined)
            json_object['label_font_size'] = this._value_label_font_size;
        if (this._value_label_color !== undefined)
            json_object['text_color'] = this._value_label_color;
        if (this._value_label_to_precision !== undefined)
            json_object['to_precision'] = this._value_label_to_precision;
        if (this._value_label_scientific_precision !== undefined)
            json_object['scientific_precision'] = this._value_label_scientific_precision;
        if (this._value_label_font_family !== undefined)
            json_object['font_family'] = this._value_label_font_family;
        if (this._value_label_unit_visible !== undefined)
            json_object['label_unit_visible'] = this._value_label_unit_visible;
        if (this._value_label_unit !== undefined)
            json_object['label_unit'] = this._value_label_unit;
        if (this._value_label_custom_digit !== undefined)
            json_object['custom_digit'] = this._value_label_custom_digit;
        if (this._value_label_nb_digit !== undefined)
            json_object['nb_digit'] = this._value_label_nb_digit;
        return json_object;
    }
    fromJSON(json_local_object) {
        // Geometry link
        if (json_local_object['orientation'] !== undefined)
            this._shape_orientation = getStringFromJSON(json_local_object, 'orientation', default_shape_orientation);
        if (json_local_object['left_horiz_shift'] !== undefined)
            this._shape_starting_curve = getNumberFromJSON(json_local_object, 'left_horiz_shift', default_shape_starting_curve);
        if (json_local_object['right_horiz_shift'] !== undefined)
            this._shape_ending_curve = getNumberFromJSON(json_local_object, 'right_horiz_shift', default_shape_ending_curve);
        if (json_local_object['vert_shift'] !== undefined)
            this._shape_vert_shift = getNumberFromJSON(json_local_object, 'vert_shift', default_shape_vert_shift);
        if (json_local_object['curvature'] !== undefined)
            this._shape_curvature = getNumberFromJSON(json_local_object, 'curvature', default_shape_curvature);
        if (json_local_object['curved'] !== undefined)
            this._shape_is_curved = getBooleanFromJSON(json_local_object, 'curved', default_shape_is_curved);
        if (json_local_object['recycling'] !== undefined)
            this._shape_is_recycling = getBooleanFromJSON(json_local_object, 'recycling', default_shape_is_recycling);
        if (json_local_object['arrow_size'] !== undefined)
            this._shape_arrow_size = getNumberFromJSON(json_local_object, 'arrow_size', default_shape_arrow_size);
        // Geometry link labels
        if (json_local_object['label_position'] !== undefined)
            this._value_label_position = getStringFromJSON(json_local_object, 'label_position', default_value_label_position);
        if (json_local_object['orthogonal_label_position'] !== undefined)
            this._value_label_orthogonal_position = getStringFromJSON(json_local_object, 'orthogonal_label_position', default_value_label_orthogonal_position);
        if (json_local_object['label_on_path'] !== undefined)
            this._value_label_on_path = getBooleanFromJSON(json_local_object, 'label_on_path', default_value_label_on_path);
        if (json_local_object['label_pos_auto'] !== undefined)
            this._value_label_pos_auto = getBooleanFromJSON(json_local_object, 'label_pos_auto', default_value_label_pos_auto);
        //Attributes link
        if (json_local_object['arrow'] !== undefined)
            this._shape_is_arrow = getBooleanFromJSON(json_local_object, 'arrow', default_shape_is_arrow);
        if (json_local_object['color'] !== undefined)
            this._shape_color = getStringFromJSON(json_local_object, 'color', default_shape_color);
        if (json_local_object['opacity'] !== undefined)
            this._shape_opacity = getNumberFromJSON(json_local_object, 'opacity', default_shape_opacity);
        if (json_local_object['dashed'] !== undefined)
            this._shape_is_dashed = getBooleanFromJSON(json_local_object, 'dashed', default_shape_is_dashed);
        //Attributes link labels
        if (json_local_object['label_visible'] !== undefined)
            this._value_label_is_visible = getBooleanFromJSON(json_local_object, 'label_visible', default_value_label_is_visible);
        if (json_local_object['label_font_size'] !== undefined)
            this._value_label_font_size = getNumberFromJSON(json_local_object, 'label_font_size', default_value_label_font_size);
        if (json_local_object['text_color'] !== undefined)
            this._value_label_color = getStringFromJSON(json_local_object, 'text_color', default_value_label_color);
        if (json_local_object['to_precision'] !== undefined)
            this._value_label_to_precision = getBooleanFromJSON(json_local_object, 'to_precision', default_value_label_to_precision);
        if (json_local_object['scientific_precision'] !== undefined)
            this._value_label_scientific_precision = getNumberFromJSON(json_local_object, 'scientific_precision', default_value_label_scientific_precision);
        if (json_local_object['font_family'] !== undefined)
            this._value_label_font_family = getStringFromJSON(json_local_object, 'font_family', default_value_label_font_family);
        if (json_local_object['label_unit_visible'] !== undefined)
            this._value_label_unit_visible = getBooleanFromJSON(json_local_object, 'label_unit_visible', default_value_label_unit_visible);
        if (json_local_object['label_unit'] !== undefined)
            this._value_label_unit = getStringFromJSON(json_local_object, 'label_unit', default_value_label_unit);
        if (json_local_object['custom_digit'] !== undefined)
            this._value_label_custom_digit = getBooleanFromJSON(json_local_object, 'custom_digit', default_value_label_custom_digit);
        if (json_local_object['nb_digit'] !== undefined)
            this._value_label_nb_digit = getNumberFromJSON(json_local_object, 'nb_digit', default_value_label_nb_digit);
    }
    copyFrom(element) {
        // Shape type
        this._shape_is_curved = element._shape_is_curved;
        this._shape_curvature = element._shape_curvature;
        this._shape_is_recycling = element._shape_is_recycling;
        // Shape orientation
        this._shape_orientation = element._shape_orientation;
        this._shape_starting_curve = element._shape_starting_curve;
        this._shape_ending_curve = element._shape_ending_curve;
        this._shape_starting_tangeant = element._shape_starting_tangeant;
        this._shape_ending_tangeant = element._shape_ending_tangeant;
        this._shape_middle_recycling = element._shape_middle_recycling;
        this._shape_vert_shift = element._shape_vert_shift;
        // Shape's arrow attributes
        this._shape_is_arrow = element._shape_is_arrow;
        this._shape_arrow_size = element._shape_arrow_size;
        // Shape's Filling attributes
        this._shape_is_dashed = element._shape_is_dashed;
        this._shape_color = element._shape_color;
        this._shape_opacity = element._shape_opacity;
        // Geometry link labels
        this._value_label_position = element._value_label_position;
        this._value_label_orthogonal_position = element._value_label_orthogonal_position;
        this._value_label_on_path = element._value_label_on_path;
        this._value_label_pos_auto = element._value_label_pos_auto;
        // Value label display
        this._value_label_is_visible = element._value_label_is_visible;
        this._value_label_font_family = element._value_label_font_family;
        this._value_label_font_size = element._value_label_font_size;
        this._value_label_color = element._value_label_color;
        this._value_label_to_precision = element._value_label_to_precision;
        this._value_label_scientific_precision = element._value_label_scientific_precision;
        this._value_label_custom_digit = element._value_label_custom_digit;
        this._value_label_nb_digit = element._value_label_nb_digit;
        this._value_label_unit_visible = element._value_label_unit_visible;
        this._value_label_unit = element._value_label_unit;
    }
    // PROTECTED METHODS ==================================================================
    update() { }
    // GETTERS ============================================================================
    // Shape type
    get shape_is_curved() { return this._shape_is_curved; }
    get shape_curvature() { return this._shape_curvature; }
    get shape_is_recycling() { return this._shape_is_recycling; }
    // Shape orientation
    get shape_orientation() { return this._shape_orientation; }
    get shape_starting_curve() { return this._shape_starting_curve; }
    get shape_ending_curve() { return this._shape_ending_curve; }
    get shape_starting_tangeant() { return this._shape_starting_tangeant; }
    get shape_ending_tangeant() { return this._shape_ending_tangeant; }
    get shape_middle_recycling() { return this._shape_middle_recycling; }
    get shape_vert_shift() { return this._shape_vert_shift; }
    // Shape's arrow attributes
    get shape_is_arrow() { return this._shape_is_arrow; }
    get shape_arrow_size() { return this._shape_arrow_size; }
    // Shape's Filling attributes
    get shape_is_dashed() { return this._shape_is_dashed; }
    get shape_color() { return this._shape_color; }
    get shape_opacity() { return this._shape_opacity; }
    // Geometry link labels
    get value_label_position() { return this._value_label_position; }
    get value_label_orthogonal_position() { return this._value_label_orthogonal_position; }
    get value_label_on_path() { return this._value_label_on_path; }
    get value_label_pos_auto() { return this._value_label_pos_auto; }
    // Value label display
    get value_label_is_visible() { return this._value_label_is_visible; }
    get value_label_font_family() { return this._value_label_font_family; }
    get value_label_font_size() { return this._value_label_font_size; }
    get value_label_color() { return this._value_label_color; }
    get value_label_to_precision() { return this._value_label_to_precision; }
    get value_label_scientific_precision() { return this._value_label_scientific_precision; }
    get value_label_custom_digit() { return this._value_label_custom_digit; }
    get value_label_nb_digit() { return this._value_label_nb_digit; }
    get value_label_unit_visible() { return this._value_label_unit_visible; }
    get value_label_unit() { return this._value_label_unit; }
    // SETTERS ============================================================================
    // Shape type
    set shape_is_curved(_) { this._shape_is_curved = _; this.update(); }
    set shape_curvature(_) { this._shape_curvature = _; this.update(); }
    set shape_is_recycling(_) { this._shape_is_recycling = _; this.update(); }
    // Shape orientation
    set shape_orientation(_) {
        this._shape_orientation = _;
        this.update();
    }
    set shape_starting_curve(_) {
        var _a;
        if (_ !== undefined) {
            if ((_ >= 0) &&
                (_ < ((_a = this.shape_ending_curve) !== null && _a !== void 0 ? _a : default_shape_ending_curve))) {
                this._shape_starting_curve = _;
            }
        }
        else {
            this._shape_starting_curve = _;
        }
        this.update();
    }
    set shape_ending_curve(_) {
        var _a;
        if (_ !== undefined) {
            if ((_ <= 1) &&
                (_ > ((_a = this.shape_starting_curve) !== null && _a !== void 0 ? _a : default_shape_starting_curve))) {
                this._shape_ending_curve = _;
            }
        }
        else {
            this._shape_ending_curve = _;
        }
        this.update();
    }
    set shape_starting_tangeant(_) {
        if (_ !== undefined) {
            if (_ > 0) {
                this._shape_starting_tangeant = _;
            }
        }
        else {
            this._shape_starting_tangeant = _;
        }
        this.update();
    }
    set shape_ending_tangeant(_) {
        if (_ !== undefined) {
            if (_ > 0) {
                this._shape_ending_tangeant = _;
            }
        }
        else {
            this._shape_ending_tangeant = _;
        }
        this.update();
    }
    set shape_middle_recycling(_) {
        this._shape_middle_recycling = _;
        this.update();
    }
    // TODO remove
    set shape_vert_shift(_) { this._shape_vert_shift = _; this.update(); }
    // Shape's arrow attributes
    set shape_is_arrow(_) { this._shape_is_arrow = _; this.update(); }
    set shape_arrow_size(_) { this._shape_arrow_size = _; this.update(); }
    // Shape's Filling attributes
    set shape_is_dashed(_) { this._shape_is_dashed = _; this.update(); }
    set shape_color(_) { this._shape_color = _; this.update(); }
    set shape_opacity(_) { this._shape_opacity = _; this.update(); }
    // Geometry link labels
    set value_label_position(_) { this._value_label_position = _; this.update(); }
    set value_label_orthogonal_position(_) { this._value_label_orthogonal_position = _; this.update(); }
    set value_label_on_path(_) { this._value_label_on_path = _; this.update(); }
    set value_label_pos_auto(_) { this._value_label_pos_auto = _; this.update(); }
    // Value label display
    set value_label_is_visible(_) { this._value_label_is_visible = _; this.update(); }
    set value_label_font_family(_) { this._value_label_font_family = _; this.update(); }
    set value_label_font_size(_) { this._value_label_font_size = _; this.update(); }
    set value_label_color(_) { this._value_label_color = _; this.update(); }
    set value_label_to_precision(_) { this._value_label_to_precision = _; this.update(); }
    set value_label_scientific_precision(_) { this._value_label_scientific_precision = _; this.update(); }
    set value_label_custom_digit(_) { this._value_label_custom_digit = _; this.update(); }
    set value_label_nb_digit(_) { this._value_label_nb_digit = _; this.update(); }
    set value_label_unit_visible(_) { this._value_label_unit_visible = _; this.update(); }
    set value_label_unit(_) { this._value_label_unit = _; this.update(); }
}
// CLASS LINK STYLE *********************************************************************
/**
 * Define style for links
 *
 * @export
 * @class Class_LinkStyle
 * @extends {Class_LinkAttribute}
 */
export class Class_LinkStyle extends Class_LinkAttribute {
    // CONSTRUCTOR ========================================================================
    constructor(id, name, is_deletable = true) {
        // Instantiate super class
        super();
        this._references = {};
        // Set id
        this._id = id;
        // Set name
        this._name = name;
        // Set as deletable or not
        this._is_deletable = is_deletable;
        // Parameters for shape
        this._shape_arrow_size = default_shape_arrow_size;
        this._shape_color = default_shape_color;
        this._shape_curvature = default_shape_curvature;
        this._shape_is_arrow = default_shape_is_arrow;
        this._shape_is_curved = default_shape_is_curved;
        this._shape_is_dashed = default_shape_is_dashed;
        this._shape_is_recycling = default_shape_is_recycling;
        this._shape_opacity = default_shape_opacity;
        this._shape_orientation = default_shape_orientation;
        this._shape_starting_curve = default_shape_starting_curve;
        this._shape_ending_curve = default_shape_ending_curve;
        this._shape_starting_tangeant = default_shape_starting_tangeant;
        this._shape_ending_tangeant = default_shape_ending_tangeant;
        this._shape_vert_shift = default_shape_vert_shift;
        this._value_label_color = default_value_label_color;
        this._value_label_custom_digit = default_value_label_custom_digit;
        this._value_label_font_family = default_value_label_font_family;
        this._value_label_font_size = default_value_label_font_size;
        this._value_label_is_visible = default_value_label_is_visible;
        this._value_label_nb_digit = default_value_label_nb_digit;
        this._value_label_on_path = default_value_label_on_path;
        this._value_label_orthogonal_position = default_value_label_orthogonal_position;
        this._value_label_pos_auto = default_value_label_pos_auto;
        this._value_label_position = default_value_label_position;
        this._value_label_scientific_precision = default_value_label_scientific_precision;
        this._value_label_to_precision = default_value_label_to_precision;
        this._value_label_unit = default_value_label_unit;
        this._value_label_unit_visible = default_value_label_unit_visible;
    }
    delete() {
        if (this._is_deletable) {
            // Switch all refs to default style
            Object.values(this._references)
                .forEach(ref => ref.useDefaultStyle());
            this._references = {};
            // Garbage collector will do the rest....
        }
    }
    // PUBLIC METHODS =====================================================================
    addReference(_) {
        if (!this._references[_.id]) {
            this._references[_.id] = _;
        }
    }
    removeReference(_) {
        if (this._references[_.id] !== undefined) {
            delete this._references[_.id];
        }
    }
    // PROTECTED METHODS ==================================================================
    update() {
        this.updateReferencesDraw();
    }
    // PRIVATE METHODS ====================================================================
    updateReferencesDraw() {
        Object.values(this._references)
            .forEach(ref => ref.drawElements());
    }
    // GETTERS ============================================================================
    /**
     * get id of style
     *
     * @readonly
     * @memberof Class_NodeStyle
     */
    get id() { return this._id; }
    /**
     * Get name of style != id
     * @memberof Class_NodeStyle
     */
    get name() { return this._name; }
    // SETTERS =============================================================================
    /**
     * Set name of style != id
     * @memberof Class_NodeStyle
     */
    set name(_) { this._name = _; }
}
// CLASS LINK TREE VALUE ****************************************************************
/**
 * Define a node for value
 * @export
 * @class Class_LinkValueTree
 * @implements {TreeNodeInterface}
 */
export class Class_LinkValueTree {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_LinkValueTree.
     * @param {(Class_LinkValueTree | Class_LinkElement)} parent
     * @param {Class_DataTagGroup} tag_group
     * @memberof Class_LinkValueTree
     */
    constructor(parent, data_tag_group) {
        // PRIVATE ATTRIBUTES =================================================================
        this._is_currently_deleted = false;
        // Instanciate parent
        this.parent = parent;
        // Instanciate taggroup
        this.data_tag_group = data_tag_group;
        // Instanciate children
        this.children = {};
        data_tag_group.tags_list.forEach(tag => {
            this.children[tag.id] = new Class_LinkValue(this);
        });
    }
    /**
     * Define deletion behavior
     * - Remove self from parent
     * - Delete childrens
     * @memberof Class_LinkValueTree
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Delete children
            Object.keys(this.children)
                .forEach(id => {
                this.children[id].delete();
            });
            this.children = {};
            // Unref from parent
            if (this.parent instanceof Class_LinkValueTree)
                this.parent.removeChild(this);
            // // Unref taggroup
            // this.data_tag_group = null
        }
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Add new children related to new tagGroup
     * Always add in the bottom of the tree
     * @param {Class_DataTagGroup} data_tag_group
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    expand(data_tag_group) {
        if (this.data_tag_group !== data_tag_group) // Protection against tag group already present
            Object.keys(this.children)
                .forEach(id => {
                this.children[id] = this.children[id].expand(data_tag_group);
            });
        return this;
    }
    /**
     * Remove all children related to given tag group
     * - Either prune bottom of tree (simple case)
     * - Or slice tree to keep sub-combinations of tags
     * @param {Class_DataTagGroup} data_tag_group
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    prune(data_tag_group) {
        // If data_tag_group correspond to this tree's tag group - do the pruning process
        if (this.data_tag_group === data_tag_group) {
            // Keep parent ref in memory
            const parent = this.parent;
            // Keep first child ref in memory
            const id = Object.keys(this.children)[0];
            const child = this.children[id];
            // Delete ref to first child
            delete this.children[id];
            // Re-attach tree together
            if (parent instanceof Class_LinkValueTree) {
                // When pruning this, first child is preserve because ref has been deleted from children table
                parent.removeAndReplaceChild(this, child);
                return parent;
            }
            else {
                // Parent is LinkElement
                return child;
            }
        }
        // If data_tag_group is different than the one used by
        else {
            // Recurse, only if children are also trees
            Object.keys(this.children)
                .forEach(id => {
                const child = this.children[id];
                if (child instanceof Class_LinkValueTree)
                    child.prune(data_tag_group);
            });
            return this;
        }
    }
    /**
     * Add new child from given data_tag
     * @param {Class_Tag} data_tag
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    extend(data_tag) {
        // What kind of children
        const [allValues, allTrees] = this.kindOfChildren();
        // Case 1 : Last node tree before values
        if (allValues && (!allTrees)) {
            // Tag must be from this tree's data_tag group
            if (data_tag.group === this.data_tag_group) {
                // If not already existing, create a new child // given data_tag
                if (!this.children[data_tag.id]) {
                    const _ = new Class_LinkValue(this);
                    this.children[data_tag.id] = _;
                }
                // Return child // given data_tag
                return this.children[data_tag.id];
            }
        }
        // Case 2 : Current children's are also tree
        else if ((!allValues) && allTrees) {
            // If data_tag's group correspond to this tree's data_tag group - add new child
            if (data_tag.group === this.data_tag_group) {
                // If not already existing, create a new child // given data_tag
                if (!this.children[data_tag.id]) {
                    const ref_child = Object.values(this.children)[0]; // Never undefined beacause of test on (!allValues && AllTrees)
                    if (ref_child instanceof Class_LinkValueTree) {
                        // Create and reference
                        const _ = new Class_LinkValueTree(this, ref_child.data_tag_group);
                        this.children[data_tag.id] = _;
                        // Recursivly copy values / sub-trees
                        _.copyFrom(ref_child);
                    }
                }
                // Return child // given data_tag
                return this.children[data_tag.id];
            }
            // Tag group is different than the one used
            else {
                // Go deeper recursivley
                let output = undefined;
                Object.values(this.children)
                    .forEach(child => {
                    // Child can only be Class_LinkValueTree because of test on (!allValues && AllTrees)
                    const _ = child.extend(data_tag);
                    // Return something not undefined if possible
                    if (_ && (!output))
                        output = _;
                });
                return output;
            }
        }
        return undefined;
    }
    /**
     * Remove child related to given dataTag
     * @param {Class_Tag} data_tag
     * @memberof Class_LinkValueTree
     */
    reduce(data_tag) {
        // Tag is from correct data_tag group
        if (data_tag.group === this.data_tag_group) {
            this.removeChildFromDataTagId(data_tag.id);
        }
        // Recursive call
        else {
            Object.values(this.children)
                .forEach(child => {
                if (child instanceof Class_LinkValueTree)
                    child.reduce(data_tag);
            });
        }
    }
    /**
     * Remove given child from children (ie prune tree)
     * @private
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @memberof Class_LinkValueTree
     */
    removeChild(child) {
        // Get child's id
        const id = this.getDataTagIdFromChild(child);
        // Remove it
        if (id)
            this.removeChildFromDataTagId(id);
    }
    getValueForDataTags(data_tags) {
        // Failsafe
        if (data_tags.length === 0)
            return null;
        // Get value recursively
        const matching_tags = data_tags.filter(tag => (tag.group === this.data_tag_group));
        const remaining_tags = data_tags.filter(tag => (tag.group !== this.data_tag_group));
        // Failsafe
        if (matching_tags.length !== 1)
            return null;
        // Recursive
        const child = this.children[matching_tags[0].id];
        if (child !== undefined) {
            if (child instanceof Class_LinkValue)
                return child;
            else
                return child.getValueForDataTags(remaining_tags);
        }
        else {
            return null;
        }
    }
    setDataValueForDataTags(data_tags, val) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            value.data_value = val;
        }
    }
    getDataValueForDataTags(data_tags) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            return value.data_value;
        }
        else {
            return null;
        }
    }
    setTextValueForDataTags(data_tags, val) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            value.text_value = val;
        }
    }
    getTextValueForDataTags(data_tags) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            return value.text_value;
        }
        else {
            return null;
        }
    }
    /**
     * Find corresponding id for given child
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @memberof Class_LinkValueTree
     */
    getDataTagIdFromChild(child) {
        let id = undefined;
        Object.keys(this.children)
            .forEach(tag_id => {
            if (this.children[tag_id] === child) {
                id = tag_id;
            }
        });
        return id;
    }
    /**
     * Return combinason of datatags if to reach given child
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @return {*}  {string[]}
     * @memberof Class_LinkValueTree
     */
    getDataTagsIdCombination(child) {
        const id = this.getDataTagIdFromChild(child);
        if (id) {
            if (this.parent instanceof Class_LinkValueTree) {
                const prev_id = this.parent.getDataTagsIdCombination(this);
                prev_id.push(id);
                return prev_id;
            }
            else
                return [id];
        }
        return [];
    }
    toJSON() {
        const json_object = {};
        json_object['datatag_group'] = this.data_tag_group.id;
        Object.entries(this.children)
            .forEach(([id, child]) => {
            json_object[id] = child.toJSON();
        });
        return json_object;
    }
    fromJSON(json_object, matching_taggs_id = {}, matching_tags_id = {}) {
        // All parentality relations are sets via sankey struct with fromJSON + addDataTag
        // So it is not necessary to read datatag group -> it should be the same as in JSON
        // if (this.data_tag_group.id !== json_object['datatag_group'])
        //   console.error('Erreur lecture valeur dans JSON : datatag group are not matching')
        // else {
        Object.entries(json_object)
            .filter(([id,]) => id !== 'datatag_group') // Skip this entry in JSON
            .forEach(([id, sub_json_object]) => {
            var _a;
            if (typeof sub_json_object === 'object')
                (_a = this.children[id]) === null || _a === void 0 ? void 0 : _a.fromJSON(sub_json_object, matching_taggs_id, matching_tags_id);
        });
        //}
    }
    /**
     * Browse children & search for the maximum value among them
     *
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    getMaxValue() {
        let max = null;
        Object.entries(this.children)
            .forEach(child => {
            const _ = child[1].getMaxValue();
            max = ((max !== null && max !== void 0 ? max : 0) <= _ ? _ : max);
        });
        return max;
    }
    getAllValues() {
        let out = {};
        Object.values(this.children)
            .forEach(child => {
            const _ = child.getAllValues();
            out = Object.assign({}, _);
        });
        Object.values(out)
            .forEach(_ => {
            if (_[1] && this.data_tag)
                _[1].push(this.data_tag);
        });
        return out;
    }
    // PRIVATE METHODS ====================================================================
    kindOfChildren() {
        let allLinkValue = true;
        let allLinkValueTree = true;
        Object.values(this.children)
            .forEach(child => {
            allLinkValue = allLinkValue && (child instanceof Class_LinkValue);
            allLinkValueTree = allLinkValueTree && (child instanceof Class_LinkValueTree);
        });
        return [allLinkValue, allLinkValueTree];
    }
    copyFrom(element) {
        // Check types of children
        const [allValues, allTrees] = element.kindOfChildren();
        // Clean children
        Object.values(this.children)
            .forEach(child => child.delete());
        // Copy children recursively
        Object.keys(element.children)
            .forEach(tag_id => {
            const child_to_copy = element.children[tag_id];
            if ((child_to_copy instanceof Class_LinkValueTree) && (allTrees)) {
                const new_child = new Class_LinkValueTree(this, child_to_copy.data_tag_group);
                this.children[tag_id] = new_child;
                new_child.copyFrom(child_to_copy);
            }
            else if ((child_to_copy instanceof Class_LinkValue) && allValues) {
                const new_child = new Class_LinkValue(this);
                this.children[tag_id] = new_child;
                new_child.copyFrom(child_to_copy);
            }
        });
    }
    removeAndReplaceChild(child, new_child) {
        // Get current child id
        const id = this.getDataTagIdFromChild(child);
        // Delete current child
        if (id) {
            this.removeChildFromDataTagId(id);
            // Replace and update cross refs
            this.children[id] = new_child;
            new_child.parent = this;
        }
    }
    removeChildFromDataTagId(id) {
        if (this.children[id]) {
            this.children[id].delete();
            delete this.children[id];
        }
    }
    // GETTERS / SETTERS ==================================================================
    get link() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.link;
        else
            return this.parent;
    }
    get data_tag() {
        var _a, _b;
        if (this.parent instanceof Class_LinkValueTree)
            return (_b = this.parent.data_tag_group.tags_dict[(_a = this.parent.getDataTagIdFromChild(this)) !== null && _a !== void 0 ? _a : '']) !== null && _b !== void 0 ? _b : null;
        else
            return null;
    }
}
// CLASS LINK VALUE *********************************************************************
/**
 * Define a link value object
 *
 * @export
 * @class Class_LinkValue
 * @extends {Class_LinkValueTree}
 */
export class Class_LinkValue {
    // CONSTRUCTOR ========================================================================
    constructor(parent) {
        var _a, _b;
        this.data_value = null;
        this.text_value = null;
        /**
         * FluxTags
         * @private
         * @type {{ [_: string]: Class_Tag }}
         * @memberof Class_LinkElement
         */
        this._flux_tags = {};
        this._is_currently_deleted = false;
        // Parents / Children relations
        this.parent = parent;
        // Id
        const name = ((_b = (_a = this.link) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '') + '_value_';
        this.data_tags_id
            .forEach(tag_id => name + '_' + tag_id);
        this._id = makeId(name);
    }
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Unref from parent
            if (this.parent instanceof Class_LinkValueTree)
                this.parent.removeChild(this);
            // Remove reference of self in related tags
            this.flux_tags_list.forEach(tag => tag.removeReference(this));
            this._flux_tags = {};
        }
    }
    // PUBLIC METHODS =====================================================================
    draw() {
        var _a;
        (_a = this.link) === null || _a === void 0 ? void 0 : _a.draw();
    }
    copyFrom(element) {
        this.data_value = element.data_value;
        this.text_value = element.text_value;
        element.flux_tags_list
            .forEach(flux_tag => {
            flux_tag.addReference(this);
        });
    }
    expand(data_tag_group) {
        const new_parent = new Class_LinkValueTree(this.parent, data_tag_group);
        // Copy values from child in grandchildren
        data_tag_group.tags_list.forEach(tag => {
            const _ = new_parent.extend(tag);
            if (_ instanceof Class_LinkValue) // Should always be the case here, but needed
                _.copyFrom(this);
        });
        // Clean self
        this.delete();
        // Return new parent
        return new_parent;
    }
    /**
     * Check if given flux tag is referenced by value
     * @param {Class_Tag} tag
     * @return {*}
     * @memberof Class_LinkElement
     */
    hasGivenTag(tag) {
        return (this._flux_tags[tag.id] !== undefined);
    }
    /**
     * Add and cross-reference a Flux tag with this value
     * @param {Class_Tag} tag
     * @memberof Class_LinkElement
     */
    addTag(tag) {
        if (!this.hasGivenTag(tag)) {
            this._flux_tags[tag.id] = tag;
            tag.addReference(this);
            this.draw();
        }
    }
    /**
     * Remove given tag and cross-reference from link
     * @param {Class_Tag} tag
     * @memberof Class_LinkElement
     */
    removeTag(tag) {
        if (this.hasGivenTag(tag)) {
            delete this._flux_tags[tag.id];
            tag.removeReference(this);
            this.draw();
        }
    }
    /**
     * Function that can be used instead of the one in Class_linkValueTree so the recursive function stop & return a value
     *
     * @return {*}
     * @memberof Class_LinkValue
     */
    getMaxValue() {
        return this.data_value;
    }
    getAllValues() {
        const tmp = {};
        if (this.data_tag)
            tmp[this.id] = [this, [this.data_tag]];
        else
            tmp[this.id] = [this, undefined];
        return tmp;
    }
    /**
     * Extract this link value as JSON
     *
     * @return {*}
     * @memberof Class_LinkValue
     */
    toJSON() {
        // Init output JSON
        const json_object = {};
        json_object['id'] = this._id;
        // Fill data
        json_object['id'] = this._id;
        if (this.data_value)
            json_object['data_value'] = this.data_value;
        if (this.text_value)
            json_object['text_value'] = this.text_value;
        json_object['tags'] = Object.fromEntries(this.flux_taggs_list
            .map(tagg => [
            tagg.id,
            this.flux_tags_list
                .filter(tag => (tag.group === tagg))
                .map(tag => tag.id)
        ]));
        // Output
        return json_object;
    }
    fromJSONLegacy(json_object) {
        this.data_value = getNumberOrNullFromJSON(json_object, 'value');
        this.text_value = getStringOrNullFromJSON(json_object, 'display_value');
    }
    /**
     * Read this link value from JSON
     *
     * @param {Type_JSON} json_object
     * @memberof Class_LinkValue
     */
    fromJSON(json_object, matching_taggs_id = {}, matching_tags_id = {}) {
        var _a, _b, _c;
        this._id = getStringFromJSON(json_object, 'id', this._id);
        // Update attributes
        if (Object.prototype.hasOwnProperty.call(json_object, 'value')) { // Value key => Legacy JSON
            this.fromJSONLegacy(json_object);
        }
        else {
            this.data_value = getNumberOrNullFromJSON(json_object, 'data_value');
            this.text_value = getStringOrNullFromJSON(json_object, 'text_value');
        }
        // Get Flux tags
        // In JSON here are how supposed tags var is :
        // tags: {key_grp_tag: [key_tag, ...] }
        // where 'key_grp_tag' represent the id of a flux tag group
        // &  '[key_tag, ...]' represent the array of id of tag selected
        // for that flux tag group
        const flux_taggs_dict = ((_b = (_a = this.link) === null || _a === void 0 ? void 0 : _a.drawing_area.sankey.flux_taggs_dict) !== null && _b !== void 0 ? _b : {});
        Object.entries((_c = json_object['tags']) !== null && _c !== void 0 ? _c : {})
            .filter(([id, list]) => {
            var _a;
            const tagg_id = (_a = matching_taggs_id[id]) !== null && _a !== void 0 ? _a : id;
            const tag_ids = list.map(_ => { var _a; return (_a = matching_tags_id[id][_]) !== null && _a !== void 0 ? _a : _; });
            return ((tagg_id in flux_taggs_dict) &&
                (tag_ids.length > 0));
        })
            .forEach(([id, list]) => {
            var _a;
            const tagg_id = (_a = matching_taggs_id[id]) !== null && _a !== void 0 ? _a : id;
            const tagg = flux_taggs_dict[tagg_id];
            const tag_ids = list.map(_ => { var _a; return (_a = matching_tags_id[id][_]) !== null && _a !== void 0 ? _a : _; });
            tagg.tags_list
                .filter(tag => tag_ids.includes(tag.id))
                .forEach(tag => this.addTag(tag));
        });
    }
    // GETTERS / SETTERS ==================================================================
    /**
     * Id of value
     *
     * @readonly
     * @memberof Class_LinkValue
     */
    get id() { return this._id; }
    /**
     * Related link of value
     *
     * @readonly
     * @type {(Class_LinkElement | null)}
     * @memberof Class_LinkValue
     */
    get link() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.link;
        else
            return this.parent;
    }
    /**
     * Dict as [id: tag] of flux tags related to this value
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_tags_dict() {
        return this._flux_tags;
    }
    /**
     * Array of flux tags related to this value
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_tags_list() {
        return Object.values(this._flux_tags);
    }
    /**
     * Dict as [id: tag group] of tag groups related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_taggs_dict() {
        const taggs = {};
        this.flux_tags_list
            .forEach(tag => {
            if (!taggs[tag.group.id])
                taggs[tag.group.id] = tag.group;
        });
        return taggs;
    }
    /**
     * Array of tag groups related to link
     * @readonly
     * @memberof Class_NodeElement
     */
    get flux_taggs_list() {
        return Object.values(this.flux_taggs_dict);
    }
    get data_tags_id() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.getDataTagsIdCombination(this);
        else
            return [];
    }
    get data_tagg() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.data_tag_group;
        else
            return null;
    }
    get data_tag() {
        var _a, _b, _c;
        if (this.parent instanceof Class_LinkValueTree)
            return (_c = (_a = this.data_tagg) === null || _a === void 0 ? void 0 : _a.tags_dict[(_b = this.parent.getDataTagIdFromChild(this)) !== null && _b !== void 0 ? _b : '']) !== null && _c !== void 0 ? _c : null;
        else
            return null;
    }
}
// CLASS GHOST LINK *********************************************************************
export class Class_GhostLinkElement extends Class_LinkElement {
    // GETTER / SETTER ====================================================================
    get is_visible() { return this._is_visible; }
}
