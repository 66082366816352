// Standard libs
import React, { useState, useRef } from 'react';
import * as d3 from 'd3';
import { MultiSelect } from 'react-multi-select-component';
import { FaAngleDown, FaAngleUp, FaMinus, FaPlus } from 'react-icons/fa';
import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css'
// Imported libs
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Checkbox, Button, InputGroup, Input, NumberInput, NumberDecrementStepper, NumberIncrementStepper, NumberInputField, NumberInputStepper, ButtonGroup } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { OSTooltip } from './import/OpenSankey';
const sep = React.createElement("hr", { style: { borderStyle: 'none', margin: '0px', color: 'grey', backgroundColor: 'grey', height: 2 } });
/**
 *  TODO
 *
 * @param { TFunction } t - TODO description
 * @param { OSPData } data - TODO description
 * @param { Function } set_data - TODO description
 *
 */
export const OSPMenuPreferenceLabels = ({ applicationData, updateMenus }) => {
    const { new_data } = applicationData;
    return React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', defaultChecked: new_data.menu_configuration.isGivenAccordionShowed('MEP'), onChange: () => {
            new_data.menu_configuration.toggleGivenAccordion('MEP');
            updateMenus[1](!updateMenus[0]);
        } }, new_data.t('Menu.LL'));
};
export const OSPMenuConfigurationFreeLabels = ({ applicationData, }) => {
    var _a, _b;
    const { new_data } = applicationData;
    const { t } = new_data;
    const selected_zdt = new_data.drawing_area.selected_free_labels_list;
    const r_editor_ZDT = useRef();
    const zdt_or_image = (selected_zdt.length > 0 ? (selected_zdt[0].is_image === true ? 'image' : 'zdt') : 'zdt');
    const [button_icon_or_image, set_button_icon_or_image] = useState(zdt_or_image);
    const INITIAL_OPTIONS_label = new_data.drawing_area.sankey.free_labels_list_sorted.map((d) => { return { 'label': d.title, 'value': d.id }; });
    const selected_label = selected_zdt.map((d) => { return { 'label': d.title, 'value': d.id }; });
    //const [s_editor_content_fo_zdt,sEditorContentFOZdt]= useState('')
    const [forceUpdate, setForceUpdate] = useState(false);
    // Link current component updater to menu config class
    new_data.menu_configuration.ref_to_menu_config_free_label_updater.current = () => setForceUpdate(!forceUpdate);
    //applicationState.r_setter_editor_content_fo_zdt.current!.push(sEditorContentFOZdt)
    // if (selected_zdt.length == 0 && s_editor_content_fo_zdt != '') {
    //   sEditorContentFOZdt('')
    // }
    //Dépalce la place des labels libres sélectionnés vers le debut dans le tableau de flux de data
    //Permet donc de les déssiner après
    const handleUplabel = (i) => {
        new_data.drawing_area.sankey.moveUpFreeLabelOrder(i);
        setForceUpdate(!forceUpdate);
    };
    //Dépalce la place des labels libres sélectionnés vers la fin dans le tableau de flux de data
    //Permet donc de les déssiner après
    const handleDownlabel = (i) => {
        new_data.drawing_area.sankey.moveDownFreeLabelOrder(i);
        setForceUpdate(!forceUpdate);
    };
    const redrawAndRefresh = () => {
        selected_zdt.forEach(zdt => zdt.draw());
        setForceUpdate(!forceUpdate);
    };
    //Renvoie le menue déroulant pour la sélection des labels libres
    const dropdownMultiLabel = () => {
        const DD = (React.createElement(Box, { layerStyle: 'submenuconfig_droplist' },
            React.createElement(Box, { height: '2rem', width: '10rem' },
                React.createElement(MultiSelect, { disabled: !new_data.has_sankey_plus, valueRenderer: (selected) => {
                        return selected.length ? selected.map(({ label }) => label + ', ') : 'Aucun label sélectionné';
                    }, options: INITIAL_OPTIONS_label, value: selected_label, overrideStrings: {
                        'selectAll': 'Tout sélectionner',
                    }, onChange: (entries) => {
                        // Update selection list
                        const entries_values = entries.map(d => d.value);
                        new_data.drawing_area.sankey.free_labels_list.forEach(zdt => {
                            if (entries_values.includes(zdt.id)) {
                                new_data.drawing_area.addFreeLabelToSelection(zdt);
                            }
                            else {
                                new_data.drawing_area.removeFreeLabelFromSelection(zdt);
                            }
                        });
                        redrawAndRefresh();
                    }, labelledBy: t('Noeud.TS') }))));
        return DD;
    };
    //=================FONCTION POUR TEST VALEUR MULTI SELECT LABEL===========================
    const allLabelHeight = () => {
        let display_size = true;
        let size = 25;
        if (selected_zdt.length !== 0) {
            size = selected_zdt[0].label_height;
        }
        selected_zdt.map((d) => {
            display_size = (d.label_height === size) ? display_size : false;
        });
        return (display_size) ? Math.round(size) : -1;
    };
    const allLabelWidth = () => {
        let display_size = true;
        let size = 25;
        if (selected_zdt.length !== 0) {
            size = selected_zdt[0].label_width;
        }
        selected_zdt.map((d) => {
            display_size = (d.label_width === size) ? display_size : false;
        });
        return (display_size) ? Math.round(size) : -1;
    };
    const allLabelTitle = () => {
        return selected_zdt.length > 0 ? selected_zdt[0].title : '';
    };
    const allLabelTransparent = () => {
        let display_size = true;
        let opa = 100;
        if (selected_zdt.length !== 0) {
            opa = selected_zdt[0].opacity;
        }
        selected_zdt.map((d) => {
            display_size = (d.opacity === opa) ? display_size : false;
        });
        return (display_size) ? opa : 0;
    };
    const valAllLabelBorderTransparent = (_b = (_a = selected_zdt[0]) === null || _a === void 0 ? void 0 : _a.transparent_border) !== null && _b !== void 0 ? _b : false;
    // Check if every transparent_border of selected zdt are the same as the first selected, if it true value is not indeterminate
    const valAllLabelBorderTransparentIndeterminate = !selected_zdt.every(zdt => zdt.transparent_border == valAllLabelBorderTransparent);
    const modules = {
        toolbar: [
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean'],
        ],
    };
    const formats = [
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'list',
        'bullet',
        'align'
    ];
    const disable_options = new_data.has_sankey_plus ? (selected_zdt.length === 0) : true;
    const content_image = React.createElement(React.Fragment, null,
        React.createElement(OSTooltip, { label: !new_data.has_sankey_plus ? t('Menu.sankeyOSPDisabled') : '' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.img_src')),
                React.createElement(Input, { accept: 'image/*', type: "file", disabled: disable_options, onChange: (evt) => {
                        const files = evt.target.files;
                        const reader = new FileReader();
                        reader.onload = (() => {
                            return (e) => {
                                const resultat = e.target.result;
                                const res = resultat === null || resultat === void 0 ? void 0 : resultat.toString().replaceAll('=', '');
                                selected_zdt.forEach(n => n.image_src = res);
                                redrawAndRefresh();
                            };
                        })();
                        reader.readAsDataURL(files[0]);
                    } }))));
    const content_menu_zdt = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_zdt_row_droplist' },
            React.createElement(Button, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_add_button', onClick: () => {
                    // Create default node
                    const new_node = new_data.drawing_area.sankey.addNewDefaultFreeLabel();
                    // Add node to selection
                    new_data.drawing_area.addFreeLabelToSelection(new_node);
                    // Update menus
                    redrawAndRefresh();
                } },
                React.createElement(FaPlus, null)),
            dropdownMultiLabel(),
            React.createElement(Button, { variant: 'menuconfigpanel_del_button', isDisabled: disable_options, onClick: () => {
                    // Delete all selected nodes
                    applicationData.new_data.drawing_area.sankey.deleteSelectedFreeLabels();
                    // Update all menus
                    redrawAndRefresh();
                } },
                React.createElement(FaMinus, null)),
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: disable_options, onClick: () => {
                    selected_zdt.map(l => {
                        handleDownlabel(l);
                    });
                } },
                React.createElement(FaAngleUp, null)),
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: disable_options, onClick: () => {
                    selected_zdt.map(l => {
                        handleUplabel(l);
                    });
                } },
                React.createElement(FaAngleDown, null))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', gridTemplateColumns: '1fr 9fr' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('LL.title')),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input', max: 100, disabled: disable_options, style: {
                        color: (disable_options) ? '#666666' : '',
                        backgroundColor: (disable_options) ? '#cccccc' : ''
                    }, value: allLabelTitle(), onChange: evt => {
                        const value = evt.target.value;
                        selected_zdt.map(d => d.title = value);
                        setForceUpdate(!forceUpdate);
                    } }))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.illustration_type')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Button, { isDisabled: disable_options, variant: 'menuconfigpanel_option_button', onClick: () => {
                        selected_zdt.forEach(n => n.is_image = false);
                        set_button_icon_or_image('zdt');
                        redrawAndRefresh();
                    } }, "Texte"),
                React.createElement(Button, { disabled: disable_options, variant: 'menuconfigpanel_option_button', onClick: () => {
                        selected_zdt.forEach(n => n.is_image = true);
                        set_button_icon_or_image('image');
                        redrawAndRefresh();
                    } }, "Image"))),
        button_icon_or_image === 'zdt' ? React.createElement(Box, { style: { 'height': '300px' } },
            React.createElement(ReactQuill, { className: 'quill_editor', value: selected_zdt.length > 0 ? selected_zdt[0].content : '', ref: r_editor_ZDT, onChange: (evt) => {
                    selected_zdt.forEach(n => n.content = evt);
                    redrawAndRefresh();
                }, theme: "snow", modules: modules, formats: formats, readOnly: disable_options, style: {
                    'height': '300px',
                    color: (disable_options) ? '#666666' : '',
                    backgroundColor: (disable_options) ? '#cccccc' : '',
                    overflowY: 'scroll'
                } })) : content_image,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.hl')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', min: 0, isDisabled: disable_options, value: allLabelHeight(), onChange: evt => {
                            selected_zdt.map(d => d.label_height = +evt);
                            redrawAndRefresh();
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null))))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.ll')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', min: 0, isDisabled: disable_options, value: allLabelWidth(), onChange: evt => {
                            selected_zdt.map(d => d.label_width = +evt);
                            redrawAndRefresh();
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null)))))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.cfl')),
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', id: 'form_color_zdt', name: 'form_color_zdt', isDisabled: disable_options, value: (selected_zdt.length === 1) ? selected_zdt[0].color : '#ffffff', onChange: evt => {
                        const val = evt.target.value;
                        selected_zdt.map(d => d.color = val);
                        redrawAndRefresh();
                    } })),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.ft')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', max: 100, min: 0, step: 1, isDisabled: disable_options, value: allLabelTransparent(), onChange: evt => {
                            const value = +evt;
                            selected_zdt.map(d => d.opacity = value);
                            redrawAndRefresh();
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null)))))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.cbl')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', id: 'form_color_border_zdt', name: 'form_color_border_zdt', disabled: !new_data.has_sankey_plus && !valAllLabelBorderTransparent, value: (selected_zdt.length === 1) ? selected_zdt[0].color_border : '#ffffff', onChange: evt => {
                        const val = evt.target.value;
                        selected_zdt.map(d => d.color_border = val);
                        redrawAndRefresh();
                    } }),
                React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', iconColor: valAllLabelBorderTransparentIndeterminate ? '#78C2AD' : 'white', isDisabled: disable_options, isIndeterminate: valAllLabelBorderTransparentIndeterminate, isChecked: valAllLabelBorderTransparent, onChange: (evt) => {
                        selected_zdt.map(d => d.transparent_border = evt.target.checked);
                        redrawAndRefresh();
                    } }, t('LL.bt')))));
    return content_menu_zdt;
};
export const ContextZDT = ({ applicationData, }) => {
    var _a, _b;
    const { new_data } = applicationData;
    const { t } = new_data;
    // const [zdt_to_contextualise, set_zdt_to_contextualise] = useState<OSPLabel>()
    // const { _ref_to_menu_config_free_label_updater } = ComponentUpdater
    const selected_zdt = new_data.drawing_area.selected_free_labels_list;
    const zdt_to_contextualise = new_data.drawing_area.contextualised_free_label;
    // contextualised_zdt.current = set_zdt_to_contextualise
    // dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_zdt.current
    const [, setCount] = useState(0);
    let style_c_zdd = '0px 0px auto auto';
    if (zdt_to_contextualise) {
        style_c_zdd = (new_data.drawing_area.pointer_pos[1] - 20) + 'px auto auto ' + (new_data.drawing_area.pointer_pos[0] + 10) + 'px';
    }
    const redrawAndRefresh = () => {
        // Refresh menu config free label
        new_data.menu_configuration.ref_to_menu_config_free_label_updater.current();
        // Redraw selected elements
        selected_zdt.forEach(zdt => zdt.draw());
        // Refresh this menu
        setCount(a => a + 1);
    };
    const closeContextMenu = () => {
        // Unset contextualized node
        new_data.drawing_area.contextualised_free_label = undefined;
        setCount(a => a + 1);
    };
    const valAllLabelBorderTransparent = (_b = (_a = selected_zdt[0]) === null || _a === void 0 ? void 0 : _a.transparent_border) !== null && _b !== void 0 ? _b : false;
    // Check if every transparent_border of selected zdt are the same as the first selected, if it true value is not indeterminate
    const valAllLabelBorderTransparentIndeterminate = !selected_zdt.every(zdt => zdt.transparent_border == valAllLabelBorderTransparent);
    const btn_mask_border = React.createElement(Button, { onClick: () => {
            selected_zdt.forEach(zdt => zdt.transparent_border = !valAllLabelBorderTransparent);
            redrawAndRefresh();
        }, variant: 'contextmenu_button' }, valAllLabelBorderTransparent ? t('LL.display_border') : t('LL.hide_border'));
    const btn_change_color = React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'contextmenu_button' },
            React.createElement(Input, { hidden: true, type: 'color', id: 'form_color_zdt', name: 'color_bg_zdd', value: (selected_zdt.length === 1) ? selected_zdt[0].color : '#ffffff', onChange: (evt) => {
                    const val = evt.target.value;
                    selected_zdt.map(d => d.color = val);
                    redrawAndRefresh();
                } }),
            React.createElement("label", { htmlFor: 'form_color_zdt', style: { width: '100%', margin: 0 } }, t('LL.cfl'))));
    const button_open_layout = React.createElement(Button, { onClick: () => {
            new_data.menu_configuration.dict_setter_show_dialog_plus.ref_setter_show_menu_zdt.current(true);
            closeContextMenu();
        }, variant: 'contextmenu_button' },
        t('Menu.LL'),
        " ",
        icon_open_modal);
    return zdt_to_contextualise ? React.createElement(Box, { layerStyle: 'context_menu', id: "context_zdd_pop_over", style: { maxWidth: '100%', position: 'absolute', inset: style_c_zdd, zIndex: 4 } },
        React.createElement(ButtonGroup, { orientation: 'vertical', isAttached: true },
            btn_mask_border,
            btn_change_color,
            sep,
            button_open_layout)) : React.createElement(React.Fragment, null);
};
const icon_open_modal = React.createElement(FontAwesomeIcon, { style: { float: 'right' }, icon: faUpRightFromSquare });
export const blur_ZDT_wysiwyg = (r_editor_ZDT) => {
    var _a, _b, _c;
    if (r_editor_ZDT && r_editor_ZDT.current && ((_c = (_b = (_a = d3.select(document.activeElement)) === null || _a === void 0 ? void 0 : _a.attr('class')) === null || _b === void 0 ? void 0 : _b.includes('ql-editor')) !== null && _c !== void 0 ? _c : false)) {
        r_editor_ZDT.current.getEditor().focus();
        r_editor_ZDT.current.getEditor().blur();
    }
};
/**
 *Function that return content_menu_zdt with JSX to imbricate it in the config menu
*
* @param {*} {
*   applicationData,
*   content_menu_zdt
* }
* @return {*}
*/
export const ZDTMenuAsAccordeonItem = ({ applicationData, content_menu_zdt }) => {
    const { new_data } = applicationData;
    const { t } = new_data;
    return React.createElement(AccordionItem
    // style={{ 'display': (new_data.menu_configuration.isGivenAccordionShowed('LL')) ? 'initial' : 'none' }}
    , null,
        React.createElement(AccordionButton, { ref: new_data.menu_configuration.zdt_accordion_ref, onClick: () => {
                const scroll_x = window.scrollX;
                const scroll_y = window.scrollY;
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                }, 50);
            } },
            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.LL')),
            React.createElement(AccordionIcon, null)),
        React.createElement(AccordionPanel, null, content_menu_zdt));
};
