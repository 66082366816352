// External imports
import React,{ Dispatch, SetStateAction, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FaPowerOff,
  FaUser
} from 'react-icons/fa'
import LZString from 'lz-string'
import {
  Box,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  Menu
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

// OpenSankey types imports
// import {
//   initializeElementSelectedType,
//   initializeReinitializationType,
//   applicationDataType,
//   applicationStateType,
//   contextMenuType,
//   initializeApplicationDataType,
//   initializeApplicationDrawType,
//   initializeLinkFunctionsType,
//   initializeNodeFunctionsType,
//   DrawAllType,
//   InstallEventsOnSVGType,
//   ComponentUpdaterType,
//   initializeUIElementsRefType,
//   initializeAdditionalMenusType,
//   module_dialogsType,
//   initializeShowDialogType,
//   initializeCloseAllMenuContextType,
//   dict_hook_ref_setter_show_dialog_componentsType,
//   processFunctionsType,
//   initializeMenuConfigurationFuncType,
//   initializeKeyHandlerType,
//   applicationDrawType,
//   LinkFunctionTypes,
//   NodeFunctionTypes,
//   uiElementsRefType
// } from 'open-sankey/src/types/Types'
// import {
//   ConvertDataFuncType
// } from 'open-sankey/src/configmenus/types/SankeyConvertTypes'

// Local types imports
// import { suiteApplicationContextType,
//   suiteApplicationDataType,
//   suiteElementsSelectedType,
//   SuiteInitializeApplicationContextType
// } from '../types/Types'

// Local components / functions imports
import {
  CardsTemplateBuilder,
  welcomeModalBuilder
} from './SankeyModalWelcome'
import {
  loginOut
} from './components/Login/LoginFunctions'
import { applicationDataType, initializeAdditionalMenusType, initializeApplicationDataType, initializeReinitializationType, module_dialogsType } from 'open-sankey/src/types/LegacyType'
import { Class_ApplicationDataSuite } from './Types/ApplicationDataSuite'
import { setDiagram } from './import/OpenSankey'
import { Class_ApplicationData } from 'open-sankey/dist/types/ApplicationData'


declare const window: Window &
  typeof globalThis & {
    SankeyToolsStatic: boolean
    sankey: {
      filiere?: string,
      header?: string,
      has_header?: boolean,
      footer?: boolean,
      logo_width?: number,
      excel?: string,
      publish?: boolean
      logo?: string,
      has_welcome: boolean
    }
  }

/*************************************************************************************************/
//Search for licence logo
let logo_OS = ''
try {
  /* eslint-disable */
  // @ts-ignore
  logo_OS = require('./css/Open_Sankey_logo.jpg')
  /* eslint-enable */
  const path = window.location.href
  if (!path.includes('localhost')) {
    logo_OS = logo_OS.replace('static/', 'static/sankeysuite/')
  }
} catch (expt) {
  console.log('logo_OS not found')
}

let logo_OSP = ''
try {
  /* eslint-disable */
  // @ts-ignore
  logo_OSP = require('./css/OSP.png')
  /* eslint-enable */
  const path = window.location.href
  if (!path.includes('localhost')) {
    logo_OSP = logo_OSP.replace('static/', 'static/sankeysuite/')
  }
} catch (expt) {
  console.log('logo_OSP not found')
}

let logo_OSS = ''
try {
  /* eslint-disable */
  // @ts-ignore
  logo_OSS = require('./css/OSS.png')
  /* eslint-enable */
  const path = window.location.href
  if (!path.includes('localhost')) {
    logo_OSS = logo_OSS.replace('static/', 'static/sankeysuite/')
  }
} catch (expt) {
  console.log('logo_OSS not found')
}

let logo = ''

try {
  logo = require('./css/opensankey.png')
  const path = window.location.href
  if (!path.includes('localhost')) {
    logo = logo.replace('static/', 'static/sankeysuite/')
  }
} catch (expt) {
  console.log('opensankey.png not found')
}

let logo_terriflux = 'logo_terriflux.png'
try {
  logo_terriflux = require('./css/terriflux.png')
  const path = window.location.href
  if (!path.includes('localhost')) {
    logo_terriflux = logo_terriflux.replace('static/', 'static/sankeysuite/')
  }
} catch (expt) {
  console.log('terriflux.png not found')
}


// Global variables not stored in SankeyData
// Mode, nodes and links selected, style selected...
//export const SuiteInitializeElementSelected: initializeElementSelectedType = () => { return {} as applicationStateType }

// Réinitialise data et vide les noeud/liens sélectionnés
export const SuiteInitializeReinitialization: initializeReinitializationType = () => () => { }

// Data, displayed data, default data
export const SuiteInitializeApplicationData: initializeApplicationDataType = (
  data,
  set_data,
  get_default_data,
  initial_data
) => {
  // const data_plus = data as OSPData
  // // const [master_data, set_master_data] = useState<OSPData>() // useState OK
  // // const [view, pre_set_view] = useState(data_plus.current_view) // useState OK
  // // const set_view=(s:string)=>{
  // //   data_plus.current_view=s
  // //   if(master_data){
  // //     master_data.current_view=s
  // //   }
  // //   pre_set_view(s)
  // // }
  // // const [view_not_saved,set_view_not_saved]=useState('')

  // const set_data_plus = set_data as (_: OSPData) => void
  // const plus_get_defaut_data = get_default_data as OSPGetDefaultData
  // const useOpenSankeySetDiagram = (master_data && master_data.view.length > 0) || window.SankeyToolsStatic

  // If initial data has views & has a current view then update current data to the view (and initial data become master data)
  // if (data_plus.view && data_plus.view.length > 0 && !master_data) {
  //   set_master_data({...JSON.parse(JSON.stringify(data))})
  //   if(data_plus.current_view && data_plus.current_view!=='none'){
  //     const view_to_display= GetDataFromView(data_plus,data_plus.current_view)
  //     set_view(data_plus.current_view)
  //     set_data(view_to_display as OSPData)
  //   }
  // }

  return {
    data,
    set_data,
    get_default_data,
    new_data:new Class_ApplicationDataSuite(false),
    dataVarToUpdate:useRef(['']),
    // convert_data : (data:SankeyData,DefaultSankeyData:()=>SankeyData) => {
    //   plus_convert_data(data as OSPData,DefaultSankeyData as ()=> OSPData)
    //   convert_data(data,DefaultSankeyData)
    // },
    // master_data,
    // set_master_data,
    // view,
    // set_view,
    // view_not_saved:view_not_saved,
    // set_view_not_saved:set_view_not_saved,
    // setDiagram:useOpenSankeySetDiagram?getSetDiagramFunc(set_master_data,set_view,plus_get_defaut_data ) : setDiagram,
    setDiagram: setDiagram,
    // is_catalog:false
  } as unknown as applicationDataType
}

// General functions necessay to draw the diagram
//export const SuiteInitializeApplicationDraw: initializeApplicationDrawType = () => { return {} as applicationDrawType }

// Functions necessay to draw the links
//export const SuiteInitializeLinkFunctions: initializeLinkFunctionsType = () => { return {} as LinkFunctionTypes }

// Functions necessay to draw the nodes
//export const SuiteInitializeNodeFunctions: initializeNodeFunctionsType = () => { return {} as NodeFunctionTypes }


//export const SuiteDrawAll: DrawAllType = () => { }


//export const SuiteInstallEventsOnSVG: InstallEventsOnSVGType = () => { }

// Used to update the various component of the application
//export const SuiteInitializeComponentUpdater: () => ComponentUpdaterType = () => { return {} as ComponentUpdaterType }

// Ref to some key ui element (accordion item) in the application
//export const SuiteInitializeUIElementsRef: initializeUIElementsRefType = () => { return {} as uiElementsRefType }

export const SuiteInitializeAdditionalMenus: initializeAdditionalMenusType = (
  additionalMenus,
  applicationData
) => {
  if (window.SankeyToolsStatic) {
    return
  }
  const new_data = applicationData.new_data as unknown as Class_ApplicationDataSuite
  //const suiteApplicationContext = applicationContext as unknown as suiteApplicationContextType

  // If windowSankey.SankeyToolsStatic is at true : we don't use the function useNavigate because we can't it use this function outside BrowserRouter
  // and if the app is in publication mode we aren't in one
  const navigate = useNavigate()
  const returnToApp = () => {
    navigate('/')
    applicationData.set_data({ ...applicationData.data })
  }

  // Either create a menu to select where we navigate to (login or register account)
  // or add a button to navigate to
  const btn_navigate_to_login_register_dashboard = !new_data.has_free_account? <Menu
    variant='menu_button_subnav_account_style'
    placement='bottom-end'
  >
    <MenuButton>
      <Box
        gridColumn='1'
        gridRow='1'
        justifySelf='end'
      >
        <FaUser
          style={{'height':'2rem', 'width':'2rem'}}
        />
      </Box>
      <Box
        gridColumn='2'
        gridRow='1'
        height='1rem'
        width='1rem'
        alignSelf='end'
      >
        <ChevronDownIcon
          style={{'height':'1rem', 'width':'1rem'}}
        />
      </Box>
    </MenuButton>
    <MenuList>
      <MenuItem
        onClick={() => {
          new_data.menu_configuration.function_on_wait.current = () => {
            //localStorage.setItem('data', LZString.compress(JSON.stringify((applicationData as suiteApplicationDataType).master_data)))
            localStorage.setItem('last_save', 'true')
            new_data.menu_configuration.ref_to_save_in_cache_indicator.current(true)
            navigate('/login')
            return null
          }
          new_data.menu_configuration.ref_lauchToast.current()
        }}
      >
        {new_data.t('connect')}
      </MenuItem>
      <MenuItem
        onClick={() => navigate('/register')}
      >
        {new_data.t('UserPages.to_reg')}
      </MenuItem>
    </MenuList>
  </Menu>:<Box
    alignSelf='center'
    justifySelf='center'
    display='grid'
    gridTemplateColumns='1fr 1fr'
    gridColumnGap='0.25rem'
  >
    <Button
      variant={'menutop_button_goto_dashboard'}
      onClick={() => {
        new_data.menu_configuration.function_on_wait.current = () => {
          //localStorage.setItem('data', LZString.compress(JSON.stringify((applicationData as suiteApplicationDataType).master_data)))
          localStorage.setItem('last_save', 'true')
          new_data.menu_configuration.ref_to_save_in_cache_indicator.current(true)
          navigate('/dashboard')
          return null
        }
        new_data.menu_configuration.ref_lauchToast.current()
      }}>
      <FaUser />
    </Button>
    <Button
      variant='menutop_button_logout'
      onClick={() => {
        loginOut(new_data.unsetTokens.bind(new_data), returnToApp)
      }}>
      <FaPowerOff />
    </Button>
  </Box>

additionalMenus.additional_nav_item.push(btn_navigate_to_login_register_dashboard)

// additionalMenus.cards_template = CardsTemplateBuilder(
//     appState as suiteElementsSelectedType,
//     applicationData as suiteApplicationDataType,
//     applicationContext as suiteApplicationContextType,
//     applicationData.convert_data as ConvertDataFuncType
//   )
}



// Modal Dialogs
export const SuiteModuleDialogs: module_dialogsType = (
  applicationData,
  additional_menus,
  menu_configuration_nodes_attributes,
  processFunctions
) => {
  // const active_page = useRef<Dispatch<SetStateAction<string>>>(() => null)
  // if (window.SankeyToolsStatic && window.sankey.has_welcome === false) {
  //   return []
  // }
  // return [
  //   welcomeModalBuilder(
  //     applicationContext as suiteApplicationContextType,
  //     applicationData as suiteApplicationDataType,
  //     {},
  //     dict_hook_ref_setter_show_dialog_components,
  //     applicationState.never_see_again,
  //     active_page
  //   )
  // ]
  return []
}


// Visibility states for the modal dialogs
//export const SuiteInitializeShowDialog: initializeShowDialogType = () => { return {} as dict_hook_ref_setter_show_dialog_componentsType }

// Menu opening on RMB
// export const SuiteInitializeContextMenu: () => contextMenuType = () => { return {} as contextMenuType }
// export const SuiteInitializeCloseAllMenuContext: initializeCloseAllMenuContextType = () => { return () => { } }
// export const closeAllMenu = () => { }

//- BackEnd
//export const SuiteInitializeProcessFunctions: () => processFunctionsType = () => { return {} as processFunctionsType }

//export const SuiteInitializeMenuConfiguration: initializeMenuConfigurationFuncType = () => { return [] as JSX.Element[] }

//export const SuiteInitializeKeyHandler: initializeKeyHandlerType = () => { }