import 'open-sankey/src/css/bootstrap.css'
import 'open-sankey/src/css/main.css'
import 'open-sankey/src/css/colors/red.css'
import 'sankeydev/src/css/style_elements_sankey.css'
import './css/react-quill.css'
import './css/Login.css'
import './css/Register.css'

import React, { FunctionComponent, MutableRefObject, useState } from 'react'
import { createRoot } from 'react-dom/client'
import * as d3 from 'd3'
import LZString from 'lz-string'
import './traduction'

// import {
//   SankeyData, SankeyNode, SankeyLink, applicationDataType, ComponentUpdaterType,
//   LinkFunctionTypes, NodeFunctionTypes, applicationContextType, contextMenuType,
//   applicationStateType, uiElementsRefType, dict_hook_ref_setter_show_dialog_componentsType,
//   applicationDrawType,
//   AdditionalMenusType,
//   processFunctionsType
// } from 'open-sankey/src/types/Types'
// import { NodeTooltipsContentFType } from 'open-sankey/src/draw/types/SankeyTooltipTypes'
// import { GetSankeyMinWidthAndHeightFuncType } from 'open-sankey/src/configmenus/types/SankeyUtilsTypes'
import {
  initializeAdditionalMenus,
  initializeReinitialization,
  moduleDialogs, 
  DefaultSankeyData,
  SankeyApp,
  OpenSankeyDiagramSelector,
  ClickSaveDiagram,
  initializeApplicationData,
  initializeMenuConfiguration
} from './import/OpenSankey'
// import {
//   OSDevinitializeApplicationData, OSDevInitializeElementSelected,
//   OSDevInitializeApplicationDraw,
//   OSDevInitializeShowDialog,
//   OSDevInitializeProcessFunctions,
//   OSDevInitializeContextMenu,
//   OSDevInitializeUIElementsRef,
//   OSDevInitializeNodeFunctions,
//   OSDevInitializeAdditionalMenus,
//   OSDevInstallEventsOnSVG,
//   OSDevDrawAll,
//   OSDevInitializeReinitialization,
//   OSDevInitializeApplicationContext,
//   OSDevInitializeComponentUpdater,
//   OSDevInitializeLinkFunctions,
//   OSDevModuleDialogs,
//   blockers_suite_sankey,
//   SankeyDevClickSaveDiagram,
//   dev_convert_data
// } from './import/SankeyDev'
import {
  OSPInitializeApplicationData,
  // DefaultOSPStyleLink,
  OSPInitializeAdditionalMenus,
  OSPModuleDialogs,
  OSPInitializeReinitialization,
  DefaultOSPStyleLink,
} from './import/SankeyPlus'
import {
//   AFMInitializeApplicationContext, AFMInitializeElementSelected, AFMInitializeApplicationDraw, AFMInitializeShowDialog,
//   AFMInitializeComponentUpdater, AFMInitializeReinitialization, AFMInitializeContextMenu, AFMInitializeUIElementsRef,
//   AFMInitializeLinkFunctions, 
AFMInitializeAdditionalMenus,
AFMModuleDialogs
//, AFMinitializeApplicationData,
//   AFMInstallEventsOnSVG,
//   AFMDrawAll,
//   AFMModuleDialogs,
//   AFMInitializeNodeFunctions
} from './import/MFASankey'
import {
  OSPDataVar, OSPNode, OSPLink
} from 'sankeyanimation/types/Types'

import Register from './components/Register/Register'
import Login from './components/Login/Login'
import Account from './components/UserPages/Account'
import Dashboard from './components/UserPages/Dashboard'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import i18next from './traduction'
//import { SuiteSankeyData } from '../types/Types'
//import { AFMSankeyData } from 'afmsankey/types/Types'
// // SankeyDev js-code
//import { ConvertDataFuncType } from 'open-sankey/src/configmenus/types/SankeyConvertTypes'
import {
  SuiteInitializeAdditionalMenus, SuiteInitializeApplicationData,
  SuiteInitializeReinitialization,SuiteModuleDialogs
} from './SankeySuiteModule'
// import { applicationState_devVar } from 'sankeydev/types/Types'
import { SaveDiagramOptionsType } from 'open-sankey/src/dialogs/types/SankeyPersistenceTypes'
import { ChakraProvider } from '@chakra-ui/react'
import { opensankey_theme } from 'open-sankey/dist/chakra/Theme'
import { OSPUpdateMenuConf } from 'sankeyanimation/dist/src/OSPModule'
import { processFunctionsType } from 'open-sankey/src/types/LegacyType'
import { Class_ApplicationData } from 'open-sankey/src/types/ApplicationData'
import { Class_ApplicationDataSuite } from './Types/ApplicationDataSuite'
import { OSDevInitializeAdditionalMenus } from './import/SankeyDev'

declare const window: Window &
  typeof globalThis & {
    SankeyToolsStatic: boolean
    sankey: {
      filiere?: string,
      header?: string,
      has_header?: boolean,
      footer?: boolean,
      logo_width?: number,
      excel?: string,
      publish?: boolean
      logo?: string
    }
  }

window.React = React
i18next.changeLanguage(navigator.language.includes('fr') ? 'fr' : 'en')

const fetchData = {
  method: 'POST'
}

const container = document.getElementById('react-container') as Element | DocumentFragment
const root = createRoot(container)



let exemple_menu = {} as { [_: string]: JSX.Element }
let formations_menu = {} as { [_: string]: JSX.Element }

if (!window.SankeyToolsStatic) {
  // Menus are not presents in mode publish
  const path = window.location.origin
  const url = path + '/opensankey/sankey/menu_examples'
  fetch(url, fetchData).then(response => {
    response.text().then(text => {
      const json_data = JSON.parse(text)
      exemple_menu = json_data.exemples_menu
      if (Object.keys(json_data.exemples_menu['Formations']).length > 0) {
        formations_menu = Object.fromEntries(
          Object.entries(json_data.exemples_menu['Formations']['Tutoriels']).filter(d => d[0] !== 'artefacts')
        ) as { [_: string]: JSX.Element }
        delete json_data.exemples_menu['Formations']['Tutoriels']
      }
    }).catch(() => {
      exemple_menu = {}
      formations_menu = {}
    }).then(() => {
      renderPage()
    })
  })
}

const get_default_data = () => {
  const os_var = DefaultSankeyData()

  const osp_var: OSPDataVar = {
    nodes: os_var.nodes as { [x: string]: OSPNode },
    links: os_var.links as { [x: string]: OSPLink },
    is_catalog: false,
    view: [],
    current_view: 'none',
    labels: {},
    icon_catalog: {},
    style_link: { 'default': DefaultOSPStyleLink() },
    background_image: '',
    show_background_image: false,
    style_node: os_var.style_node
  }
  Object.assign(os_var, osp_var)

  const AFM_var = {
    show_uncert: false,


    units_names: [[]],
    tooltip_names: []
  }
  Object.assign(os_var, AFM_var)

  const Dev_var = {
    unitary_node: [],
    process_transfo_ref: {},
    unit_link_value_display: 'percent'
  }
  Object.assign(os_var, Dev_var)

  return os_var
}

// const suite_converter = (data: SuiteSankeyData, DefaultData: () => SuiteSankeyData) => {
//   plus_convert_data(data, DefaultData)
//   dev_convert_data(data)
//   convert_data(data, DefaultData)
//   const special_data_cast = (data as AFMSankeyData)
//   if (special_data_cast.free_null_link_visible === undefined) {
//     special_data_cast.free_null_link_visible = true
//   }
// }

const SuiteRootCommponent: FunctionComponent = () => {
  const [update, set_update] = useState<boolean>(true) // useState OK

  const data = get_default_data() //as SuiteSankeyData

  // If the app is in edition mode then go search the data in localStorage
  // Search if a data is stored in localStorage of the navigator
  const json_data = LZString.decompress(localStorage.getItem('data') as string) as string

  // if (json_data !== null && json_data !== 'undefined' && json_data !== '') {
  //   const new_data = JSON.parse(json_data)
  //   Object.assign(data, new_data)
  //   suite_converter(data, get_default_data as () => SuiteSankeyData)
  // }

  // if (window.sankey && window.sankey.filiere) {
  //   Object.assign(data, window.sankey.filiere)
  //   complete_sankey_data(data, DefaultSankeyData, DefaultNode, DefaultLink)
  //   suite_converter(data, get_default_data as () => SuiteSankeyData)
  // }
  // ==================
  //  CREATION VARIABLE
  // ==================
  //- login info
  //const [,set_name_user] = useState(username) // useState OK TODO check ?
  // For SankeyDev
  const [blocker_token_OSP, set_blocker_token_OSP] = useState(false) // useState OK
  const [blocker_token_SSM, set_blocker_token_SSM] = useState(false) // useState OK
  const suiteApplicationData = new Class_ApplicationDataSuite(false)
  const blockers = {}/*suiteApplicationContext.has_sankeydev ? blockers_suite_sankey(
    blocker_token_OSP, set_blocker_token_OSP, blocker_token_SSM, set_blocker_token_SSM) : {}*/


  // let logo_application=logo
  const Application = <SankeyApp
    initial_sankey_data={data}
    get_default_data={get_default_data}
    // initializeApplicationContext={
    //   () => {
    //     const _ = initializeApplicationContext() as suiteApplicationContextType
    //     Object.assign(_, OSPInitializeApplicationContext())
    //     Object.assign(_, AFMInitializeApplicationContext())
    //     Object.assign(_, OSDevInitializeApplicationContext())
    //     Object.assign(_, SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM))
    //     if (window.SankeyToolsStatic) {
    //       _.has_open_sankey_plus = true
    //       _.has_sankey_suite = true
    //       _.logo = window.sankey && window.sankey.logo ? window.sankey.logo : ''
    //       _.logo_terriflux = 'logo_terriflux.png'
    //     }
    //     return _
    //   }
    // }
    initializeApplicationData={
      (
        data,
        set_data,
        get_default_data,
        initial_data
      ) => {
        return SuiteInitializeApplicationData(data, set_data, get_default_data, initial_data)
      }
    }
    // initializeElementSelected={
    //   () => {
    //     return {
    //       ...initializeElementSelected(),
    //       ...OSPInitializeElementSelected(),
    //       ...AFMInitializeElementSelected(),
    //       ...OSDevInitializeElementSelected(),
    //       ...SuiteInitializeElementSelected()
    //     }
    //   }
    // }
    initializeMenuConfiguration={
      (
        applicationData,
        additional_menus,
        config_link_data,
        config_link_attr,
        menu_configuration_nodes_attributes,
      )=>{
        const menu_conf= initializeMenuConfiguration(
          applicationData,
          additional_menus,
          config_link_data,
          config_link_attr,
          menu_configuration_nodes_attributes,
        )
        OSPUpdateMenuConf(menu_conf,applicationData)
        return menu_conf

      }
    }
    // initializeApplicationDraw={
    //   (
    //     applicationData: applicationDataType,
    //     applicationState: applicationStateType,
    //     contextMenu: contextMenuType,
    //     applicationContext: applicationContextType,
    //     ComponentUpdater: ComponentUpdaterType,
    //     uiElementsRef: uiElementsRefType,
    //     node_function: NodeFunctionTypes,
    //     link_function: LinkFunctionTypes,
    //     start_point: { current: number[]; },
    //     resizeCanvas: () => void,
    //     ref_alt_key_pressed?: MutableRefObject<boolean>
    //   ) => {
    //     const _ = initializeApplicationDraw(
    //       applicationData, applicationState, contextMenu,
    //       applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
    //       start_point, resizeCanvas
    //     )
    //     Object.assign(_, OSPInitializeApplicationDraw(
    //       applicationData, applicationState, contextMenu,
    //       applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
    //       start_point, resizeCanvas
    //     ));
    //     (_ as OSPApplicationDrawType).reDrawOSPLabels = (object_to_update: OSPLabel[]) => {
    //       OSPDrawLabels(
    //         applicationData as OSPApplicationDataType,
    //         applicationState as OSPElementsSelectedType,
    //         uiElementsRef as OSPUiElementsRefType,
    //         contextMenu as OSPContextMenuType,
    //         applicationContext as OSPApplicationContextType,
    //         sankey_plus_min_width_and_height,
    //         contextMenu.closeAllMenuContext,
    //         _ as unknown as OSPApplicationDrawType,
    //         ComponentUpdater as OSPComponentUpdaterType,
    //         object_to_update,
    //         link_function,
    //         start_point,
    //         resizeCanvas
    //       )
    //       ComponentUpdater.updateComponenSaveInCache.current(false)
    //     }
    //     OSPNodeDragEvent(
    //       applicationData as OSPApplicationDataType,
    //       applicationState as OSPElementsSelectedType,
    //       applicationContext as OSPApplicationContextType,
    //       ref_alt_key_pressed?.current ?? false,
    //       ComponentUpdater,
    //       (node_function as OSPNodeFuntionType),
    //       link_function,
    //       _ as unknown as OSPApplicationDrawType
    //     )
    //     Object.assign(_, AFMInitializeApplicationDraw(
    //       applicationData, applicationState, contextMenu,
    //       applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
    //       start_point, resizeCanvas
    //     ))
    //     Object.assign(_, OSDevInitializeApplicationDraw(
    //       applicationData, applicationState, contextMenu,
    //       applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
    //       start_point, resizeCanvas
    //     ))
    //     Object.assign(_, SuiteInitializeApplicationDraw(
    //       applicationData, applicationState, contextMenu,
    //       applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
    //       start_point, resizeCanvas
    //     ))
    //     return _
    //   }
    // }
    // initializeShowDialog={
    //   () => {
    //     return {
    //       ...initializeShowDialog(),
    //       ...OSPInitializeShowDialog(),
    //       ...AFMInitializeShowDialog(),
    //       ...OSDevInitializeShowDialog(),
    //       ...SuiteInitializeShowDialog()
    //     }
    //   }
    // }
    // initializeComponentUpdater={
    //   () => {
    //     return {
    //       ...initializeComponentUpdater(),
    //       ...OSPInitializeComponentUpdater(),
    //       ...AFMInitializeComponentUpdater(),
    //       ...OSDevInitializeComponentUpdater(),
    //       ...SuiteInitializeComponentUpdater()
    //     }
    //   }
    // }
    initializeReinitialization={
      (
        applicationData
      ) => {
        return (() => {
          initializeReinitialization(applicationData)()
          OSPInitializeReinitialization(applicationData)()
          // AFMInitializeReinitialization(applicationData)()
          // OSDevInitializeReinitialization(applicationData)()
          SuiteInitializeReinitialization(applicationData)()
        })
      }
    }
    // closeAllMenu={
    //   (
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     contextMenu: contextMenuType
    //   ) => {
    //     //TODO
    //     return closeAllMenu(dict_hook_ref_setter_show_dialog_components, contextMenu)
    //   }
    // }
    // initializeProcessFunctions={
    //   (
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType
    //   ) => {
    //     return {
    //       ...initializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
    //       //...OSPinitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
    //       //...AFMInitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
    //       ...OSDevInitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
    //       ...SuiteInitializeProcessFunctions(),
    //     }
    //   }
    // }
    // initializeContextMenu={
    //   () => {
    //     return {
    //       ...initializeContextMenu(),
    //       ...OSPInitializeContextMenu(),
    //       ...AFMInitializeContextMenu(),
    //       ...OSDevInitializeContextMenu(),
    //       ...SuiteInitializeContextMenu()
    //     }
    //   }
    // }
    // initializeUIElementsRef={
    //   () => {
    //     return {
    //       ...initializeUIElementsRef(),
    //       ...OSPInitializeUIElementsRef(),
    //       ...AFMInitializeUIElementsRef(),
    //       ...OSDevInitializeUIElementsRef(),
    //       ...SuiteInitializeUIElementsRef()
    //     }
    //   }
    // }
    // initializeLinkFunctions={
    //   (
    //     applicationData: applicationDataType,
    //     applicationState: applicationStateType,
    //     contextMenu: contextMenuType,
    //     applicationContext: applicationContextType,
    //     ComponentUpdater: ComponentUpdaterType,
    //     uiElementsRef: uiElementsRefType,
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     ref_alt_key_pressed: React.MutableRefObject<boolean>
    //   ) => {
    //     const _ = initializeLinkFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext,
    //       ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
    //     )
    //     Object.assign(_, OSPInitializeLinkFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext,
    //       ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
    //     ))
    //     Object.assign(_, AFMInitializeLinkFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext,
    //       ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
    //     ))
    //     Object.assign(_, OSDevInitializeLinkFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext,
    //       ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
    //     ))
    //     Object.assign(_, SuiteInitializeLinkFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext,
    //       ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
    //     ))
    //     return _
    //   }
    // }
    // initializeNodeFunctions={
    //   (
    //     applicationData: applicationDataType,
    //     applicationState: applicationStateType,
    //     contextMenu: contextMenuType,
    //     applicationContext: applicationContextType,
    //     ComponentUpdater: ComponentUpdaterType,
    //     uiElementsRef: uiElementsRefType,
    //     resizeCanvas: (_: applicationDataType) => void,
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     ref_alt_key_pressed: React.MutableRefObject<boolean>,
    //     accept_simple_click: React.MutableRefObject<boolean>,
    //     recomputeDisplayedElement: () => void,
    //     link_function: LinkFunctionTypes
    //   ) => {
    //     const _ = initializeNodeFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
    //       uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
    //       recomputeDisplayedElement, link_function
    //     )
    //     Object.assign(_, OSPInitializeNodeFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
    //       uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
    //       recomputeDisplayedElement, link_function
    //     ))
    //     Object.assign(_, AFMInitializeNodeFunctions(
    //       applicationData,applicationState,contextMenu,applicationContext,ComponentUpdater,
    //       uiElementsRef,resizeCanvas,dict_hook_ref_setter_show_dialog_components,ref_alt_key_pressed,accept_simple_click,
    //       recomputeDisplayedElement,link_function
    //     ))
    //     Object.assign(_, OSDevInitializeNodeFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
    //       uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
    //       recomputeDisplayedElement, link_function
    //     ))
    //     Object.assign(_, SuiteInitializeNodeFunctions(
    //       applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
    //       uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
    //       recomputeDisplayedElement, link_function
    //     ))
    //     return _
    //   }
    // }
    initializeAdditionalMenus={
      (
        additionalMenus,
        applicationData,
        processFunctions,
        Reinitialization
      ) => {
        //const new_data = applicationData.new_data as unknown as Class_ApplicationDataSuite
        SuiteInitializeAdditionalMenus(
          additionalMenus,
          applicationData,
          processFunctions,
          Reinitialization
        )

        initializeAdditionalMenus(
          additionalMenus,
          applicationData,
          processFunctions,
          Reinitialization
        )
        //const suiteApplicationContext = SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM)

        if (suiteApplicationData.has_sankey_plus) {
          OSPInitializeAdditionalMenus(
            additionalMenus,applicationData,processFunctions,Reinitialization
          )
        }
        if (suiteApplicationData.has_sankey_suite) {
          AFMInitializeAdditionalMenus(
            additionalMenus,applicationData,processFunctions,Reinitialization
          )
        }
        additionalMenus.formations_menu = formations_menu
        if (suiteApplicationData.has_sankey_dev) {
          additionalMenus.example_menu = exemple_menu
          OSDevInitializeAdditionalMenus(
            additionalMenus,
            applicationData,
            processFunctions,
            Reinitialization
          )
        }
      }
    }
    moduleDialogs={
      (
        applicationData,
        additional_menus,
        menu_configuration_nodes_attributes,
        processFunctions:processFunctionsType
      ) => {
        return [
          ...moduleDialogs(
            applicationData,
            additional_menus,
            menu_configuration_nodes_attributes,
            processFunctions
          ),
          ...OSPModuleDialogs(
            applicationData,
            additional_menus,
            menu_configuration_nodes_attributes,
            processFunctions
          ),
          ...AFMModuleDialogs(
            applicationData,
            additional_menus,
            menu_configuration_nodes_attributes,
            processFunctions
          ),
          // ...OSDevModuleDialogs(
          //   applicationContext,
          //   applicationData,
          //   applicationState,
          //   contextMenu,
          //   applicationDraw,
          //   uiElementsRef,
          //   dict_hook_ref_setter_show_dialog_components,
          //   node_function,
          //   link_function,
          //   ComponentUpdater,
          //   additional_menus,
          //   menu_configuration_nodes_attributes,
          //   applicationDraw.reDrawLegend,
          //   processFunctions
          // ),
          ...SuiteModuleDialogs(
            applicationData,
            additional_menus,
            menu_configuration_nodes_attributes,
            processFunctions
          )
        ]
      }
    }
    // DrawAll={
    //   (
    //     contextMenu: contextMenuType,
    //     applicationData: applicationDataType,
    //     uiElementsRef: uiElementsRefType,
    //     applicationState: applicationStateType,
    //     applicationContext: applicationContextType,
    //     alt_key_pressed: MutableRefObject<boolean>,
    //     accept_simple_click: { current: boolean },
    //     link_function: LinkFunctionTypes,
    //     ComponentUpdater: ComponentUpdaterType,
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     node_function: NodeFunctionTypes,
    //     GetSankeyMinWidthAndHeight: GetSankeyMinWidthAndHeightFuncType,
    //     applicationDraw: applicationDrawType
    //   ) => {
    //     // Call the function that add links to the sankey
    //     d3.selectAll(' .opensankey #svg #sankey_def').remove()
    //     d3.select(' .opensankey #svg').append('defs').attr('id', 'sankey_def');
    //     [DrawAll, OSPDrawAll, AFMDrawAll, OSDevDrawAll, SuiteDrawAll].forEach(
    //       _ => _(
    //         contextMenu,
    //         applicationData,
    //         uiElementsRef,
    //         applicationState,
    //         applicationContext,
    //         alt_key_pressed,
    //         accept_simple_click,
    //         link_function,
    //         ComponentUpdater,
    //         dict_hook_ref_setter_show_dialog_components,
    //         node_function,
    //         GetSankeyMinWidthAndHeight,
    //         applicationDraw
    //       )
    //     )
    //   }
    // }
    // installEventOnSVG={
    //   (
    //     contextMenu: contextMenuType,
    //     applicationContext:applicationContextType,
    //     applicationData: applicationDataType,
    //     uiElementsRef: uiElementsRefType,
    //     applicationState: applicationStateType,
    //     link_function: LinkFunctionTypes,
    //     ComponentUpdater: ComponentUpdaterType,
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     node_function: NodeFunctionTypes,
    //     applicationDraw: applicationDrawType
    //   ) => {
    //     [InstallEventsOnSVG, OSPInstallEventsOnSVG, AFMInstallEventsOnSVG, OSDevInstallEventsOnSVG, SuiteInstallEventsOnSVG].forEach(
    //       _ => _(
    //         contextMenu,
    //         applicationContext,
    //         applicationData,
    //         uiElementsRef,
    //         applicationState,
    //         link_function,
    //         ComponentUpdater,
    //         dict_hook_ref_setter_show_dialog_components,
    //         node_function,
    //         applicationDraw
    //       )
    //     )
    //   }
    // }
    // initializeKeyHandler={
    //   (applicationData: applicationDataType,
    //     uiElementsRef: uiElementsRefType,
    //     contextMenu: contextMenuType,
    //     e: KeyboardEvent,
    //     applicationState: applicationStateType,
    //     closeAllMenu: () => void,
    //     ref_alt_key_pressed: MutableRefObject<boolean>,
    //     accept_simple_click: { current: boolean },
    //     link_function: LinkFunctionTypes,
    //     ComponentUpdater: ComponentUpdaterType,
    //     dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
    //     applicationContext: applicationContextType,
    //     node_function: NodeFunctionTypes,
    //     applicationDraw: applicationDrawType
    //   ) => {
    //     initializeKeyHandler(
    //       applicationData,
    //       uiElementsRef,
    //       contextMenu,
    //       e,
    //       applicationState,
    //       closeAllMenu,
    //       ref_alt_key_pressed,
    //       accept_simple_click,
    //       link_function,
    //       ComponentUpdater,
    //       dict_hook_ref_setter_show_dialog_components,
    //       applicationContext,
    //       node_function,
    //       applicationDraw
    //     )
    //     OSPInitializeKeyHandler(
    //       applicationContext as OSPApplicationContextType,
    //       e,
    //       applicationData as OSPApplicationDataType,
    //       applicationState as OSPElementsSelectedType,
    //       dict_hook_ref_setter_show_dialog_components as OSPShowMenuComponentsType,
    //       (applicationDraw as OSPApplicationDrawType).reDrawOSPLabels,
    //       ComponentUpdater as OSPComponentUpdaterType
    //     )
    //     //AFMInitializeKeyHandler //TODO
    //     //OSDevInitializeKeyHandler //TODO
    //     SuiteInitializeKeyHandler(
    //       applicationData,
    //       uiElementsRef,
    //       contextMenu,
    //       e,
    //       applicationState,
    //       closeAllMenu,
    //       ref_alt_key_pressed,
    //       accept_simple_click,
    //       link_function,
    //       ComponentUpdater,
    //       dict_hook_ref_setter_show_dialog_components,
    //       applicationContext,
    //       node_function,
    //       applicationDraw
    //     )
    //   }
    // }
    initializeDiagrammSelector={(applicationData) => {
      // const ospDiagrammeSelector = OSPDiagramSelector(
      //   applicationData as OSPApplicationDataType
      // )
      // const suiteApplicationContext = SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM)
      // if (suiteApplicationContext.has_open_sankey_plus) {
      //   return ospDiagrammeSelector
      // } else {
        return OpenSankeyDiagramSelector
      //}
    }}
    ClickSaveDiagram={
      (
        applicationData: Class_ApplicationData,
        // applicationState:applicationStateType,
        options:SaveDiagramOptionsType
      ) => {
        // const getter = elementsSelected as OSPElementsSelectedType & applicationState_devVar
        // // if(view !== 'none' && has_open_sankey_plus) {
        // //   // If views are saved the user is asked to save the view before saving the whole diagram.
        // //   const diff = CheckCurrentViewSaved(master_data, data, master_data.current_view)
        // //   if(diff.length>0 && !window.SankeyToolsStatic){
        // //     set_view_not_saved(view)
        // //     return
        // //   }
        // // }
        // const { master_data } = applicationData as OSPApplicationDataType
        // const OSPElementsSelected = elementsSelected as OSPElementsSelectedType
        // let data_to_save = data as OSPData
        // if (master_data && (master_data.view.length > 0)) {
        //   //if views are present there are two cases. If save only view data is saved otherwise master data is saved.
        //   if (OSPElementsSelected.saveViewGetter.current) {
        //     data_to_save.current_view = 'none'
        //   } else {
        //     data_to_save = master_data
        //   }
        // }
        // if (getter.ref_getter_beautify.current) {
        //   SankeyDevClickSaveDiagram(
        //     applicationData,
        //     data_to_save,
        //     elementsSelected,
        //     options
        //   )
        // } else {
          ClickSaveDiagram(
            applicationData,
            //data_to_save,
            // elementsSelected,
            options
          )
        //}
      }
    }
    //InitalizeSelectorDetailNodes={OSPInitalizeSelectorDetailNodes}
    //GetSankeyMinWidthAndHeight={suiteApplicationContext.has_open_sankey_plus ? sankey_plus_min_width_and_height : GetSankeyMinWidthAndHeight}
  />

  if (window.SankeyToolsStatic) {
    return Application
  }

  return <ChakraProvider resetCSS={false} theme={opensankey_theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/register' element={
          <Register
            navbar_logo={suiteApplicationData.logo}
            update={update}
            set_update={set_update}
          />}
        />
        <Route path='/login' element={
          <Login
            navbar_logo={suiteApplicationData.logo}
            update={update}
            set_update={set_update}

          />}
        />
        <Route path='/dashboard' element={
          <Dashboard
            navbar_logo={suiteApplicationData.logo}

            exemple_menu={exemple_menu}
            update={update}
            set_update={set_update}
            suiteApplicationData={suiteApplicationData}
            //suite_convert_data={suite_converter as ConvertDataFuncType}
            //SuiteDefaultData={get_default_data}
          />}
        />
        <Route path='/account' element={
          <Account
            navbar_logo={suiteApplicationData.logo}
            suiteApplicationData={suiteApplicationData}
            blocker_suite_sankey={blockers}
            update={update}
            set_update={set_update}
          />}
        />
        <Route path='/' element={Application} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
}

const renderPage = () => {
  root.render(<SuiteRootCommponent />)
}

if (window.SankeyToolsStatic) {
  renderPage()
}