import { AFMEditionMenu /*, processTrade,*/ } from './lib/AFMSankeyMenu';
//import { FreeVariables } from './lib/FreeVariables'
import React from 'react';
import { SupplyUseModelisationProd } from './lib/SankeyReconciliation';
import { initializeProcessFunctions } from './import/OpenSankey';
export const AFMDefaultData = () => {
    return {};
};
// export const AFMInitializeApplicationContext : initializeApplicationContextType = ()=>{
//   return {
//     url_prefix:'/sankeytools/'
//   } as unknown as applicationContextType
// }
export const AFMInitializeApplicationData = () => {
    return {};
};
// export const AFMInitializeShowDialog : AFMInitializeShowDialogType = ()=>{
//   return {
//     ref_setter_show_reconciliation:useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//     ref_setter_show_split_trade:useRef<Dispatch<SetStateAction<boolean>>>(()=>null),
//   }
// }
// export const AFMcloseAllMenu = closeAllMenu
// Modify Application Draw
// export const AFMInitializeApplicationDraw : initializeApplicationDrawType= () => {
//   const _ = {
//   }
//   return _ as unknown as applicationDrawType
// }
// export const AFMInitializeComponentUpdater : initializeComponentUpdaterType  = ()=> {
//   const _ = {
//   }
//   return _ as unknown as ComponentUpdaterType
// }
export const AFMInitializeReinitialization = () => () => {
};
export const AFMInitializeProcessFunctions = initializeProcessFunctions;
// Modify context menu
// export const AFMInitializeContextMenu : ()=> contextMenuType = ()=> {
//   return {} as contextMenuType
// }
// Modify Ref used to open accordion item
// export const AFMInitializeUIElementsRef : initializeUIElementsRefType = () => {
//   return {
//   } as unknown as uiElementsRefType
// }
// export const AFMInitializeLinkFunctions : initializeLinkFunctionsType = () => {
//   return {
//     LinkText:AFMLinkText,
//     LinkTooltipsContent:AFMLinkTooltipsContent
//   } as unknown as LinkFunctionTypes
// }
// export const AFMInitializeNodeFunctions : initializeNodeFunctionsType = () => {
//   return {
//     postProcessLoadExcel:processTrade,
//     NodeTooltipsContent:AFMNodeTooltipsContent
//   } as unknown as NodeFunctionTypes
// }
export const AFMInitializeAdditionalMenus = (additional_menus, applicationData, processFunctions, Reinitialization) => {
    const { new_data } = applicationData;
    const afmData = new_data;
    // const {units_names} = applicationData.data as AFMSankeyData
    // let units = undefined
    // if (units_names && units_names.length > 0 && units_names[0].length > 0) {
    //   units = [units_names[0][0], 'Naturelles']
    // }
    // additionalMenus.additional_link_appearence_items.push(<FreeVariables
    //   t={applicationContext.t}
    //   applicationData={applicationData as AFMApplicataionDataType}
    //   applicationState={applicationState}
    //   node_function={node_function}
    //   link_function={link_function}
    // />)
    // Configuration Menu
    // additionalMenus.additional_configuration_menus.push(<AFMMenuConfigurationImportExport
    //   applicationData={applicationData as AFMApplicataionDataType}
    //   t={applicationContext.t}
    //   node_function={node_function}
    //   link_function={link_function}
    // />)
    // addon_link_visual_filter_toolbar.push(displayFNL)
    if (!window.SankeyToolsStatic) {
        additional_menus.sankey_menus['afm'] = React.createElement(AFMEditionMenu, { applicationData: afmData });
    }
    // additionalMenus.sankey_menus['unité'] = <UnitsSelector
    //   data={applicationData.data as AFMSankeyData}
    //   set_data={applicationData.set_data as (d:AFMSankeyData)=>void}
    //   units={units as string[]}
    // />
};
export const AFMModuleDialogs = (applicationData, additional_menus, menu_configuration_nodes_attributes, processFunctions) => {
    const { new_data } = applicationData;
    const afmData = new_data;
    // const AFMAppData=applicationData as AFMApplicataionDataType
    // const AFMElementSelected=(applicationState as AFMElementSelectedType)
    // const AFMShowDialog=(dict_hook_ref_setter_show_dialog_components as AFMSankeyShowMenuComponentsType)
    const module_dialogs = [];
    module_dialogs.push(React.createElement(SupplyUseModelisationProd, { applicationData: afmData, postProcessLoadExcel: () => null, launch: processFunctions.launch }));
    // module_dialogs.push(
    //   <SplitVerticalModal
    //     data = {applicationData.data as AFMSankeyData}
    //     ref_setter_show_split_trade = {
    //       (dict_hook_ref_setter_show_dialog_components as AFMSankeyShowMenuComponentsType).ref_setter_show_split_trade
    //     } />
    // )
    return module_dialogs;
};
// export const AFMDrawAll : DrawAllType = ()=>{
// }
// export const AFMInstallEventsOnSVG : InstallEventsOnSVGType = () => {}
