// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { default_main_sankey_id } from './Sankey';
import { getBooleanFromJSON } from './Utils';
// CONSTANT *****************************************************************************
const const_default_position_x = 50;
const const_default_position_y = 50;
// SPECIFIC FUNCTIONS *******************************************************************
// Nothing ...
// CLASS ELEMENT ************************************************************************
/**
 * Class that define a meta element to display on drawing area
 *
 * @class Class_ProtoElement
 */
export class Class_ProtoElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Element.
     * @param {string} id
     * @param {Class_DrawingArea} drawing_area
     * @param {string} svg_group
     * @memberof Class_Element
     */
    constructor(id, menu_config, svg_group) {
        // PUBLIC ATTRIBUTES ==================================================================
        /**
         * D3 selection that contains related svg element
         * @type {(d3.Selection<SVGGElement, Class_Element, SVGGElement, unknown> | null)}
         * @memberof Class_Element
         */
        this.d3_selection = null;
        /**
         * List of Sankey in which element appear
         *
         * @private
         * @type {Class_Sankey[]}
         * @memberof Class_ProtoElement
         */
        this._sankeys = {};
        /**
         * Is element currently visually selected
         * @protected
         * @type {boolean}
         * @memberof Class_Element
         */
        this._is_selected = false;
        /**
         * Is element currently drawn
         * @protected
         * @type {boolean}
         * @memberof Class_Element
         */
        this._is_visible = true;
        /**
         * Is mouse cursor over element d3 selection (default=false)
         * @protected
         * @type {boolean}
         * @memberof Class_Element
         */
        this._is_mouse_over = false;
        /**
         * Is this element grabbed by mouse (default=false)
         * @protected
         * @type {boolean}
         * @memberof Class_Element
         */
        this._is_mouse_grabbed = false;
        /**
         * True if element is currently on a deletion process
         * Avoid cross calls of delete() method
         * @private
         * @memberof Class_Element
         */
        this._is_currently_deleted = false;
        this._id = id;
        this._svg_group = svg_group;
        this._menu_config = menu_config;
    }
    /**
     * Define deletion behavior
     * @memberof Class_Element
     */
    delete() {
        if (this._is_currently_deleted === false) {
            // Set deletion boolean to true
            this._is_currently_deleted = true;
            // Remove from drawing area
            this.unDraw();
            // Abstract method for cleaning relations between elements
            this.cleanForDeletion();
        }
    }
    cleanForDeletion() {
        // Does nothing here
    }
    // PUBLIC METHODS ====================================================================
    /**
     * Set up element on d3 svg area
     * @protected
     * @memberof Class_Element
     */
    draw() {
        if (!this._is_currently_deleted) {
            const d3_drawing_area = this.drawing_area.d3_selection;
            if (d3_drawing_area !== null) {
                // Undraw all
                this.unDraw();
                // Draw only if visible
                if (this.is_visible) {
                    // Set d3 selection
                    this.d3_selection = d3_drawing_area.selectAll(' #' + this._svg_group)
                        .datum(this)
                        .append('g')
                        .attr('id', 'gg_' + this._id);
                    // Add events listeners
                    this.setEventsListeners();
                }
            }
        }
    }
    /**
     * Set up events related to element d3_element
     * @protected
     * @memberof Class_Element
     */
    setEventsListeners() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (!this._display.drawing_area.static) {
            // Right mouse button clicks
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.on('click', (event) => this.eventSimpleLMBCLick(event));
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.on('dblclick', (event) => this.eventDoubleLMBCLick(event));
            // Right mouse button maintained
            (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.on('mousedown', (event) => this.eventMaintainedClick(event));
            (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.on('mouseup', (event) => this.eventReleasedClick(event));
            // Mouse cursor goes over this
            (_e = this.d3_selection) === null || _e === void 0 ? void 0 : _e.on('mouseover', (event) => this.eventMouseOver(event));
            (_f = this.d3_selection) === null || _f === void 0 ? void 0 : _f.on('mouseout', (event) => this.eventMouseOut(event));
            // Mouse cursor move
            (_g = this.d3_selection) === null || _g === void 0 ? void 0 : _g.on('mousemove', (event) => this.eventMouseMove(event));
            // Left mouse button click
            (_h = this.d3_selection) === null || _h === void 0 ? void 0 : _h.on('contextmenu', (event) => this.eventSimpleRMBCLick(event));
            // Drag events TODO
            // Changed call of drag, we have to use only on time call because otherwise each .call erase the previous .call event
            if (this.drawing_area.isInSelectionMode()) {
                (_j = this.d3_selection) === null || _j === void 0 ? void 0 : _j.call(d3.drag()
                    .on('start', (event) => this.eventMouseDragStart(event))
                    .on('drag', (event) => this.eventMouseDrag(event))
                    .on('end', (event) => this.eventMouseDragEnd(event)));
            }
        }
    }
    /**
     * Convert element to JSON
     *
     * @return {*}
     * @memberof Class_NodeElement
     */
    toJSON() {
        // Init output JSON
        const json_object = {};
        // Fill data
        json_object['is_visible'] = this._is_visible;
        json_object['is_selected'] = this._is_selected;
        // Return
        return json_object;
    }
    /**
     * Apply json to element
     *
     * @param {Type_JSON} json_object
     * @memberof Class_NodeElement
     */
    fromJSON(json_object) {
        this._is_visible = getBooleanFromJSON(json_object, 'is_visible', this._is_visible);
        this._is_selected = getBooleanFromJSON(json_object, 'is_selected', this._is_selected);
    }
    // PROTECTED METHODES =================================================================
    /**
     * Unset element from d3 svg area
     * @protected
     * @memberof Class_Element
     */
    unDraw() {
        if (this.d3_selection !== null) {
            this.d3_selection.remove();
            this.d3_selection = null;
        }
    }
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventSimpleLMBCLick(_event) {
        // Clear tooltips presents
        d3.selectAll('.sankey-tooltip').remove();
        // TODO do something
    }
    /**
     * Deal with double left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventDoubleLMBCLick(_event) {
        // TODO Ajouter déclemenchement editeur nom de noeud
    }
    /**
     * Deal with simple right Mouse Button (RMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventSimpleRMBCLick(_event) {
        // Clear tooltips presents
        d3.selectAll('.sankey-tooltip').remove();
        // TODO Ajouter ouverture menu contextuel (clic droit) sur noeud
    }
    /**
     * Define maintained left mouse button click for drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMaintainedClick(_event) {
        /* TODO définir clique gauche sur element */
        this._is_mouse_grabbed = true;
    }
    /**
     * Define released left mouse button click for drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventReleasedClick(_event) {
        /* TODO définir clique gauche sur element */
        this._is_mouse_grabbed = false;
    }
    /**
     * Define event when mouse moves over drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOver(_event) {
        // Update mouse over indicator for element
        this.setMouseOver();
    }
    /**
     * Define event when mouse moves out of drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseOut(_event) {
        // Update mouse left indicator for element
        this.unsetMouseOver();
    }
    /**
     * Define event when mouse moves in drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseMove(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag starts
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDragStart(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDrag(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag ends
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Element
     */
    eventMouseDragEnd(_event) {
        /* TODO définir  */
    }
    // GETTERS / SETTERS ==================================================================
    // DrawingArea
    get drawing_area() { return this._display.drawing_area; }
    // Svg Group
    get svg_group() { return this._svg_group; }
    // Selection
    setSelected() { this._is_selected = true; this.drawAsSelected(); }
    setUnSelected() { this._is_selected = false; this.drawAsSelected(); }
    get is_selected() { return this._is_selected; }
    // Visible
    setVisible() { this._is_visible = true; this.draw(); }
    setInvisible() { this._is_visible = false; this.draw(); }
    get is_visible() { return this._is_visible; }
    // Mouse is over element
    isMouseOver() { return this._is_mouse_over; }
    setMouseOver() { this._is_mouse_over = true; }
    unsetMouseOver() { this._is_mouse_over = false; }
    // Unique id
    get id() { return this._id; }
    // Sankey
    get main_sankey() {
        if (!this._sankeys[default_main_sankey_id]) {
            this._sankeys[default_main_sankey_id] = this.drawing_area.sankey;
        }
        return this._sankeys[default_main_sankey_id];
    }
    // Get application config menu
    get menu_config() { return this._menu_config; }
}
// CLASS ELEMENT ************************************************************************
/**
 * Class that define a meta element to display on drawing area
 * Difference with Class_ProtoElement, Class_Element set its position
 *
 * @class Class_Element
 */
export class Class_Element extends Class_ProtoElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Element.
     * @param {string} id
     * @param {Class_DrawingArea} drawing_area
     * @param {string} svg_group
     * @memberof Class_Element
     */
    constructor(id, menu_config, svg_group) {
        super(id, menu_config, svg_group);
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Set up element on d3 svg area
     * @protected
     * @memberof Class_Element
     */
    draw() {
        // Draw element on D3
        super.draw();
        // Add apply position
        this.applyPosition();
    }
    // Positioning
    setPosXY(x, y) { this._display.position.x = x; this._display.position.y = y; this.applyPosition(); }
    initPosXY(x, y) { this._display.position.x = x; this._display.position.y = y; this.draw(); }
    initDefaultPosXY() { this.initPosXY(const_default_position_x, const_default_position_y); }
    // PROTECTED METHODS ==================================================================
    /**
     * Unset element from d3 svg area
     * @protected
     * @memberof Class_Element
     */
    unDraw() {
        if (this.d3_selection !== null) {
            this.d3_selection.remove();
            this.d3_selection = null;
        }
    }
    drawAsSelected() { }
    /**
     * Apply node position to it shape in d3
     * @protected
     * @return {*}
     * @memberof Class_Node
     */
    applyPosition() {
        if (this.d3_selection !== null) {
            this.d3_selection.attr('transform', 'translate(' + this.position_x + ', ' + this.position_y + ')');
        }
    }
    // GETTERS / SETTERS ==================================================================
    // Position
    get position_x() { return this._display.position.x; }
    set position_x(_) { this._display.position.x = _; this.applyPosition(); }
    get position_y() { return this._display.position.y; }
    set position_y(_) { this._display.position.y = _; this.applyPosition(); }
    get position_u() { return this._display.position.u; }
    set position_u(_) { this._display.position.u = _; this.applyPosition(); }
    get position_v() { return this._display.position.v; }
    set position_v(_) { this._display.position.v = _; this.applyPosition(); }
    get position_dx() { return this._display.position.dx; }
    set position_dx(_) { this._display.position.dx = _; this.applyPosition(); }
    get position_relative_dx() { return this._display.position.relative_dx; }
    set position_relative_dx(_) { this._display.position.relative_dx = _; this.applyPosition(); }
    get position_dy() { return this._display.position.dy; }
    set position_dy(_) { this._display.position.dy = _; this.applyPosition(); }
    get position_relative_dy() { return this._display.position.relative_dy; }
    set position_relative_dy(_) { this._display.position.relative_dy = _; this.applyPosition(); }
    get display() { return this._display; }
}
