// Standard libs
import React, { useRef, useState, } from 'react';
// Imported libs
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, InputRightAddon, InputGroup, Input, } from '@chakra-ui/react';
// Local libs
import SankeyNodeEdition from './SankeyMenuConfigurationNodes';
import SankeyMenuConfigurationLinks from './SankeyMenuConfigurationLinks';
// COMPONENTS ===========================================================================
/**
 *  Define configuration menu
  *
  * @param {*} {
  *     applicationData,
  *     applicationContext,
  *     menu_configuration_layout,
  *     menu_configuration_node_tags,
  *     menu_configuration_link_tags,
  *     menu_configuration_data_tags,
  *     menu_configuration_nodes_attributes,
  *     menu_config_link_data,
  *     menu_config_link_attr,
  *     additional_accordion_edition_elements,
  *   }
  * @return {*}
  */
export const OpenSankeyConfigurationsMenus = ({ applicationData, menu_configuration_layout, menu_configuration_node_tags, menu_configuration_link_tags, menu_configuration_data_tags, menu_configuration_nodes_attributes, menu_config_link_data, menu_config_link_attr, 
// additional_accordion_edition_elements,
additional_menus, }) => {
    // Data -------------------------------------------------------------------------------
    const { t } = applicationData.new_data;
    const { new_data } = applicationData;
    const config_object = new_data.menu_configuration;
    // Component updater ------------------------------------------------------------------
    const [, setCount] = useState(0);
    config_object.ref_to_menu_config_updater.current = () => setCount(a => a + 1);
    // JSX Component ----------------------------------------------------------------------
    const show_menu_config_tag = (config_object.isGivenAccordionShowed('EN') ||
        config_object.isGivenAccordionShowed('EF') ||
        config_object.isGivenAccordionShowed('ED'));
    const menu_items = [
        config_object.isGivenAccordionShowed('MEP') ?
            React.createElement(AccordionItem, null,
                React.createElement(AccordionButton, { onClick: () => {
                        const scroll_x = window.scrollX;
                        const scroll_y = window.scrollY;
                        setTimeout(() => {
                            var _a;
                            (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                        }, 50);
                    } },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.MEP')),
                    React.createElement(AccordionIcon, null)),
                React.createElement(AccordionPanel, null,
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_configuration_layout))) :
            React.createElement(React.Fragment, null),
        React.createElement(AccordionItem, null,
            React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_elements },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.Elements')),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Accordion, { allowToggle: true, variant: 'accordion_sublevel_style' },
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_node },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdN')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyNodeEdition, { applicationData: applicationData, menu_configuration_nodes_attributes: menu_configuration_nodes_attributes, additionalMenus: additional_menus }))),
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_link },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdF')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyMenuConfigurationLinks, { applicationData: applicationData, menu_config_link_data: menu_config_link_data, menu_config_link_attr: menu_config_link_attr }))),
                    additional_menus.additional_configuration_menus))),
        show_menu_config_tag ?
            React.createElement(AccordionItem, null,
                React.createElement(AccordionButton, { onClick: () => {
                        const scroll_x = window.scrollX;
                        const scroll_y = window.scrollY;
                        setTimeout(() => {
                            var _a;
                            (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                        }, 50);
                    } },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.Etiquettes')),
                    React.createElement(AccordionIcon, null)),
                React.createElement(AccordionPanel, null,
                    React.createElement(Accordion, { allowToggle: true, variant: "accordion_sublevel_style" },
                        React.createElement(AccordionItem, { style: { 'display': (config_object.isGivenAccordionShowed('EN')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EN')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_node_tags)),
                        React.createElement(AccordionItem, { style: { 'display': (config_object.isGivenAccordionShowed('EF')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EF')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_link_tags)),
                        React.createElement(AccordionItem, { style: { 'display': (config_object.isGivenAccordionShowed('ED')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.ED')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_data_tags))))) :
            React.createElement(React.Fragment, null)
    ];
    return (React.createElement(Accordion, { allowToggle: true }, menu_items.map((c, i) => {
        return React.createElement(React.Fragment, { key: i }, c);
    })));
};
export const SankeyConfigurationMenu = ({ configuration_menus }) => {
    return (React.createElement(Accordion, { allowToggle: true }, configuration_menus.map((c, i) => {
        return React.createElement(React.Fragment, { key: i }, c);
    })));
};
/**
 * Component developped for number input of the config menu
  * @param {*} {
  *   ref_to_set_value,
  *   function_on_blur,
  *   menu_for_style = false,
  *   minimum_value = Number.MIN_SAFE_INTEGER,
  *   maximum_value = Number.MAX_SAFE_INTEGER,
  *   stepper = false,
  *   step = 1,
  *   unit_text = undefined,
  * }
  * @return {*}
  */
export const ConfigMenuNumberInput = ({ default_value, ref_to_set_value, function_on_blur, menu_for_style = false, minimum_value = Number.MIN_SAFE_INTEGER, maximum_value = Number.MAX_SAFE_INTEGER, stepper = false, step = 1, unit_text = undefined, }) => {
    const ref_input = useRef(null);
    const is_modifying = useRef();
    const variant = unit_text ? 'menuconfigpanel_option_numberinput_with_right_addon' : 'menuconfigpanel_option_numberinput';
    const [value, setValue] = useState(default_value);
    ref_to_set_value.current = setValue;
    // Add stepper addon if specified
    const stepperBtn = stepper ? React.createElement(NumberInputStepper, null,
        React.createElement(NumberIncrementStepper, null),
        React.createElement(NumberDecrementStepper, null)) : React.createElement(React.Fragment, null);
    // Add unit addon if specified
    const input_unit = unit_text ? React.createElement(InputRightAddon, null, unit_text) : React.createElement(React.Fragment, null);
    return React.createElement(InputGroup, null,
        React.createElement(NumberInput, { allowMouseWheel: true, variant: variant, min: minimum_value, max: maximum_value, step: step, value: value !== null && value !== void 0 ? value : '', onChange: (value_as_string, value_as_number) => {
                // Launch/reset timeout before the input auto blur (and update the value in data)
                if (!menu_for_style) {
                    // reset timeout if exist
                    if (is_modifying.current) {
                        clearTimeout(is_modifying.current);
                    }
                    // launch timeout that automatically blur the input
                    is_modifying.current = setTimeout(() => {
                        var _a;
                        (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, 3000);
                }
                // Update displayed value_as_number
                setValue((value_as_string !== '') ? value_as_number : null);
            }, onKeyDown: e => {
                var _a;
                if (e.key === 'Enter') {
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
            } },
            React.createElement(NumberInputField, { ref: ref_input, onBlur: () => {
                    if (!menu_for_style) {
                        clearTimeout(is_modifying.current);
                    }
                    // Update selected elements value
                    function_on_blur(value);
                } }),
            stepperBtn),
        input_unit);
};
/**
 * Component developped for text input of the config menu
 * @param {*} {
 *   default_value,
 *   function_onChange,
 *   function_onBlur,
 *   menu_for_style = false
 * }
 * @return {*}
 */
export const ConfigMenuTextInput = ({ ref_to_set_value, function_get_value, function_on_blur, menu_for_style = false }) => {
    const ref_input = useRef(null);
    const is_modifying = useRef();
    const [value, setValue] = useState(function_get_value());
    ref_to_set_value.current = setValue;
    return React.createElement(InputGroup, null,
        React.createElement(Input, { ref: ref_input, variant: 'menuconfigpanel_option_input', value: value !== null && value !== void 0 ? value : '', onChange: evt => {
                const updated_value = evt.target.value;
                // Launch/reset timeout before the input auto blur (and update the updated_value in data)
                if (!menu_for_style) {
                    // reset timeout if exist
                    if (is_modifying.current) {
                        clearTimeout(is_modifying.current);
                    }
                    // launch timeout that automatically blur the input
                    is_modifying.current = setTimeout(() => {
                        var _a;
                        (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, 2000);
                }
                // Update displayed updated_value
                setValue((updated_value !== '') ? updated_value : null);
            }, onKeyDown: e => {
                var _a;
                if (e.key === 'Enter') {
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
            }, onBlur: () => {
                if (!menu_for_style) {
                    clearTimeout(is_modifying.current);
                }
                // Update selected elements value
                function_on_blur(value);
            } }));
};
