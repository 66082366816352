import React, { useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Box, Checkbox, Input, } from '@chakra-ui/react';
import { OSTooltip } from '../types/Utils';
import { ConfigMenuNumberInput } from './SankeyMenuConfiguration';
// MENU COMPONENT ***********************************************************************
export const OpenSankeyMenuConfigurationLayout = ({ applicationData, extra_background_element }) => {
    // Data -------------------------------------------------------------------------------
    const { new_data } = applicationData;
    const { t } = new_data;
    // Components updaters ---------------------------------------------------------------
    const [, setCount] = useState(0);
    new_data.menu_configuration.ref_to_menu_config_layout_updater.current = () => setCount(a => a + 1);
    // Link to ConfigMenuNumberInput state variable
    const number_of_input = 9;
    const ref_set_number_inputs = [];
    for (let i = 0; i < number_of_input; i++)
        ref_set_number_inputs.push(useRef((_) => null));
    // Be sure that values are updated in inputs when refreshing this component
    ref_set_number_inputs[0].current(new_data.drawing_area.scale);
    ref_set_number_inputs[1].current(new_data.drawing_area.minimum_flux);
    ref_set_number_inputs[2].current(new_data.drawing_area.maximum_flux);
    ref_set_number_inputs[3].current(new_data.drawing_area.legend.legend_police);
    ref_set_number_inputs[4].current(new_data.drawing_area.legend.legend_bg_opacity);
    ref_set_number_inputs[5].current(new_data.drawing_area.legend.position_x);
    ref_set_number_inputs[6].current(new_data.drawing_area.legend.position_y);
    ref_set_number_inputs[7].current(new_data.drawing_area.legend.width);
    ref_set_number_inputs[8].current(new_data.drawing_area.grid_size);
    /**
     * Function used to reset menu UI
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Whatever is done, set saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        setCount(a => a + 1);
    };
    // Utils functions -------------------------------------------------------------------
    const right_addon_pixel = (val) => {
        if (val === 1) {
            return 'pixel';
        }
        return 'pixels';
    };
    // Components updaters ---------------------------------------------------------------
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.background')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.BgC')),
            React.createElement(OSTooltip, { label: t('MEP.tooltips.BgC') },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: new_data.drawing_area.color, onChange: evt => {
                        new_data.drawing_area.color = evt.target.value;
                        refreshThisAndUpdateRelatedComponents();
                    } }))),
        extra_background_element,
        React.createElement(Box, { as: 'span' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.drawing_area.grid_visible, icon: new_data.drawing_area.grid_visible ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), onChange: (evt) => {
                    new_data.drawing_area.grid_visible = evt.target.checked;
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.GV') }, t('MEP.TCG')))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', style: { display: (new_data.drawing_area.grid_visible ? '' : 'none') } },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.TCG_shift')),
            React.createElement(Box, null,
                React.createElement(OSTooltip, { label: t('MEP.tooltips.TCG') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[8], default_value: new_data.drawing_area.grid_size, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.grid_size = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: 10, stepper: true, unit_text: right_addon_pixel(new_data.drawing_area.grid_size) })))),
        React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('MEP.links_size')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Echelle')),
            React.createElement(Box, null,
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[0], default_value: new_data.drawing_area.scale, function_on_blur: (value) => {
                        if (value) {
                            new_data.drawing_area.scale = value;
                            refreshThisAndUpdateRelatedComponents();
                        }
                    }, minimum_value: 1, stepper: true, unit_text: 'unit. / 100 pixels' }))),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_2row_3cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '1', gridColumnEnd: '2', gridRowStart: '2', gridRowEnd: '3' }, t('MEP.link_size_limit')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '2', gridColumnEnd: '3', gridRowStart: '1', gridRowEnd: '2', alignItems: 'flex-end' }, t('MEP.MinFlux')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '3', gridColumnEnd: '4', gridRowStart: '1', gridRowEnd: '2', alignItems: 'flex-end' }, t('MEP.MaxFlux')),
            React.createElement(Box, { gridColumnStart: '2', gridColumnEnd: '3', gridRowStart: '2', gridRowEnd: '3' },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.MinFlux') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[1], default_value: new_data.drawing_area.minimum_flux, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.minimum_flux = value;
                                // Even we are changing a parameter for link we redraw all node so it also redraw link + arrow
                                new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw());
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: 1, maximum_value: new_data.drawing_area.maximum_flux, stepper: true, unit_text: right_addon_pixel(new_data.drawing_area.minimum_flux) }))),
            React.createElement(Box, { gridColumnStart: '3', gridColumnEnd: '4', gridRowStart: '2', gridRowEnd: '3' },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.MaxFlux') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[2], default_value: new_data.drawing_area.maximum_flux, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.maximum_flux = value;
                                // Even we are changing a parameter for link we redraw all node so it also redraw link + arrow
                                new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw());
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: new_data.drawing_area.minimum_flux, stepper: true, unit_text: right_addon_pixel(new_data.drawing_area.maximum_flux) })))),
        React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: !new_data.drawing_area.legend.masked ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), isChecked: !new_data.drawing_area.legend.masked, onChange: () => {
                    new_data.drawing_area.legend.masked = !new_data.drawing_area.legend.masked;
                    refreshThisAndUpdateRelatedComponents();
                } }, t('Menu.Leg'))),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid', style: { display: (new_data.drawing_area.legend.masked ? 'none' : '') } },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_layout')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, t('MEP.leg_layout_text')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.fontSize')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.fontSize') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[3], default_value: new_data.drawing_area.legend.legend_police, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.legend.legend_police = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: 1, stepper: true }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, t('MEP.leg_layout_background')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.LegBgColor')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgColor') },
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: new_data.drawing_area.legend.legend_bg_color, onChange: evt => {
                            new_data.drawing_area.legend.legend_bg_color = evt.target.value;
                            refreshThisAndUpdateRelatedComponents();
                        } }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.LegBgOpacity')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgOpacity') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[4], default_value: new_data.drawing_area.legend.legend_bg_opacity, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.legend.legend_bg_opacity = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: 0, maximum_value: 100, stepper: true, unit_text: '%' }))),
            React.createElement(Box, { as: 'span' },
                React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.drawing_area.legend.legend_bg_border, onChange: (evt) => {
                        new_data.drawing_area.legend.legend_bg_border = evt.target.checked;
                        refreshThisAndUpdateRelatedComponents();
                    } },
                    React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgBorder') }, t('Menu.LegBgBorder')))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_pos')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegX')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegX') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[5], default_value: new_data.drawing_area.legend.position_x, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.legend.position_x = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, step: 1, stepper: true, unit_text: right_addon_pixel(Math.round(new_data.drawing_area.legend.position_x)) }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegY')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegY') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[6], default_value: new_data.drawing_area.legend.position_y, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.legend.position_y = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, step: 1, stepper: true, unit_text: right_addon_pixel(Math.round(new_data.drawing_area.legend.position_y)) }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegWidth')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegWidth') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[7], default_value: new_data.drawing_area.legend.width, function_on_blur: (value) => {
                            if (value) {
                                new_data.drawing_area.legend.width = value;
                                refreshThisAndUpdateRelatedComponents();
                            }
                        }, minimum_value: 0, step: 1, stepper: true, unit_text: right_addon_pixel(new_data.drawing_area.legend.width) }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_info')),
            React.createElement(Box, { as: 'span' },
                React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.drawing_area.legend.display_legend_scale, checked: new_data.drawing_area.legend.display_legend_scale, onChange: (evt) => {
                        new_data.drawing_area.legend.display_legend_scale = evt.target.checked;
                        refreshThisAndUpdateRelatedComponents();
                    } }, t('Menu.display_scale'))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.drawing_area.legend.legend_show_dataTags, checked: new_data.drawing_area.legend.legend_show_dataTags, onChange: (evt) => {
                    new_data.drawing_area.legend.legend_show_dataTags = evt.target.checked;
                    refreshThisAndUpdateRelatedComponents();
                } }, t('MEP.leg_show_dataTags'))));
};
