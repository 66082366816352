// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import { Class_Sankey } from 'open-sankey/dist/types/Sankey';
import { Class_NodePlusElement } from './NodePlus';
import { Class_FreeLabel } from './FreeLabel';
// SPECIFIC CONSTANTS *******************************************************************
export const default_main_sankey_id = 'sankey_maitre';
export const default_style_id = 'default';
export const default_style_name = 'Style par default';
// CLASS SANKEY *************************************************************************
/**
 * Contains all necessary elements to draw a Sankey
 *
 * @export
 * @class Class_Sankey
 */
export class Class_SankeyPlus extends Class_Sankey {
    // PRIVATE ATTRIBUTES =================================================================
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Sankey.
     * @param {Class_DrawingArea} drawing_area
     * @memberof Class_Sankey
     */
    constructor(drawing_area, menu_config, id = default_main_sankey_id) {
        super(drawing_area, menu_config, id);
        // PUBLIC ATTRIBUTES ==================================================================
        // PROTECTED ATTRIBUTES ===============================================================
        // Nodes
        this._labels = {};
        this._icon_catalog = {};
        this._view = [];
        this._current_view = 'none';
        this._background_image = '';
        this._show_background_image = false;
        this._is_catalog = false;
        this.moveUpFreeLabelOrder = (zdt) => {
            const list_zdt = Object.entries(this._labels);
            // Get idx of element to move up
            const posElemt = list_zdt.indexOf([zdt.id, zdt]);
            // Remove zdt from original dict.
            list_zdt.splice(posElemt, 1);
            // Add zdt before previous zdt if dict
            list_zdt.splice(posElemt - 1, 0, [zdt.id, zdt]);
            // Replace original dict with new one (the same in different order)
            this._labels = Object.fromEntries(list_zdt);
            // Redraw all free labels
            this.free_labels_list.map(zdt => zdt.draw());
        };
        this.moveDownFreeLabelOrder = (zdt) => {
            const list_zdt = Object.entries(this._labels);
            // Get idx of element to move up
            const posElemt = list_zdt.indexOf([zdt.id, zdt]);
            // Remove zdt from original dict.
            list_zdt.splice(posElemt, 1);
            // Add zdt after next zdt if dict
            list_zdt.splice(posElemt + 1, 0, [zdt.id, zdt]);
            // Replace original dict with new one (the same in different order)
            this._labels = Object.fromEntries(list_zdt);
            // Redraw all free labels
            this.free_labels_list.map(zdt => zdt.draw());
        };
        this._labels = {};
        this._icon_catalog = {};
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Add a given zdt to Sankey
     * @param {Class_FreeLabel} node
     * @memberof Class_Sankey
     */
    _addLabel(zdt) { this._labels[zdt.id] = zdt; }
    /**
     * Create and add a node for this Sankey
     * @param {string} id
     * @param {string} name
     * @return {Class_Node}
     * @memberof Class_Sankey
     */
    addNewFreeLabel(id) {
        if (!this._labels[id]) {
            // Create node
            const zdt = new Class_FreeLabel(id, this._menu_config, this.drawing_area);
            // Set node to default position
            zdt.initDefaultPosXY();
            // Update registry of nodes
            this._addLabel(zdt);
            return zdt;
        }
        else {
            return this.addNewFreeLabel(id + '_0');
        }
    }
    /**
     * Create and add a node for this Sankey with default name
     * @return {*}
     * @memberof Class_Sankey
     */
    addNewDefaultFreeLabel() {
        const n = String(Object.values(this._labels).length);
        const id = 'free_label' + n;
        const name = 'Zone de texte ' + n;
        return this.addNewFreeLabel(id);
    }
    /**
     * Permanently delete selected nodes
     * @memberof Class_DrawingAreaPlus
     */
    deleteSelectedFreeLabels() {
        // Get copy of selected nodes
        const selected_nodes = this.drawing_area.selected_free_labels_list;
        // Delete each one of them
        selected_nodes.forEach(zdt => { this.deleteFreeLabel(zdt); });
        // Then let garbage collector do the rest...
    }
    /**
   * Delete a given zdt from Sankey -> zdt may still exist somewhere
   * @param {Class_FreeLabel} zdt
   * @memberof Class_SankeyPlus
   */
    deleteFreeLabel(zdt) {
        if (this._labels[zdt.id] !== undefined) {
            // Delete node in sankey
            const _ = this._labels[zdt.id];
            delete this._labels[zdt.id];
            _.delete();
        }
    }
    /**
     * Return the path of the icon, if it doesn't exist return an empty string
     *
     * @param {string} id_icon
     * @return {*}
     * @memberof Class_SankeyPlus
     */
    getIconFromCatalog(id_icon) {
        const icon = this.icon_catalog[id_icon];
        if (icon !== undefined && icon !== null) {
            return icon;
        }
        return '';
    }
    addNewDefaultNode() {
        const n = String(Object.values(this._nodes).length);
        const id = 'node' + n;
        const name = 'Node ' + n;
        return this.addNewNode(id, name);
    }
    addNewNode(id, name) {
        if (!this._nodes[id]) {
            // Create node
            const node = new Class_NodePlusElement(id, name, this.drawing_area, this._menu_config);
            // Set node to default position
            node.initDefaultPosXY();
            // Update registry of nodes
            this._addNode(node);
            return node;
        }
        else {
            return this.addNewNode(id + '_0', name + '_0');
        }
    }
    // GETTERS / SETTERS ==================================================================
    get nodes_list_plus() {
        return this.nodes_list;
    }
    get free_labels_dict() { return this._labels; }
    get free_labels_list() { return Object.values(this._labels); }
    get free_labels_list_sorted() { return this.free_labels_list.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)); }
    get visible_free_labels_list() { return this.free_labels_list.filter(zdt => zdt.is_visible); }
    get icon_catalog() { return this._icon_catalog; }
    set icon_catalog(value) { this._icon_catalog = value; }
}
