import * as OSPNodes from 'sankeyanimation/dist/src/SankeyPlusNodes'
import * as OSPForeignObject  from 'sankeyanimation/dist/src/SankeyPlusForeignObject'
import * as OSPLabels from 'sankeyanimation/dist/src/SankeyPlusLabels'
import * as OSPGradient from 'sankeyanimation/dist/src/SankeyPlusGradient'
//import * as OSPViews from 'sankeyanimation/dist/src/SankeyPlusViews'
import * as OSPUtils from 'sankeyanimation/dist/src/SankeyPlusUtils'
import * as OSPMenuConfigurationLabels from 'sankeyanimation/dist/src/SankeyPlusMenuConfigurationLabels'
import * as OSPItemShortcut from 'sankeyanimation/dist/src/SankeyPlusItemShortcut'
import * as OSPConvert from 'sankeyanimation/dist/src/SankeyPlusConvert'
import * as OSPModule from 'sankeyanimation/dist/src/OSPModule'

import {
  // OSPDrawNodesIllustrationFType,
  // OSPHyperLinkFType,
  // OSPNodeClickEventFType,
  // OSPNodeDragEventFType,
  OSPNodeIconFType,
  // ContextNodeIconFType
} from 'sankeyanimation/types/SankeyPlusNodesTypes'
// import {
//   OSPDrawNodesFOFType,
//   OSPNodeFOFType
// } from 'sankeyanimation/types/SankeyPlusForeignObjectTypes'
// import {
//   // OSPDrawLabelsFType,
//   // sankey_plus_min_width_and_heightFType,
//   // zone_selection_labelFType,
//   sankey_plus_zoom_text_zoneFType
// } from 'sankeyanimation/types/SankeyPlusLabelsTypes'
// import {
//   // OSPLinkStrokeFType,
//   OSPDrawArrowsFType,
//   // MenuConfLinkApparenceGradientFType
// } from 'sankeyanimation/types/SankeyPlusGradientTypes'
// import {
//   MenuEnregistrerViewFType,
//   OpenOSPCheckpointButtonFType,
//   OSPBannerViewFType,
//   OSPMenuPreferenceViewFType,
//   CheckCurrentViewSavedFType,
//   FilterViewFType,
//   GetDataFromViewFType,
//   modal_transparent_view_attrFType,
//   modal_view_not_savedFType,
//   RecomputeViewsFType,
//   SelecteurViewFType,
//   setValueFType,
//   viewsAccordionFType,
//   getSetDiagramFType
// } from 'sankeyanimation/types/SankeyPlusViewsTypes'
import {
  SetSvgBgFType,
  // OSPLinkSabotColorFType,
  ImportImageAsSvgBgFType,
  DefaultOSPStyleLinkFType
} from 'sankeyanimation/types/SankeyPlusUtilsTypes'
import {
  OSPMenuPreferenceLabelsFType,
  blur_ZDT_wysiwygFType,
  ZDTMenuAsAccordeonItemType
}from 'sankeyanimation/types/SankeyPlusMenuConfigurationLabelsTypes'
import {
  OSPShortcutFType
} from 'sankeyanimation/types/SankeyPlusItemShortcutTypes'
import {
  OSPDiagramSelectorFType, /*OSPTransformationElementsFType*/
  plus_convert_dataFType,
  plus_sankey_layoutFType
} from 'sankeyanimation/types/SankeyPlusConvertTypes'
// import { 
//   module_dialogsType, DrawAllType, InstallEventsOnSVGType, 
//   initializeContextMenuType, 
//   InitalizeSelectorDetailNodesType} from 'open-sankey/src/types/Types'
// import { 
//   OSPInitializeElementSelectedType, OSPInitializeApplicationDrawType, OSPInitializeShowDialogType, 
//   OSPInitializeReinitializationType, OSPInitializeUIElementsRefType, OSPInitializeAdditionalMenusType, 
//   OSPInitializeApplicationDataVarType, OSPInitializeComponentUpdaterType, OSPInitializeLinkFuntionType, 
//   OSPInitializeNodeFunctionsType, OSPInitializeApplicationContextVarType, OSPInitializeKeyHandlerType 
// } from 'sankeyanimation/types/Types'
import { FunctionComponent } from 'react'
import { OSPInitializeAdditionalMenusType, OSPInitializeApplicationDataVarType, OSPInitializeReinitializationType } from 'sankeyanimation/types/Types'
import { module_dialogsType } from 'open-sankey/src/types/LegacyType'


// Import From OSP
// export const OSPDrawNodesIllustration =
//  OSPNodes.OSPDrawNodesIllustration as OSPDrawNodesIllustrationFType
// export const OSPNodeClickEvent =
//  OSPNodes.OSPNodeClickEvent as OSPNodeClickEventFType
// export const OSPNodeDragEvent =
//  OSPNodes.OSPNodeDragEvent as OSPNodeDragEventFType
// export const OSPDrawNodesFO =
//  OSPForeignObject.OSPDrawNodesFO as OSPDrawNodesFOFType
// export const OSPDrawLabels =
//  OSPLabels.OSPDrawLabels as OSPDrawLabelsFType
// export const sankey_plus_min_width_and_height =
//  OSPLabels.sankey_plus_min_width_and_height as sankey_plus_min_width_and_heightFType
// export const zone_selection_label =
//  OSPLabels.zone_selection_label as zone_selection_labelFType
// export const sankey_plus_zoom_text_zone =
//  OSPLabels.sankey_plus_zoom_text_zone as sankey_plus_zoom_text_zoneFType
// export const OSPDrawArrows =
//   OSPGradient.OSPDrawArrows as OSPDrawArrowsFType
// export const OSPLinkStroke =
//  OSPGradient.OSPLinkStroke as OSPLinkStrokeFType
// export const MenuConfLinkApparenceGradient =
//  OSPGradient.MenuConfLinkApparenceGradient as MenuConfLinkApparenceGradientFType
// export const GetDataFromView =
//  OSPViews.GetDataFromView as GetDataFromViewFType
// export const getSetDiagramFunc =
//  OSPViews.getSetDiagramFunc as getSetDiagramFType
// export const SetSvgBg =
//  OSPUtils.SetSvgBg as SetSvgBgFType
// export const OSPLinkSabotColor =
//  OSPUtils.OSPLinkSabotColor as OSPLinkSabotColorFType

export const blur_ZDT_wysiwyg =
 OSPMenuConfigurationLabels.blur_ZDT_wysiwyg as blur_ZDT_wysiwygFType

export const OSPNodeIcon =
 OSPNodes.OSPNodeIcon as OSPNodeIconFType
// export const ContextNodeIcon =
//  OSPNodes.ContextNodeIcon as ContextNodeIconFType
// export const OSPHyperLink =
//  OSPNodes.OSPHyperLink as OSPHyperLinkFType
export const ZDTMenuAsAccordeonItem =
 OSPMenuConfigurationLabels.ZDTMenuAsAccordeonItem as ZDTMenuAsAccordeonItemType

export const OSPMenuPreferenceLabels =
  OSPMenuConfigurationLabels.OSPMenuPreferenceLabels as
   OSPMenuPreferenceLabelsFType
export const OSPShortcut =
  OSPItemShortcut.OSPShortcut as OSPShortcutFType
// export const OSPNodeFO =
//   OSPForeignObject.OSPNodeFO as OSPNodeFOFType
// export const plus_sankey_layout =
//   OSPConvert.plus_sankey_layout as plus_sankey_layoutFType
// export const OSPTransformationElements =
//   OSPConvert.OSPTransformationElements as
//   OSPTransformationElementsFType
// export const OSPDiagramSelector =
//   OSPConvert.OSPDiagramSelector as OSPDiagramSelectorFType
// export const ImportImageAsSvgBg =
//   OSPUtils.ImportImageAsSvgBg as ImportImageAsSvgBgFType

// export const setValue =
//  OSPViews.setValue as setValueFType
// export const FilterView =
//  OSPViews.FilterView as FilterViewFType
// export const RecomputeViews =
//  OSPViews.RecomputeViews as RecomputeViewsFType
// export const SelecteurView =
//  OSPViews.SelecteurView as FunctionComponent<SelecteurViewFType>
// export const viewsAccordion =
//  OSPViews.viewsAccordion as viewsAccordionFType
// export const CheckCurrentViewSaved =
//  OSPViews.CheckCurrentViewSaved as CheckCurrentViewSavedFType
// export const OSPBannerView =
//  OSPViews.OSPBannerView as FunctionComponent<OSPBannerViewFType>
// export const OSPMenuPreferenceView =
//  OSPViews.OSPMenuPreferenceView as OSPMenuPreferenceViewFType
// export const modal_view_not_saved =
//  OSPViews.modal_view_not_saved as modal_view_not_savedFType
// export const modal_transparent_view_attr =
//  OSPViews.modal_transparent_view_attr as modal_transparent_view_attrFType
// export const MenuEnregistrerView =
//  OSPViews.MenuEnregistrerView as FunctionComponent<MenuEnregistrerViewFType>
// export const OpenOSPCheckpointButton =
//  OSPViews.OpenOSPCheckpointButton as OpenOSPCheckpointButtonFType

// export const OSPItemExport=OSPUtils.OSPItemExport

// export const OSPInitializeApplicationContext = OSPModule.OSPInitializeApplicationContext as OSPInitializeApplicationContextVarType
export const OSPInitializeApplicationData = OSPModule.OSPInitializeApplicationData as unknown as OSPInitializeApplicationDataVarType
// export const OSPInitializeElementSelected = OSPModule.OSPInitializeElementSelected as OSPInitializeElementSelectedType
// export const OSPInitializeApplicationDraw = OSPModule.OSPInitializeApplicationDraw as  unknown as OSPInitializeApplicationDrawType
// export const OSPInitializeShowDialog = OSPModule.OSPInitializeShowDialog as OSPInitializeShowDialogType
// export const OSPInitializeComponentUpdater = OSPModule.OSPInitializeComponentUpdater as OSPInitializeComponentUpdaterType
export const OSPInitializeReinitialization  = OSPModule.OSPInitializeReinitialization as OSPInitializeReinitializationType
//export const OSPInitializeProcessFunctions = OSPModule.OSPInitializeProcessFunctions as initializeProcessFunctionsType
// export const OSPInitializeContextMenu = OSPModule.OSPInitializeContextMenu as initializeContextMenuType
// export const OSPInitializeUIElementsRef = OSPModule.OSPInitializeUIElementsRef as OSPInitializeUIElementsRefType
// export const OSPInitializeLinkFunctions  = OSPModule.OSPInitializeLinkFunctions as OSPInitializeLinkFuntionType
// export const OSPInitializeNodeFunctions = OSPModule.OSPInitializeNodeFunctions as  OSPInitializeNodeFunctionsType
export const OSPInitializeAdditionalMenus = OSPModule.OSPInitializeAdditionalMenus as OSPInitializeAdditionalMenusType
export const OSPModuleDialogs = OSPModule.OSPModuleDialogs as module_dialogsType
// export const OSPDrawAll = OSPModule.OSPDrawAll as DrawAllType
// export const OSPInstallEventsOnSVG = OSPModule.OSPInstallEventsOnSVG as InstallEventsOnSVGType
// export const OSPInitializeKeyHandler = OSPModule.OSPInitializeKeyHandler as OSPInitializeKeyHandlerType
// export const OSPInitalizeSelectorDetailNodes = OSPModule.OSPInitalizeSelectorDetailNodes as InitalizeSelectorDetailNodesType

export const DefaultOSPStyleLink = OSPUtils.DefaultOSPStyleLink as DefaultOSPStyleLinkFType
// export const plus_convert_data = OSPConvert.plus_convert_data as plus_convert_dataFType