import { Class_MenuConfig } from 'open-sankey/dist/types/MenuConfig';
import { useRef } from 'react';
import * as d3 from 'd3';
export class Class_MenuConfigPlus extends Class_MenuConfig {
    constructor() {
        super();
        // Init value for menu_config plus variable
        this._ref_to_menu_config_free_label_updater = useRef(() => null);
        this._zdt_accordion_ref = useRef(null);
        this._dict_setter_show_dialog_plus = {
            ref_setter_show_menu_node_icon: useRef(() => null),
            ref_setter_show_modal_import_icons: useRef(() => null),
            ref_setter_show_menu_zdt: useRef(() => null),
            ref_setter_show_modal_transparent_view_attr: useRef(() => null),
        };
    }
    //PUBLIC METHODS =====================================
    OpenConfigMenuElementsFreeLabels() {
        var _a;
        this.OpenConfigMenuElements();
        (_a = this._zdt_accordion_ref.current) === null || _a === void 0 ? void 0 : _a.click();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Free labels element menu
            if (this._zdt_accordion_ref.current &&
                (d3.select(this._zdt_accordion_ref.current).attr('aria-expanded') === 'false')) {
                this._zdt_accordion_ref.current.click();
            }
        }, 200);
    }
    // ============GETTER && SETTER ==================
    get zdt_accordion_ref() { return this._zdt_accordion_ref; }
    get dict_setter_show_dialog_plus() { return this._dict_setter_show_dialog_plus; }
    // public set dict_setter_show_dialog_plus(value: OSPShowMenuComponentsVarType) {this._dict_setter_show_dialog_plus = value;}
    get ref_to_menu_config_free_label_updater() { return this._ref_to_menu_config_free_label_updater; }
    set ref_to_menu_config_free_label_updater(value) { this._ref_to_menu_config_free_label_updater = value; }
}
